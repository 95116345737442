import { APP_DOMAIN, APP_SUBPATH, GOOGLE_GEOCODE_API_KEY, STRIPE_PUBLIC_KEY } from '../../common/environment/config.prod';
import { IConfig, LoggingLevel } from '../../common/src/interfaces/config/IConfig';

export const environment: IConfig = {
  firebase: {
    apiKey: 'AIzaSyBQJSBk2coury9OwhAh3EYUX_Vge3YE9PQ',
    authDomain: 'hiero-prod.firebaseapp.com',
    databaseURL: 'https://hiero-prod.firebaseio.com',
    projectId: 'hiero-prod',
    storageBucket: 'hiero-prod.appspot.com',
    messagingSenderId: '709112750134',
    appId: '1:709112750134:web:eccc5304ab6b2eef793897'
  },
  app: {
    origin: APP_DOMAIN,
    subpath: APP_SUBPATH
  },
  appBundleId: 'tech.kevinglass.hiero',
  appMinimumVersion: '0',
  production: true,
  logLevel: LoggingLevel.Warning,
  google_geocode_apikey: GOOGLE_GEOCODE_API_KEY,
  resetRedirectUrl: 'https://translator.hierø.com',
  public_key_stripe: STRIPE_PUBLIC_KEY

};
