import { Injectable } from "@angular/core";
import * as firebase from "firebase";
import { ITraducteur } from "../../../common/src/bdd/interfaces/ITraducteur";
import { HieroBDD } from "./hierobdd.service";

const PROFESSIONNELS_COLLECTION = "professionnels";
const PARTICULIERS_COLLECTION = "particuliers";
const USERS_COLLECTION = "users";

@Injectable()
export class ClientService {
  constructor(private hiero: HieroBDD) {}

  public async getProInfos(idProfessionnel: string) {
    let pro = await this.hiero.DB.collection(PROFESSIONNELS_COLLECTION)
      .doc(idProfessionnel)
      .get()
      .then(async (snap) => {
        return {
          data: snap.data(),
          profile: await this.getUser(snap.data().uid),
        };
      });
    return pro;
  }

  private getUser(uid: string) {
    return this.hiero.DB.collection(USERS_COLLECTION)
      .doc(uid)
      .get()
      .then((snap) => {
        return snap.data().profile;
      });
  }
}
