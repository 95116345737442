<ng-container *ngIf="interpretation">
  <div class="row">
    <div class="col">
      <h4>{{ "prestation_order_number" | localise }} {{ interpretationId }}</h4>
    </div>
  </div>
  <div class="row">
    <div [ngClass]="{ 'col-8': haveChatWindow, col: !haveChatWindow }">
      <div class="card mb-3">
        <div class="card-body">
          <div class="mb-3">
            <h4>{{ "prestation_next_action_title" | localise }}</h4>
          </div>

          <div>
            <p *ngIf="interpretation.missions.intervention.id === 1">
              {{ "intervention_place" | localise }}
            </p>
            <p *ngIf="interpretation.missions.intervention.id === 2">
              {{ "intervention_tel" | localise }}
            </p>
            <p *ngIf="interpretation.missions.intervention.id === 3">
              {{ "intervention_visio" | localise }}
            </p>
            <p>
              {{ "intervention_payment" | localise }}
            </p>
          </div>
          <div class="row">
            <div *ngIf="interpretation.state == 'waiting-for-translator'">
              <button class="btn btn-success ml-3" (click)="accept()">
                {{ "intervention_next_action_accept_order" | localise }}
              </button>
              <p class="card-text"></p>
            </div>
            <div *ngIf="interpretation.state == 'waiting-for-translator'">
              <button class="btn btn-danger ml-3" (click)="refuse()">
                {{ "intervention_next_action_reject_order" | localise }}
              </button>
              <p class="card-text"></p>
            </div>
            <div *ngIf="interpretation.state == 'translating'">
              <div *ngIf="interpretation.missions.intervention.id === (3 || 2)">
                <button class="btn my-btn ml-3" (click)="refuse()">
                  {{ "intervention_next_action_start_order" | localise }}
                </button>
                <p class="card-text"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-3">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-6">
              <h4>{{ "intervention_order_details" | localise }}</h4>
            </div>
          </div>
          <dl>
            <dd>
              <strong>{{ "menu_label_event_name" | localise }}</strong>
              : {{ interpretation.event }}
            </dd>
            <dd>
              <strong>{{ "intervention_order_for" | localise }}</strong>
              :
              {{
                interpretation.missions.date.toDate()
                  | date : "dd/MM/yyyy HH:mm"
              }}
            </dd>
            <dd>
              <strong>{{ "delai_intervention" | localise }}</strong>
              : {{ interpretation.missions.extHours }}
            </dd>
            <dd *ngIf="interpretation.missions.intervention.id == 1">
              <strong>{{ "type_intervention" | localise }}</strong>
              : {{ "menu_label_sur_place" | localise }}
            </dd>
            <dd *ngIf="interpretation.missions.intervention.id == 2">
              <strong>{{ "type_intervention" | localise }}</strong>
              : {{ "menu_label_tel" | localise }}
            </dd>
            <dd *ngIf="interpretation.missions.intervention.id == 3">
              <strong>{{ "type_intervention" | localise }}</strong>
              : {{ "menu_label_visio" | localise }}
            </dd>
            <dd>
              <strong>{{ "prestation_order_from" | localise }}</strong>
              :
              {{ interpretation.missions.srcLang | iso639ToNative }}
              {{ "prestation_order_to" | localise }}
              {{ interpretation.missions.destLang | iso639ToNative }}
            </dd>
            <dd>
              <strong>{{ "prestation_order_price" | localise }}</strong>
              {{ interpretation.price.priceHT.toFixed(2) }} €
            </dd>
            <dd *ngIf="interpretation.missions.intervention.id == 1">
              <strong>{{ "intervention_address" | localise }}</strong>
              : {{ interpretation.missions.address }}
            </dd>
          </dl>
        </div>
      </div>

      <div class="card mb-3">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-6">
              <h4>{{ "intervention_info_supp" | localise }}</h4>
            </div>
          </div>
          <div>
            <p>
              {{ interpretation.missions.context }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- <div *ngIf="haveChatWindow" class="col-4">
      <app-chat [traducteur]="traducteur" [prestation]="prestation"></app-chat>
    </div> -->
  </div>
</ng-container>
