<div class="row">
    <div class="col">
      <ng-container *ngIf="!!prestation">
        
        <div class="card mb-3">
          <div class="card-body d-flex flex-row align-items-center justify-content-between">
            <button class="btn btn-info" [routerLink]="['/app', 'prestation', prestation.Id]"><i class="fa fa-arrow-circle-left" aria-hidden="true"></i></button>
            <h5 class="mb-0 ml-3">{{ getDocumentName(document.documentTypeId) }}</h5>
            <button class="btn btn-success float-right" (click)='uploadFile()'>{{ 'prestation_document_validate' | localise }}</button> <!-- Le bouton de validation est ici-->
          </div>
        </div>

        <div class="container-fluid">
          <div class="row mb-3">
            <div class="col d-flex flex-row justify-content-center align-items-center">
              <p class="mb-0 mr-3"><strong>{{ 'prestation_document_show_original' | localise }}</strong></p>
              <button *ngFor="let file of document.original" class="btn btn-outline-info btn-sm prestation-file-button mr-3" (click)="openFile(prestation.Data, document, file)">
                <i *ngIf="file.type === 1" class="fa fa-file-image-o" aria-hidden="true"></i>
                <i *ngIf="file.type === 0" class="fa fa-file-pdf-o" aria-hidden="true"></i>
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-6 offset-3">
              <div *ngIf="errorLoadingTemplate" class="alert alert-danger">
                No template could be found for this document! Please contact <a href='mailto:contact@hiero-support.com'>support.</a>
              </div>
              <app-pdf-editor  *ngIf="!!pdfData" [objectEntry]="pdfRender" (update)='onUpdate($event)'></app-pdf-editor>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-6 offset-3 d-flex align-items-center justify-content-center">
              <button class="btn btn-warning" (click)="resetTemplate()">{{ 'prestation_document_button_reset' | localise }}</button>              
            </div>
          </div>
        </div>
  
      </ng-container>
      <app-wait [show]='busy'></app-wait>
    </div>
  </div>
  