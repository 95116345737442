import { IPrice } from '../interfaces/IPrestation';
import { Rounder } from './Rounder';
export class Price {

  public breakdown: IPrice;

  constructor(priceHT: number, tva: number, margin: number) {

    this.breakdown = {
      // Price of the prestation based on transltor prices, HT
      traducteurHT: 0,
      // The % margin added by hiero at the time of the order
      hieroMarginPercent: margin,
      // The margin in € at the time of the order
      hieroMarginValue: 0,
      // The subtotal HT
      subtotalHT: 0,
      // Tax %
      tva: tva,
      // Tax
      tvaValue: 0,
      // Total TTC
      ttc: 0
    }
    
    this.add(parseFloat(<any>priceHT));
  }

  public add(priceHT: number) {
    
    const newPriceHT = Rounder.RoundCurrency(this.breakdown.traducteurHT + parseFloat(<any>priceHT));
    const marginValue = Rounder.RoundCurrency(this.breakdown.hieroMarginPercent * newPriceHT);
    const subtotalHT = Rounder.RoundCurrency(newPriceHT + marginValue);
    const tvaValue = Rounder.RoundCurrency(subtotalHT * this.breakdown.tva);
    const ttcValue = Rounder.RoundCurrency(subtotalHT + tvaValue);

    const price = {
      // Price of the prestation based on transltor prices, HT
      traducteurHT: newPriceHT,
      // The % margin added by hiero at the time of the order
      hieroMarginPercent: this.breakdown.hieroMarginPercent,
      // The margin in € at the time of the order
      hieroMarginValue: marginValue,
      // The subtotal HT
      subtotalHT: subtotalHT,
      // Tax %
      tva: this.breakdown.tva,
      // Tax
      tvaValue: tvaValue,
      // Total TTC
      ttc: ttcValue
    }

    this.breakdown = price;
  }

}