<div *ngIf="busy" class="row h-100">
  <div class="col d-flex align-items-center justify-content-center">
    <img src="../assets/img/logo/bird.png" height="80px" />
  </div>
</div>

<div *ngIf="!busy" class="row">
  <div class="col d-flex align-items-center justify-content-center">
    <div class="container">
      <div class="d-flex p-20 flex-row-reverse">
        <app-language-dropdown></app-language-dropdown>
      </div>
      <div class="row">
        <div class="col d-flex flex-column align-items-center">
          <img src="../assets/img/logo/bird.png" height="60px" />
          <h1>{{ "agency_setup_title" | localise }}</h1>
          <p
            class="text-center"
            [innerHTML]="'agency_setup_explanation' | localise"
          ></p>
        </div>
      </div>

      <div class="row">
        <div class="col d-flex flex-column align-items-center">
          <form
            [formGroup]="profileForm.Group"
            novalidate
            style="max-width: 550px"
          >
            <app-form-input
              [parameters]="profileForm.GetInputElement('businessName')"
            ></app-form-input>
            <app-form-input
              [parameters]="profileForm.GetInputElement('registration')"
            ></app-form-input>
            <label>{{ "agency_setup_tva" | localise }}</label>
            <select class="custom-select mb-3" formControlName="tva">
              <option value="false">
                {{ "prestation_document_confirm_non" | localise }}
              </option>
              <option value="true">
                {{ "prestation_document_confirm_oui" | localise }}
              </option>
            </select>
            <app-form-input
              [parameters]="profileForm.GetInputElement('tvaNumber')"
            ></app-form-input>

            <app-form-typeahead
              class="verify-address"
              [parameters]="profileForm.GetInputElement('fulladdr')"
              [search]="search"
              [formatter]="formatter"
              (selected)="onAddressSelected($event)"
            ></app-form-typeahead>
            <small
              ><a
                class="d-block text-primary clickable mb-3"
                (click)="manualAddress()"
                >{{ "agency_address_not_found" | localise }}</a
              ></small
            >

            <app-form-input
              [parameters]="profileForm.GetInputElement('extra')"
            ></app-form-input>
            <app-form-input
              [parameters]="profileForm.GetInputElement('telephone')"
            ></app-form-input>
            <app-form-input
              [parameters]="profileForm.GetInputElement('email')"
            ></app-form-input>
            <label>{{ "asser_placeholder" | localise }}</label>
            <span
              ><i>{{ "asser_help" | localise }}</i></span
            >
            <select class="custom-select" formControlName="assermentation">
              <option value="false">
                {{ "prestation_document_confirm_non" | localise }}
              </option>
              <option value="true">
                {{ "prestation_document_confirm_oui" | localise }}
              </option>
            </select>
            <hr />
          </form>
          <button
            type="button"
            class="btn btn-danger"
            (click)="profileForm.Submit()"
            [disabled]="!profileForm.Valid"
          >
            {{ "agency_setup_save" | localise }}
          </button>
          <button class="btn btn-link mt-3" (click)="disconnect()">
            {{ "main_header_user_menu_logout" | localise }}
          </button>
          <app-wait [show]="busy"></app-wait>
        </div>
      </div>
    </div>
  </div>
</div>
