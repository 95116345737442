import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { EnumPrestationState } from "../../../../../common/src/bdd/interfaces/IPrestation";
import { MessageNotifications } from "../../../../../common/src/bdd/messaging/MessageNotifications";
import { Prestation } from "../../../../../common/src/bdd/prestation/Prestation";
import { TraducteurPrestations } from "../../../../../common/src/bdd/traducteur/TraducteurPrestations";
import { HieroBDD } from "../../../services/hierobdd.service";
import { SubscriptionTraductor } from "../../../services/subsciption.services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotSubscribeModalComponent } from "../../not_found/not-subscribe-modal/not-subscribe-modal.component";
import { ITraducteur } from "../../../../../common/src/bdd/interfaces/ITraducteur";

interface IStateFilter {
  state: EnumPrestationState;
  active: boolean;
}

@Component({
  templateUrl: "./prestations.component.html",
  styleUrls: ["./prestations.component.scss"],
})
export class PrestationListComponent implements OnDestroy {
  private traducteurPrestationSub: Subscription;
  private listSub: Subscription;
  private notifySub: Subscription;
  private profSubs: Subscription;
  private profile: ITraducteur;

  public showAsser: boolean = true;
  public showClassic: boolean = true;

  prestations: TraducteurPrestations;
  prestationList: Prestation[] = [];
  classicList: Prestation[] = [];
  asserList: Prestation[] = [];
  prestationListClassic: Prestation[] = [];

  filterUI: IStateFilter[];

  desc = true;
  public descClassic: boolean = true;
  public descAsser: boolean = true;

  page = 1;
  pageSize = 10;

  notifiers: Map<string, MessageNotifications>;

  busy = true;
  public pageClassic: number = 1;
  public pageAsser: number = 1;
  //checkedSubscribe: boolean = false

  constructor(
    private hiero: HieroBDD,
    private router: Router,
    private route: ActivatedRoute,
    private modal: NgbModal
  ) {
    // Setup default filters
    this.filterUI = [
      { state: EnumPrestationState.WaitingForTranslator, active: true },
      { state: EnumPrestationState.RefusedByTranslator, active: true },
      { state: EnumPrestationState.WaitingForPayment, active: true },
      { state: EnumPrestationState.Translating, active: true },
      { state: EnumPrestationState.CancelledByClient, active: true },
      { state: EnumPrestationState.Validated, active: true },
      {
        state: EnumPrestationState.WaitingForValidationFromClient,
        active: true,
      },
      { state: EnumPrestationState.ValidatedPro, active: true },
    ];

    // Subscribe to the list of prestations for this translator
    this.traducteurPrestationSub = this.hiero.WatchTraducteurPrestations({
      next: (prestations: TraducteurPrestations) => {
        if (prestations) {
          // Get original list
          this.prestations = prestations;
          this.prestationList = this.prestations.All;
          console.log("liste: ", this.prestationList);
          console.log("prestations: " + this.prestations.Count);

          this.classicList = this.prestationList.filter(
            (x) => x.Data.srcCountryCode == ""
          );
          this.asserList = this.prestationList.filter(
            (x) => x.Data.srcCountryCode != ""
          );

          // Listen for any subsequent updates to the list
          this.listSub = this.prestations.WatchList({
            next: (list: Prestation[]) => {
              //list.filter(el => el.Data.srcCountryCode != 'classic')
              //console.log('avant list',list)
              this.prestationList = list;
              this.classicList = this.prestationList.filter(
                (x) => x.Data.srcCountryCode == ""
              );
              this.asserList = this.prestationList.filter(
                (x) => x.Data.srcCountryCode != ""
              );
            },
          });

          // Listen for notifications for the emails
          this.notifySub = this.hiero.WatchPrestationMessageNotifiers({
            next: (notifiers) => {
              this.notifiers = notifiers;
            },
          });
          console.log("presta =>", prestations);
          // Process the list for filters, order and paging
          this.processQuery();
        }
      },
    });

    this.profSubs = this.hiero.WatchTraducteurProfile({
      next: (profile: ITraducteur) => {
        if (profile) {
          this.profile = profile;
          console.log("proff =>", this.profile);
        }
      },
    });

    // this.subscription.GetSubscription()
    // this.subscription.observer.subscribe(data => (data != null) ? this.checkedSubscribe = true : this.checkedSubscribe = false)
  }

  ngOnDestroy() {
    if (this.traducteurPrestationSub) {
      this.traducteurPrestationSub.unsubscribe();
    }

    if (this.listSub) {
      this.listSub.unsubscribe();
    }

    if (this.prestations) {
      // DO NOT CLEAN UP HERE: Prestation List is global
      this.prestations = null;
    }

    if (this.notifySub) {
      this.notifySub.unsubscribe();
    }

    if (this.profSubs) {
      this.profSubs.unsubscribe();
    }
  }

  toggleState(filter: IStateFilter) {
    filter.active = !filter.active;
    this.processQuery();
  }

  /* toggleOrder() {
    this.desc = !this.desc;
    this.processQuery();
  } */
  toggleOrderClassic() {
    this.descClassic = !this.descClassic;
    this.classicList = this.classicList.sort((x, y) => {
      if (this.descClassic)
        return (
          new Date(y.Data.createdAt).getTime() -
          new Date(x.Data.createdAt).getTime()
        );
      else
        return (
          new Date(x.Data.createdAt).getTime() -
          new Date(y.Data.createdAt).getTime()
        );
    });
  }
  toggleOrderAsser() {
    this.descAsser = !this.descAsser;
    this.asserList = this.asserList.sort((x, y) => {
      if (this.descAsser)
        return (
          new Date(y.Data.createdAt).getTime() -
          new Date(x.Data.createdAt).getTime()
        );
      else
        return (
          new Date(x.Data.createdAt).getTime() -
          new Date(y.Data.createdAt).getTime()
        );
    });
  }

  private processQuery() {
    const states: EnumPrestationState[] = [];
    this.filterUI.forEach((ui) => {
      if (ui.active) {
        states.push(ui.state);
      }
    });

    this.prestations.updateQuery(
      states,
      [{ column: "createdAt", desc: this.desc }],
      this.page - 1,
      this.pageSize
    );
    this.busy = false;
  }

  open(prestation: Prestation) {
    this.router.navigate(["..", "prestation", prestation.Id, "index"], {
      relativeTo: this.route,
    });
  }

  getUnreadMessages(prestation: Prestation): number {
    const notifier: MessageNotifications = this.notifiers.get(prestation.Id);
    if (notifier) {
      return notifier.Unseen;
    } else {
      return 0;
    }
  }
  alertMessage() {
    this.modal.open(NotSubscribeModalComponent);
  }

  // onPageChange(pageNumber: number) {
  //   this.page = pageNumber;
  //   this.processQuery();
  // }
  onPageChange(pageNumber: number, switchnmbr: number) {
    switch (switchnmbr) {
      case 0:
        this.pageAsser = pageNumber;
        //this.processQuery();
        break;
      case 1:
        this.pageClassic = pageNumber;
        //this.processQuery();
        break;
      default:
        break;
    }
  }

  selectOption(selected: string) {
    switch (+selected) {
      case 0:
        this.showAsser = true;
        this.showClassic = true;
        break;
      case 1:
        this.showAsser = true;
        this.showClassic = false;
        break;
      case 2:
        this.showAsser = false;
        this.showClassic = true;
        break;
      default:
        break;
    }
  }
}
