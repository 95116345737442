import { Component, OnInit } from "@angular/core";
import * as firebase from "firebase";
import { HieroBDD } from "../../../services/hierobdd.service";
import { array } from "yup";
import { KeyValue } from "@angular/common";
import { franceRelance } from "../../../utility/components/franceRelance";

@Component({
  selector: "card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnInit {
  hasSubscription: any;
  hasSubscriptionFranceRelance: boolean;
  public tabFonct: any = {
    "Système de gestion des missions": [true, true, true],
    "Tableau de bord interactif": [true, true, true],
    "Consultation et réponse aux missions proposées": [true, true, true],
    "Alertes immédiates de nouvelles missions par mail": [true, true, true],
    "Édition de modèles automatique": [true, true, true],
    "Discussion automatique avec vos clients": [true, true, true],
    "Gestion de la comptabilité": [true, true, true],
    "Facturation automatique": [true, true, true],
    "Account manager à vos côtés": [true, true, true],
    "Application mobile pour la gestion à distance": [false, true, true],
    "Ajout de votre en-tête personnalisée": [false, false, true],
    "Récurrence client": [false, false, true],
    "Importation de vos propres clients": [false, false, true],
    "Mémoire de traduction": [false, false, true],
  };

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  constructor(private hiero: HieroBDD) {}

  async ngOnInit(): Promise<void> {
    this.getCustomerSubscription().then((value) => {
      this.hasSubscription = value;
    });

    franceRelance.includes(this.hiero.Auth.User.Id)
      ? (this.hasSubscriptionFranceRelance = true)
      : (this.hasSubscriptionFranceRelance = false);
  }

  async paid(prix) {
    let db = firebase.firestore();

    const docRef = await db
      .collection("customers")
      .doc(firebase.auth().currentUser.uid)
      .collection("checkout_sessions")
      .add({
        payment_method_types: ["card", "sepa_debit"],
        price: prix,
        allow_promotion_codes: true,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url }: any = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  }

  async gestion() {
    const functionRef = firebase
      .app()
      .functions("europe-west3")
      .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
      returnUrl: window.location.origin,
      locale: "auto", // Optional, defaults to "auto"
    });
    window.location.assign(data.url);
  }

  async getCustomerSubscription() {
    let db = firebase.firestore();

    const request = db
      .collection("customers")
      .doc(firebase.auth().currentUser.uid)
      .collection("subscriptions")
      .where("status", "in", ["trialing", "active"]);

    return request.get().then(async (snapshot) => {
      // In this implementation we only expect one active or trialing subscription to exist.
      const doc = snapshot.docs[0];
      if (doc) {
        return doc.data().items[0].price;
      } else {
        return false;
      }
    });
  }
}
