
import * as yup from 'yup';

// Parts of the user profile required for user signup
export interface ISignupUserProfile {
  givenName: string;
  familyName: string;
  telephone: string;
  subscription?: string;
}

export interface IUserProfile extends ISignupUserProfile {
  // TODO: Add fields here for generic user profile
  // These fields are ones that are not required for signup
  address?: string;
}


export const UserProfileSchema = yup.object({
  givenName: yup.string().required(),
  familyName: yup.string().required(),
  telephone: yup.string().required(),
  subscription: yup.string()
});

export const EmptyUserProfile = UserProfileSchema.cast({});

