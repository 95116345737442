import { Component, NgZone } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ESignupType } from '../../../../../common/src/bdd/user/AuthService';
import { HieroBDD } from '../../../services/hierobdd.service';
import { SubmitForm, ISubmitFormInputErrors } from '../../../../../common/src/utility/forms/submitform.class';
import { LocalisationService } from '../../../../../common/src/modules/localisation/localisation.service';
import { Config } from '../../../../../common/src/services/config.service';

@Component({
  templateUrl: './reset-password.component.html'
})

export class ResetPasswordComponent {

  busy: boolean;

  resetPassForm: SubmitForm;
  sentOk = false;

  constructor(
    private fb: FormBuilder,
    private hiero: HieroBDD,
    private router: Router,
    private zone: NgZone,
    private localisation: LocalisationService,
    private config: Config
  ) {
    this.createForm();
  }


  createForm() {
    this.resetPassForm = new SubmitForm(
      this.fb,
      [
        {
          name: 'email',
          value: '',
          validators: [Validators.required, Validators.email],
          type: 'email',
          title: this.localisation.localise('reset_password_email_title'),
          autocomplete: 'email',
          placeholder:  this.localisation.localise('reset_password_email_placeholder'),
          help: this.localisation.localise('reset_password_email_help'),
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('reset_password_error_required'),
            },
            <ISubmitFormInputErrors>{
              code: 'email',
              message: this.localisation.localise('reset_password_error_invalid_email'),
            },
            <ISubmitFormInputErrors>{
              code: 'isEmail',
              message: this.localisation.localise('reset_password_error_invalid_email'),
            },
            <ISubmitFormInputErrors>{
              code: 'notFound',
              message: this.localisation.localise('reset_password_error_user_not_found'),
            }
          ]
        }
      ],
      // Submit callback
      data => {
        this.busy = true;
        this.sentOk = false;

        // Set the message language
        this.hiero.Auth.setAuthLanguage(this.localisation.CurrentLanguageISO639);

        // Send email
        const prom = this.hiero.Auth.sendPasswordReset(
          data.email,
          this.config.Environment.resetRedirectUrl
        );

        return prom;
      },

      // Success callback
      () => {
        console.log('Success.');
        this.sentOk = true;
        this.busy = false;
      },

      // Fail callback
      err => {
        // What to do with login failuer
        this.busy = false;
      },

      // Changes callback
      null
    );
  }

}
