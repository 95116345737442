		<!-- begin error -->
		<div class="error">
			<div class="error-code m-b-10">404</div>
			<div class="error-content">
				<div class="error-message">{{'not_found_title' | localise}}</div>
				<div class="error-desc m-b-30">
						{{'not_found_subtitle' | localise}}<br />
						<span [innerHTML]="'not_found_action' | localise"></span>
				</div>
				<div>
					<a [routerLink]="['/app']" class="btn btn-success p-l-20 p-r-20">{{'not_found_home' | localise}}</a>
				</div>
			</div>
		</div>
		<!-- end error -->