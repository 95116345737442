import { PartialObserver } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { Admin } from '../admin/Admin';
import { ITemplate } from '../interfaces/ITemplate';
import { Template } from './Template';
import { User } from '../user/User';
import { NewTemplate } from '../../../../../seed/create_new_template_collection';


const FIREBASE_TEMPLATE_COLLECTION = 'templates';


export interface QueryOrderParam {
  column: string;
  desc: boolean;
}


export interface QueryFilterColumnValue {
  column: string;
  value: string;
}

export interface QueryFilterParam {
  and: QueryFilterColumnValue[];
}

export class TemplateList {

  private _user: User;

  private _templateMap: Map<string, Template>;
  private _templateArr: Template[];
  private _filteredArr: Template[];

  private _stopListening;
  private _listSubject: BehaviorSubject<Template[]>;

  private _order: QueryOrderParam[];
  private _filter: QueryFilterParam[];

  private _totalFilteredUnpagedCount = 0;

  private _page = 0;
  private _pageSize = 10;

  public getData: (columnName: string, data: any) => any = null;

  private constructor(user: User, query: firebase.firestore.Query, initSnap: firebase.firestore.QuerySnapshot, watch: boolean) {


    this._user = user;
    this.reconstruct(initSnap);
    this._listSubject = new BehaviorSubject<Template[]>(this.All);

    if (watch) {
      this._stopListening = query.onSnapshot({
        next: (snapshot: firebase.firestore.QuerySnapshot) => {
          this.reconstruct(snapshot);
        },
        error: (err) => {
        }
      });
    }
  }

  public get Count(): number {
    return this._filteredArr.length;
  }

  /**
   * Gets the number of filtered items before paging
   */
  public get TotalCount(): number {
    return this._totalFilteredUnpagedCount;
  }

  /**
   * Get a copy of your query
   */
  public get All(): Template[] {
    return [...this._filteredArr];
  }

  public updateQuery(filters: QueryFilterParam[], order: QueryOrderParam[], page: number, pageSize: number) {
    this._filter = filters;
    this._order = order;
    this._page = page;
    this._pageSize = pageSize;

    // Filter and order
    this._filteredArr = this.getWithQuery(this._filter, this._order, page, pageSize);

    if (this._listSubject) {
      this._listSubject.next(this.All);
    }
  }


  private getWithQuery(filters: QueryFilterParam[], order: QueryOrderParam[], page: number = 0, pageSize: number = 10) {

    // Filter by selected states
    let result: Template[] = [];
    if (filters && filters.length > 0) {
      const filterSet: Set<string> = new Set<string>();

      filters.forEach(
        (filter) => {

          const filterResult = this._templateArr.filter(
            (template: Template) => {

              // Iterate through ANDS, find one where there is no match
              const noMatch = filter.and.find(
                (colVal) => {
                  return (template.Data[colVal.column] !== colVal.value);
                }
              );
              // If none found, all match, therefore add to filter result
              return (!noMatch);
            }
          );

          // Add only unique to list
          filterResult.forEach(
            (template: Template) => {
              if (!filterSet.has(template.Id)) {
                result.push(template);
                filterSet.add(template.Id);
              }
            }
          );

        }
      );

    } else {
      // No filter means all values
      result = this._templateArr;
    }

    // Order
    if (order && order.length > 0) {
      for (let i = order.length - 1; i >= 0; --i) {
        const orderParam = order[i];

        const sorted: Template[] = result.sort(
          (a: Template, b: Template) => {
            let res = 0;

            let dataA = a.Data[orderParam.column];
            let dataB = a.Data[orderParam.column];
            if (this.getData) {
              dataA = this.getData(orderParam.column, a.Data[orderParam.column]);
              dataB = this.getData(orderParam.column, b.Data[orderParam.column]);
            }
            if (typeof (dataA) === 'string') {
              res = dataA.localeCompare(dataB);
            } else {
              res = dataA - dataB;
            }

            if (orderParam.desc) { res = res * -1; }
            return res;
          }
        );
        result = sorted;
      }
    }



    // Save the total filtered count
    this._totalFilteredUnpagedCount = result.length;

    // Paging
    const startPage = page * pageSize;
    result = result.slice(startPage, startPage + pageSize);

    return result;
  }

  public getTemplate(id: string): Template {
    return this._templateMap.get(id);
  }

  private reconstruct(snap: firebase.firestore.QuerySnapshot) {
    this._templateMap = new Map<string, Template>();
    this._templateArr = [];
    snap.forEach(
      (doc: firebase.firestore.QueryDocumentSnapshot) => {
        const raw = doc.data() as ITemplate;
        const template = new Template(this._user, doc.ref, doc.id, raw);
        this._templateMap.set(doc.id, template);
        this._templateArr.push(template);
      }
    );

    // Filter and order
    this._filteredArr = this.getWithQuery(this._filter, this._order);

    if (this._listSubject) {
      this._listSubject.next(this.All);
    }
  }

  /**
   * Subscribe for changes to this list
   * @param observer 
   */
  public WatchList(observer: PartialObserver<Template[]>): Subscription {
    return this._listSubject.subscribe(observer);
  }

  /**
   * Make sure you call this function to avoid memory leaks.
   */
  public cleanup() {
    if (this._stopListening) {
      this._stopListening();
      this._stopListening = null;
    }
  }


  public static async Init(user: User, watch: boolean) {
    //const first = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION).orderBy('documentTypeId').limit(300)
    //const firstSnapshot = await first.get()

    //const last = firstSnapshot.docs[firstSnapshot.docs.length -1]
    //const next = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION).orderBy('documentTypeId').startAfter(last.data().documentTypeId).limit(150) 
    //const nextSnapshot = await next.get()

    const query = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION).orderBy('documentTypeId').limit(600)
    const snapshot: firebase.firestore.QuerySnapshot = await query.get()
    console.log('snapshot.size from template list => ',snapshot.size)
    const filterTemplate = new NewTemplate(user)
    filterTemplate.regeneratedTemplate()

    /* snapshot.docs.forEach(_ => {
      console.log('elt', _.data())
    })*/

    return new TemplateList(user, query, snapshot, watch);
  }

  public static async CountTemplates(user: User, srcLangIso639: string, destLangIso639: string): Promise<number> {
    const query = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION)
      .where(
        'srcLanguageIso639', '==', srcLangIso639
      )
      .where(
        'destLanguageIso639', '==', destLangIso639
      );

    const snapshot: firebase.firestore.QuerySnapshot = await query.get();
    return snapshot.size;
  }
}
