import { Pipe, PipeTransform } from '@angular/core';
import { Countries } from '../../../common/src/bdd/utility/countries';
import { LocalisationService } from '../../../common/src/modules/localisation/localisation.service';


@Pipe({name: 'country'})
export class CountryPipe implements PipeTransform {

  constructor(
    private localisation: LocalisationService
  ) {
  }

  transform(value: string): string {
    return  Countries.GetLocalizedCountryName(this.localisation.CurrentLanguageDictionary, value);
  }
}
