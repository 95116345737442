import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: './prestationBase.component.html'
})
export class PrestationBaseComponent implements OnInit {

  prestationId: string;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // Get prestation id from params
    this.prestationId = this.route.snapshot.paramMap.get('prestationId');
  }

}
