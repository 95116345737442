<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ 'agency_address_title' | localise }}</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  
  <form [formGroup]="addressform.Group" novalidate style="max-width: 550px">
    <p class="mb-0">{{ 'agency_address_address' | localise }}</p>
    <p class="mb-0"><strong>{{formattedAddress}}</strong></p>
    <div class="row">
      <div class="col-4">
        <app-form-input [parameters]="addressform.GetInputElement('number')"></app-form-input>
      </div>
      <div class="col-8">
        <app-form-input [parameters]="addressform.GetInputElement('street')"></app-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <app-form-input [parameters]="addressform.GetInputElement('code')"></app-form-input>
      </div>
      <div class="col-6">
        <app-form-input [parameters]="addressform.GetInputElement('city')"></app-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <app-form-input [parameters]="addressform.GetInputElement('country')"></app-form-input>
      </div>
    </div>
    <p class="mb-0">{{ 'agency_address_coords' | localise }}</p>
    <div class="row">
      <div class="col-6">
        <app-form-input [parameters]="addressform.GetInputElement('latitude')"></app-form-input>
      </div>
      <div class="col-6">
        <app-form-input [parameters]="addressform.GetInputElement('longitude')"></app-form-input>
      </div>
    </div>
    
  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" ngbAutofocus (click)="modal.dismiss(false)">{{ 'agency_address_cancel' | localise }}</button>
  <button type="button" class="btn btn-success" (click)="close()" [disabled]="!addressform.Valid">{{ 'agency_address_validate' | localise }}</button>
</div>