import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Traducteur } from '../../../../../common/src/bdd/traducteur/Traducteur';
import { Prestation } from '../../../../../common/src/bdd/prestation/Prestation';
import { MessageBox } from '../../../../../common/src/bdd/messaging/MessageBox';
import { IMessage } from '../../../../../common/src/bdd/interfaces/IMessaging';
import { Subscription } from 'rxjs';
import { HieroBDD } from '../../../services/hierobdd.service';
import { File } from '../../../../../common/src/bdd/prestation/File';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class AppChatComponent implements OnChanges, OnDestroy {
  @Input() traducteur: Traducteur;
  @Input() prestation: Prestation;

  messageBox: MessageBox;
  messages: IMessage[] = [];

  private messagesSub: Subscription;

  msgText = '';
  timeOutSubs = null;

  constructor(
    private hiero: HieroBDD
  ) {}

  // Init the message box
  ngOnChanges(changes) {
    if (changes.prestation.currentValue && changes.traducteur.currentValue) {
      // Clear timeout if there is one
      if (this.timeOutSubs) {
        clearTimeout(this.timeOutSubs);
        this.timeOutSubs = null;
      }

      if (this.messageBox) {
        this.messageBox.cleanup();
        this.messageBox = null;
      }

      // Init message box
      MessageBox.InitForTranslatorAndPrestation(changes.traducteur.currentValue, changes.prestation.currentValue)
      .then(
        (messageBox) => {
          this.messageBox = messageBox;
          this.messagesSub = this.messageBox.WatchMessages({
            next: (messages: IMessage[]) => {
              this.messages = messages;

              // TODO: Refine this to actual message, for now, if on this page, assume seen all!
              this.timeOutSubs = setTimeout(() => {
                this.hiero.SetSeen(this.prestation.Id);
              }, 5000);
            }
          });
        }
      );
    }
  }

  ngOnDestroy() {
    if (this.messageBox) {
      this.messageBox.cleanup();
      this.messagesSub.unsubscribe();
      this.messageBox = null;
    }

    if (this.timeOutSubs) {
      clearTimeout(this.timeOutSubs);
      this.timeOutSubs = null;
    }
  }

  public send() {
    const clean = this.msgText.trim();
    if (clean.length === 0) {
      return;
    }

    this.msgText = clean;

    this.messageBox.AddMessage(this.prestation, clean);
    this.msgText = '';

  }

  public onNewMessage(text: string) {
    this.msgText = text;
  }

  public onKeydown(event) {
    if (event.key === 'Enter' || event.key === 'Return') {
      this.send();
    }
  }

  public isMe(message: IMessage) {
    return message.senderUid === this.traducteur.User.Id;
  }

  public isNotMe(message: IMessage) {
    return message.senderUid !== this.traducteur.User.Id;
  }

  public async openChatFile(message: IMessage) {
    const fileObj: File = new File(this.hiero.Storage, this.prestation.Data.uid, this.prestation.Data, 'chat', message.file);
    try {
      const link = await fileObj.GetDownloadLink();
      window.open(link, '_blank');
    } catch (err) {
      console.error(err);
    }
  }

}
