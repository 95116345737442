<!-- <full-calendar [options]="calendarOptions"></full-calendar> -->
<div class="calendar">
  <full-calendar
    defaultView="dayGridMonth"
    [plugins]="calendarPlugins"
    [weekends]="true"
    [events]="calendarEvents"
    [header]="{
      left: 'dayGridMonth,timeGridWeek,timeGridDay',
      center: 'title',
      right: 'prev,next'
    }"
    [buttonIcons]="{
      prev: 'left-single-arrow',
      next: 'right-single-arrow'
    }"
    nowIndicator="true"
    [eventTimeFormat]="{
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }"
    displayEventEnd="true"
    (eventClick)="test($event)"
  ></full-calendar>
</div>
