import { Injectable } from "@angular/core";
import * as firebase from "firebase";
import { ITraducteur } from "../../../common/src/bdd/interfaces/ITraducteur";
import { HieroBDD } from "./hierobdd.service";

const TRANSLATOR_COLLECTION = "traducteurs";

@Injectable()
export class TranslatorService {
  private user = firebase.auth().currentUser;

  constructor(private hiero: HieroBDD) {}

  public async getTranslatorId(): Promise<string> {
    let translator = await this.hiero.DB.collection(TRANSLATOR_COLLECTION)
      .where("uid", "==", this.user.uid)
      .get()
      .then((snap) =>
        snap.docs.map((_) => {
          return _.id;
        })
      );

    return translator[0];
  }

  public async getTranslatorData(): Promise<ITraducteur> {
    let translator = await this.hiero.DB.collection(TRANSLATOR_COLLECTION)
      .where("uid", "==", this.user.uid)
      .get()
      .then((snap) =>
        snap.docs.map((_) => {
          return _.data();
        })
      );

    return translator[0] as ITraducteur;
  }
}
