import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "sortDateInterpretation" })
export class SortDateInterpretationPipe implements PipeTransform {
  transform(value: any[]): any[] {
    return value.sort((a, b) => {
      return b.data.sendTime.toDate() - a.data.sendTime.toDate();
    });
  }
}
