import { Component, Input, OnDestroy, OnInit, AfterViewInit, Directive, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DocumentType } from '../../../../../../common/src/bdd/documents/DocumentType';
import { Template } from '../../../../../../common/src/bdd/documents/Template';
import { TemplateList } from '../../../../../../common/src/bdd/documents/TemplateList';
import { IDocumentType } from '../../../../../../common/src/bdd/interfaces/IDocumentType';
import { EnumTraducteurServiceExceptionType } from '../../../../../../common/src/bdd/interfaces/ITraducteurService';
import { TraducteurService } from '../../../../../../common/src/bdd/traducteur/TraducteurService';
import { Countries } from '../../../../../../common/src/bdd/utility/countries';
import { HieroBDD } from '../../../../services/hierobdd.service';
import { IAppFormDropdowParameters, IDDFormNameData } from '../../../../../../common/src/utility/forms/app-form-dropdown/app-form-dropdown.component';
import { SubmitForm } from '../../../../../../common/src/utility/forms/submitform.class';
import { LocalisationService } from '../../../../../../common/src/modules/localisation/localisation.service';

interface ITemplateDetail {
  template: Template;
  on: boolean;
  priceHT: number;
  overridePriceHT: number;
  localisedCountry: string;
}

//////////////////

export type SortDirection = 'asc' | 'desc' | '';
const rotate: {[key: string]: SortDirection} = { 'asc': 'desc', 'desc': 'asc', '': 'desc' };
export const compare = (v1, v2) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class NgbdSortableHeader {

  @Input() sortable: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({column: this.sortable, direction: this.direction});
  }
}

//////////////////


@Component({
  selector: 'app-traducteur-service',
  templateUrl: './traducteurService.component.html',
  styleUrls: ['./traducteurService.component.scss']
})
export class TraducteurServiceComponent implements OnDestroy, OnInit {
  @Input() service: TraducteurService;
  @Input() templateList: TemplateList;
  @Input() documentTypeMap: DocumentType;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  busy = true;


  templates: Template[];
  templateDetail: ITemplateDetail[];

  templateSub: Subscription;

  sortColumn = 'localisedCountry';
  sortDirection = 'asc';

  constructor(
    private hiero: HieroBDD,
    private localisation: LocalisationService
  ) {
  }

  ngOnInit() {
    this.templateList.updateQuery(
      [
        {
          and: [
            {
              column: 'srcLanguageIso639',
              value: this.service.data.srcLanguageIso639
            },
            {
              column: 'destLanguageIso639',
              value: this.service.data.destLanguageIso639
            },
          ]
        }
      ],
      [
        {
          column: 'srcCountryCode',
          desc: false
        }
      ],
      0,
      100000
    );
    this.templateSub = this.templateList.WatchList({
      next: (temps: Template[]) => {
        this.updateAll(temps);
        this.busy = false;
      }
    });
  }

  getCountryName(code: string) {
    return Countries.GetLocalizedCountryName(this.localisation.CurrentLanguageDictionary, code);
  }

  getDocumentName(code: string) {
    if (this.documentTypeMap) {
      return this.documentTypeMap.GetDocumentName(this.localisation.CurrentLanguageISO639, code);
    } else {
      return '';
    }
  }



  private updateAll(templates: Template[]) {
    this.templates = templates;
    this.templateDetail = [];
    this.templates.forEach(
      (template) => {

        let isOn = true;
        const price = template.Data.priceHT;

        let overridePrice = -1;
        if (this.service.data.exceptions) {
          const exception = this.service.data.exceptions.find(exc => exc.templateId === template.Id);
          if (exception) {
            isOn = (exception.type !== EnumTraducteurServiceExceptionType.DO_NOT_HANDLE);
            if (exception.type === EnumTraducteurServiceExceptionType.DIFFERENT_PRICE) {
              overridePrice = exception.priceHT;
            }
          }
        }

        this.templateDetail.push({
          template: template,
          on: isOn,
          priceHT: price,
          overridePriceHT: overridePrice,

          localisedCountry: Countries.GetCountryName('fr', template.Data.srcCountryCode)
        });
      }
    );

    this.sortByColumn();
  }

  ngOnDestroy() {
    if (this.templateSub) {
      this.templateSub.unsubscribe();
    }
    if (this.templateList) {
      this.templateList.cleanup();
    }
  }

  delete() {
    this.busy = true;
    try {
      this.service.delete();
    } catch (err) {
    }
  }

  async onToggled(isOn: boolean, template: ITemplateDetail) {
    this.busy = true;
    if (!isOn) {
      await this.service.addException(template.template.Id, EnumTraducteurServiceExceptionType.DO_NOT_HANDLE);
    } else {
      await this.service.removeException(template.template.Id);
    }
  }

  onUpdate(value, template: ITemplateDetail) {
    template.overridePriceHT = parseFloat(value);
    this.updateException(template);
  }

  async clearPriceException(template: ITemplateDetail) {
    template.overridePriceHT = -1;
    await this.updateException(template);
  }

  async updateException(template: ITemplateDetail) {
    this.busy = true;
    if (template.overridePriceHT > -1) {
      await this.service.addException(template.template.Id, EnumTraducteurServiceExceptionType.DIFFERENT_PRICE, template.overridePriceHT);
    } else {
      await this.service.removeException(template.template.Id);
    }
  }


  onSort({column, direction}: SortEvent) {
    // sorting countries
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortByColumn();
  }

  sortByColumn() {

    // resetting other headers
    if (this.headers) {
      this.headers.forEach(header => {
        if (header.sortable !== this.sortColumn) {
          header.direction = '';
        }
      });
    }

    this.templateDetail = this.templateDetail.sort((a, b) => {
      const res = compare(a[this.sortColumn], b[this.sortColumn]);
      return this.sortDirection === 'asc' ? res : -res;

    });
  }

}
