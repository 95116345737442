<div class="row">
  <!-- BEGIN col-3 -->
  <div class="col-xl-3 col-md-6">
    <div class="card widget widget-stats bg-white">
      <div class="stats-icon stats-icon-lg">
        <i class="fa fa-euro fa-fw"></i>
      </div>
      <div class="stats-content">
        <div class="stats-title text-dark">
          <i class="text-info h4 fa fa-balance-scale fa-fw"></i>
          <span class="h5 ml-3">{{ "dashboard_turnover" | localise }}</span>
        </div>
        <div class="stats-number text-dark">
          <span class="mr-3">
            {{ sales }} <span class="text-info">&euro;</span>
          </span>
          <span
            [ngClass]="
              rateSales > 0
                ? 'ml-5 text-success font-weight-bold font-italic h6'
                : 'ml-5 text-danger font-weight-bold font-italic h6'
            "
            >{{ rateSales > 0 ? "+" + rateSales : rateSales }}%</span
          >
        </div>
        <div class="stats-progress progress">
          <div
            [ngClass]="
              rateSales > 0
                ? 'progress-bar bg-success'
                : 'progress bar bg-danger'
            "
            [style.width.%]="rateSales > 0 ? rateSales : -rateSales"
          ></div>
        </div>
        <div class="stats-desc text-dark">
          {{ "dashboard_evolution_month" | localise }}
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-md-6">
    <div class="card widget widget-stats bg-white">
      <div class="stats-icon stats-icon-lg">
        <i class="fa fa-language fa-fw"></i>
      </div>
      <div class="stats-content">
        <div class="stats-title text-dark">
          <i class="text-info h4 fa fa-language fa-fw"></i>
          <span class="ml-3 h5">{{ "dashboard_trad_month" | localise }}</span>
        </div>
        <div class="stats-number text-dark">
          <span class="mr-4">{{ numberTrad }}</span>
          <span
            [ngClass]="
              rateTrad > 0
                ? 'ml-5 text-success font-weight-bold font-italic h6'
                : 'ml-5 text-danger font-weight-bold font-italic h6'
            "
            >{{ rateTrad > 0 ? "+" + rateTrad : rateTrad }}%</span
          >
        </div>
        <div class="stats-progress progress">
          <div
            [ngClass]="
              rateTrad > 0
                ? 'progress-bar bg-success'
                : 'progress bar bg-danger'
            "
            [style.width.%]="rateTrad > 0 ? rateTrad : -rateTrad"
          ></div>
        </div>
        <div class="stats-desc text-dark">
          {{ "dashboard_evolution_month" | localise }}
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-md-6">
    <div class="card widget widget-stats bg-white">
      <div class="stats-icon stats-icon-lg">
        <i class="fa fa-comments fa-fw"></i>
      </div>
      <div class="stats-content">
        <div class="stats-title text-dark">
          <i class="text-info h4 fa fa-comment fa-fw h5"></i>
          <span class="ml-3 h5">{{
            "dashboard_interpretation_month" | localise
          }}</span>
        </div>
        <div class="stats-number text-dark">
          <span class="mr-4">{{ numberInterpretation }}</span>
          <span
            [ngClass]="
              rateInter > 0
                ? 'ml-5 text-success font-weight-bold font-italic h6'
                : 'ml-5 text-danger font-weight-bold font-italic h6'
            "
            >{{ rateInter > 0 ? "+" + rateInter : rateInter }}%</span
          >
        </div>
        <div class="stats-progress progress">
          <div
            [ngClass]="
              rateInter > 0
                ? 'progress-bar bg-success'
                : 'progress bar bg-danger'
            "
            [style.width.%]="rateInter > 0 ? rateInter : -rateInter"
          ></div>
        </div>
        <div class="stats-desc text-dark">
          {{ "dashboard_evolution_month" | localise }}
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-md-6">
    <div class="card widget widget-stats bg-white">
      <div class="stats-icon stats-icon-lg">
        <i class="fa fa-globe fa-fw"></i>
      </div>
      <div class="stats-content">
        <div class="stats-title text-dark">
          <i class="text-info h4 fa fa-folder-open fa-fw h5"></i>
          <span class="h5 ml-3">{{ "dashboard_new_missions" | localise }}</span>
        </div>
        <div class="stats-number text-dark">
          <span class="mr-4">{{ newMission }}</span>
          <span
            [ngClass]="
              rateNewMissions > 0
                ? 'ml-5 text-success font-weight-bold font-italic h6'
                : 'ml-5 text-danger font-weight-bold font-italic h6'
            "
            >{{
              rateNewMissions > 0 ? "+" + rateNewMissions : rateNewMissions
            }}
            %</span
          >
        </div>
        <div class="stats-progress progress">
          <div
            [ngClass]="
              rateNewMissions > 0
                ? 'progress-bar bg-success'
                : 'progress bar bg-danger'
            "
            [style.width.%]="
              rateNewMissions > 0 ? rateNewMissions : -rateNewMissions
            "
          ></div>
        </div>
        <div class="stats-desc text-dark">
          {{ "dashboard_evolution_month" | localise }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" style="margin-top: 30px">
  <div class="col-xl-6 col-lg-6">
    <div class="card">
      <canvas id="lineChart"></canvas>
    </div>
  </div>

  <div class="col-xl-6 col-lg-6">
    <div class="font-weight-bold">
      <i class="text-info mr-2 h4 fa fa-folder-open fa-fw h5"></i
      >{{ "dashboard_my_missions" | localise }}
    </div>
    <table class="table table-striped mt-3">
      <tbody>
        <tr *ngFor="let commande of commandeList | slice: 0:3">
          <th scope="row">
            <div class="text-info h6" style="margin-top: -10px">
              <i class="text-info h6 fa fa-folder fa-fw"></i>
              {{
                commande.srcCountryCode != ""
                  ? ("dashboard_traductor_asser" | localise)
                  : ("dashboard_traductor_classic" | localise)
              }}
            </div>
            <div class="h6 ml-4" style="margin-top: -10px; margin-bottom: 21px">
              {{ "dashboard_type" | localise }}
              <span class="text-info">{{
                commande.documents[0].documentTypeId.substr(0, 17)
              }}</span>
            </div>
          </th>
          <td>
            <div class="text-info">
              {{ "order_translate" | localise }}
              {{ commande.srcLanguageIso639 | iso639ToNative }}
            </div>
            <div>
              {{ "order_translate_to" | localise }}
              {{ commande.destLanguageIso639 | iso639ToNative }}
            </div>
          </td>
          <td>
            <div>
              {{ "dashboard_command_date" | localise }}
              {{ commande.createdAt | date: "d/MM/yyyy" }}
            </div>
            <div
              [style.color]="
                commande.state === 'validated' ||
                commande.state === 'validated-pro'
                  ? '#75af75'
                  : '#cf812d'
              "
            >
              <span class="text-dark">
                {{ "dashboard_status" | localise }}
              </span>
              <span class="h6">{{
                (commande.state | prestationStateName).length > 22
                  ? (commande.state | prestationStateName).substr(0, 19) + "..."
                  : (commande.state | prestationStateName)
              }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row">
  <div class="col-xl-4 col-lg-6 mt-5">
    <div class="card">
      <div>
        <p class="h5 font-weight-bold ml-2">
          <i class="text-info h4 fa fa-briefcase fa-fw"></i
          >{{ "dashboard_total_sells" | localise }}
        </p>
      </div>

      <div class="ml-5">
        <span class="mr-5 font-italic">{{ "dashboard_trad" | localise }}</span>
        <span class="ml-3 font-italic"
          >{{ "dashboard_interpretation" | localise }}
        </span>
      </div>

      <div class="ml-5">
        <span class="mr-5 mt-4"
          >{{ totalTraduction }}
          <span class="text-info font-weight-bold"> &euro; </span>
        </span>
        <span class="ml-5 mt-4"
          >{{ totalInterpretation }}
          <span class="text-info font-weight-bold"> &euro; </span>
        </span>
      </div>

      <canvas id="barChart" style="height: 240px"></canvas>
    </div>
  </div>

  <div class="col-xl-4 col-lg-6 mt-5">
    <div class="card">
      <div>
        <p class="h5 font-weight-bold ml-2">
          <i class="text-info h4 fa fa-archive fa-fw"></i>
          {{ "dashboard_doc_type" | localise }}
        </p>
      </div>

      <canvas id="doughnutChart" style="height: 280px"></canvas>
    </div>
  </div>

  <div class="col-xl-4 col-lg-6 mt-5">
    <div class="card">
      <div>
        <p class="h5 font-weight-bold ml-2">
          <i class="text-info h4 fa fa-users fa-fw"></i
          >{{ "dashboard_my_clients" | localise }}
        </p>
      </div>

      <table class="table table-striped">
        <tbody>
          <tr *ngFor="let customer of customers">
            <th scope="row">
              <div class="text-info h6" style="margin-top: -20px">
                <i class="text-secondary h3 fa fa-user-circle fa-fw"></i>
                {{ customer.nom }}
              </div>
              <div class="h6 ml-4 font-italic">
                {{
                  customer.type.substr(29, 41) === "particulier"
                    ? ("dashboard_particulier" | localise)
                    : ("dashboard_pro" | localise)
                }}
                <span class="font-italic"></span>
              </div>
            </th>
            <td>
              <div></div>
              <i class="text-secondary h4 fa fa-comments fa-fw"></i>
              <i class="text-secondary h4 fa fa-folder-open fa-fw"></i>
              <i class="text-secondary h4 fa fa-phone fa-fw"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
