import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { Subscription } from "rxjs/Subscription";
import { EnumPrestationState } from "../../../../../common/src/bdd/interfaces/IPrestation";
import { ITraducteur } from "../../../../../common/src/bdd/interfaces/ITraducteur";
import { IUserProfile } from "../../../../../common/src/bdd/interfaces/IUserProfile";
import { Traducteur } from "../../../../../common/src/bdd/traducteur/Traducteur";
import { IPrestationNotification } from "../../../../../common/src/bdd/traducteur/TraducteurPrestations";
import { User } from "../../../../../common/src/bdd/user/User";
import { LocalisationService } from "../../../../../common/src/modules/localisation/localisation.service";
import { HieroBDD } from "../../../services/hierobdd.service";
import {
  ITitleInfo,
  TitleExtractor,
} from "../../../../../common/src/utility/title_extractor.class";
import { IsValidProfile } from "../../verify/profile/profilecheck.component";
import * as firebase from "firebase";
import { ProfilePictureService } from "../../../services/profile-picture.services";

@Component({
  templateUrl: "./appbase.component.html",
  styleUrls: ["./appbase.component.scss"],
})
export class AppBaseComponent implements OnInit, OnDestroy {
  @ViewChild("sidebar") sidebar: ElementRef;

  private _titleExtractor: TitleExtractor;
  titles: ITitleInfo[] = [];
  public photo: any;

  sideBarOpen: boolean;

  user: User;
  profile: IUserProfile;
  translator: ITraducteur;

  private _subscription: Subscription;
  private _eventSubs: Subscription;
  private _routerSubs: Subscription;
  private _translatorSub: Subscription;

  private _translatorProfile: Subscription;

  constructor(
    private hiero: HieroBDD,
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private zone: NgZone,
    private localisation: LocalisationService,
    private pfpService: ProfilePictureService
  ) {
    this._titleExtractor = new TitleExtractor(
      route,
      (key: string) => {
        // Replace strings here if necessary

        return "";
      },
      (titles: ITitleInfo[]) => {
        this.titles = titles;
      }
    );

    this._eventSubs = hiero.WatchPrestationEvent({
      next: (event: IPrestationNotification) => {
        let msg = null;
        switch (event.to) {
          case EnumPrestationState.WaitingForTranslator:
            msg = localisation.localise("notifications_new_task");
            break;
          case EnumPrestationState.Translating:
            msg = localisation.localise("notifications_task_confirmed");
            break;
          case EnumPrestationState.Validated:
            msg = localisation.localise("notifications_task_validated");
            break;
        }

        if (msg) {
          this.notifier.notify("success", msg);
        }
      },
    });
  }

  getTitle(): string {
    if (this.titles && this.titles.length > 0) {
      const titleKey = this.titles[this.titles.length - 1].title;
      return this.localisation.localise(titleKey);
    } else {
      return "";
    }
  }

  ngOnInit() {
    this.pfpService.pfp$.subscribe((newPic) => (this.photo = newPic));

    this._subscription = this.hiero.Auth.WatchUser({
      next: (user: User) => {
        this.user = user;

        if (this.user) {
          this.user.WatchProfile({
            next: (profile: IUserProfile) => {
              this.profile = profile;
            },
          });
        }
      },
    });

    // ESSENTIAL: TRANSLATOR DETAILS
    this._translatorProfile = this.hiero.WatchTraducteurProfile({
      next: (profile: ITraducteur) => {
        if (profile) {
          this.translator = profile;

          if (
            firebase.auth().currentUser.emailVerified === true ||
            new Date(
              firebase.auth().currentUser.metadata.creationTime
            ).getTime() < 1644879600000
          ) {
          } else if (
            firebase.auth().currentUser.emailVerified === false &&
            new Date(
              firebase.auth().currentUser.metadata.creationTime
            ).getTime() >= 1644879600000
          ) {
            this.router.navigate(["compte", "connexion"]);
          }

          if (!IsValidProfile(profile)) {
            this.router.navigate(["verify"]);
          } else {
            // Check if we have already defined some services.
            this._translatorSub = this.hiero.WatchTraducteur({
              next: async (translator: Traducteur) => {
                const hasServices = await translator.hasServices();
                this._translatorSub.unsubscribe();
                // if (!hasServices) {
                //   // navigate to subscription
                //   this.router.navigate(['app', 'card']);
                // }
              },
            });
          }
        }
      },
    });

    this._titleExtractor.update();
    this._routerSubs = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._titleExtractor.update();
      }
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
    this._eventSubs.unsubscribe();
    this._translatorProfile.unsubscribe();
    this._routerSubs.unsubscribe();
    if (this._translatorSub) {
      this._translatorSub.unsubscribe();
    }
  }

  async logout() {
    await this.hiero.Auth.logout();
    this.router.navigate(["compte", "connexion"]);
  }

  toggleSidebar() {
    this.sidebar.nativeElement.style = "width: 30px";
  }
}
