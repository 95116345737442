import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { PDFRender } from './pdfrender';


@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdfpreview.component.html',
  styleUrls: ['./pdfpreview.component.scss']
})
export class PDFPreviewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() pdf: PDFRender;
  @ViewChild('pdfEmbed', { static: true }) pdfEmbed: ElementRef;
  @ViewChild('pdfEmbedSafari', { static: true }) pdfEmbedSafari: ElementRef;
  @ViewChild('pdfContainer', { static: true }) pdfContainer: ElementRef;
  @Output() updateHeight: EventEmitter<number> = new EventEmitter();

  private subs: Subscription;

  ngOnInit() {
    this.resubscribe();
  }

  private resubscribe() {
    if (this.subs) {
      this.subs.unsubscribe();
    }

    this.subs = this.pdf.Watch({
      next: (pdfString) => {
        if (!!(<any>window).ApplePaySession) {
          this.pdfEmbedSafari.nativeElement.setAttribute('src', pdfString);
          this.pdfEmbed.nativeElement.style = 'display: none';
        } else {
          this.pdfEmbed.nativeElement.setAttribute('src', pdfString);
          this.pdfEmbedSafari.nativeElement.style = 'display: none';
        }
        this.pdfContainer.nativeElement.style = 'height: ' + (this.pdf.Height)  + 'px';
        this.updateHeight.emit(this.pdf.Height);
      }
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('pdf' in changes) {
      this.resubscribe();
    }
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

}

