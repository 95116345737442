<div class="row">
  <div class="col-6">
    <h4 class="m-t-0">{{ "support_title" | localise }}</h4>
    <b>{{ "support_company" | localise }}</b>
    <p class="m-b-15">
      {{ "support_addr1" | localise }}<br />
      {{ "support_addr2" | localise }}<br />
      {{ "support_addr3" | localise }}<br />
    </p>
    <div>
      <b>{{ "support_contact" | localise }}</b>
    </div>
    <p class="m-b-15">
      <a class="text-inverse"
        ><img
          height="20px"
          width="20px"
          src="../../../assets/img/flags/france.png"
        />{{ "support_tel" | localise }}</a
      ><br />
      <a class="text-inverse"
        ><img
          height="20px"
          width="20px"
          src="../../../assets/img/flags/picto-es.ico"
        />{{ "support_tel_es" | localise }}</a
      ><br />
      <a class="text-inverse"
        ><img
          height="20px"
          width="20px"
          src="../../../assets/img/flags/picto-en.ico"
        />{{ "support_tel_en" | localise }}</a
      ><br />
      <span [innerHTML]="'support_email' | localise"></span><br />
    </p>
  </div>
  <div class="col-6">
    <div class="embed-responsive embed-responsive-16by9 m-b-15">
      <iframe
        class="embed-responsive-item"
        src="https://maps.google.com/maps?q=7%20rue%20de%20montespan%20every&t=&z=13&ie=UTF8&iwloc=&output=embed"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</div>

<!--
<style type="text/css">
	.column {
		float: left;
		width: 45%;
		margin-left: auto;
		margin-right: auto;
	}

	/* Clear floats after the columns */
	.row:after {
		content: "";
		display: table;
		clear: both;
	}
</style>

<div class="row">
	<div class="column">
		<div class="container">

			<div class="accordion faq" id="faq-list">
				<div class="card">
					<a href="#" class="card-header bg-black text-white" id="faq-1-heading" data-toggle="collapse"
						data-target="#faq-1">
						<i class="fa fa-question-circle fa-fw m-r-5"></i> 1. Le paiement est-il sécurisé ?
					</a>
					<div id="faq-1" class="collapse show" data-parent="#faq-list">
						<div class="card-body">
							<p class="m-b-0">
								L'utilisateur doit effectuer le paiement avant toute traduction, ce qui vous garanti votre versement en
								fin de prestation
							</p>
						</div>
					</div>
				</div>

				<div class="card">
					<a href="#" class="card-header bg-black text-white collapsed" id="faq-2-heading" data-toggle="collapse"
						data-target="#faq-2">
						<i class="fa fa-question-circle fa-fw text-muted m-r-5"></i> 2. Aucune mission ne me parvient
					</a>
					<div id="faq-2" class="collapse" data-parent="#faq-list">
						<div class="card-body">
							<p class="m-b-0">
								Actuellement en phase de béta-test, la plateforme Hiero se met en place et développe encore de nouvelles
								fonctionnalités. Il se peut que vous rencontriez quelques bugs lors de vos premières utilisations.
							</p>
						</div>
					</div>
				</div>

				<div class="card">
					<a href="#" class="card-header bg-black text-white collapsed" id="faq-3-heading" data-toggle="collapse"
						data-target="#faq-3">
						<i class="fa fa-question-circle fa-fw text-primary m-r-5"></i> 3. Comment confirmer mon assermentation ?
					</a>
					<div id="faq-3" class="collapse" data-parent="#faq-list">
						<div class="card-body">
							<p class="m-b-0">
								Veuillez envoyer un e-mail au support technique ( contact@byme-univers.com ) Hiero qui traitera votre
								demande. Vous devez fournir votre justificatif d'assermentation pour bénéficier de ce titre.
							</p>
						</div>
					</div>
				</div>

				<div class="card">
					<a href="#" class="card-header bg-black text-white collapsed" id="faq-4-heading" data-toggle="collapse"
						data-target="#faq-4">
						<i class="fa fa-question-circle fa-fw text-info m-r-5"></i> 4. Je souhaite proposer une idée d'amélioration
						pour la plateforme
					</a>
					<div id="faq-4" class="collapse" data-parent="#faq-list">
						<div class="card-body">
							<p class="m-b-0">
								Nous sommes à l'écoute de vos suggestions et autres demandes pour améliorer la plateforme. Les
								fonctionnalités supplémentaires seront ajoutées dans le temps. Pour ce faire, contactez-nous en nous
								soumétant votre idée.
							</p>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
-->
