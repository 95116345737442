<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ header }}</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{ message }}  
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" ngbAutofocus (click)="modal.dismiss(false)">{{ cancel }}</button>
  <button type="button" class="btn btn-success" (click)="modal.close(true)">{{ ok }}  </button>
</div>