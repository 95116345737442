import * as firebase from "firebase/app";
import { Subscription } from "rxjs/Subscription";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { PartialObserver } from "rxjs";
import { IUserProfile, UserProfileSchema } from "../interfaces/IUserProfile";

export enum ERoleType {
  client = "client",
  traducteur = "traducteur",
  admin = "admin",
  pro = "pro",
}

export interface IRole {
  type: ERoleType;
  access: ERoleAccess;
}

enum ERoleAccess {
  granted = "granted",
  denied = "denied",
}

enum EAsyncResult {
  DoNothing,
  Negative,
  Positive,
}

/**
 * The User class represents a user in the Hiero system, and is generally identified by an email address.
 * A user may be a translator AND a paying customer, in both cases he/she will have the same ID address.
 * For this reason, during sign-up or login, we must specify which ROLE we are interested in.
 *
 * This class will automatically monitor for changes to the user profile, and roles through the Watch* methods.
 */

export class User {
  /**
   * The user id as in the Firestore database
   */
  get Id(): string {
    return this._fbUser.uid;
  }

  /**
   * The user email address
   */
  get Email(): string {
    return this._fbUser.email;
  }

  /**
   * Reference to the firestore database.
   */
  get DB(): firebase.firestore.Firestore {
    return this._db;
  }

  private constructor(
    fbUser: firebase.User,
    db: firebase.firestore.Firestore,
    uid: string,
    role: IRole,
    profile
  ) {
    this._fbUser = fbUser;
    this._db = db;

    this._profileSubject = new BehaviorSubject(profile);
    this._role = new BehaviorSubject(role);
    this._authorized = new BehaviorSubject(role.access === ERoleAccess.granted);

    this._docRef = db.collection("users").doc(uid);

    // Subscribe to the snapshot
    this._docRef.onSnapshot(
      (snapshot: firebase.firestore.DocumentSnapshot) => {
        const snapProfile = snapshot.get("profile");
        this._profileSubject.next(snapProfile);

        const roles: IRole[] = snapshot.get("roles");

        const foundRole = roles.find((oneRole: IRole) => {
          return oneRole.type === this._role.value.type;
        });

        if (foundRole) {
          if (
            this._role.value.type !== foundRole.type ||
            this._role.value.access !== foundRole.access
          ) {
            this._role.next(foundRole);
          }

          if (this._role.value.access !== foundRole.access) {
            this._authorized.next(foundRole.access === ERoleAccess.granted);
          }
        } else {
          // If role is removed on the server, deny
          this._authorized.next(false);
        }
      },
      (err) => {
        this._profileSubject.next(null);
        this._authorized.next(false);
      },
      () => {}
    );
  }

  private _fbUser: firebase.User;
  private _db: firebase.firestore.Firestore;
  private _docRef: firebase.firestore.DocumentReference;

  private _profileSubject: BehaviorSubject<any>;
  private _role: BehaviorSubject<IRole>;
  private _authorized: BehaviorSubject<boolean>;

  /**
   * Subscribe to get updates to the user profile.
   * @param observer
   */
  public WatchProfile(observer: PartialObserver<any>): Subscription {
    return this._profileSubject.subscribe(observer);
  }

  public get Profile(): IUserProfile | null {
    return this._profileSubject.value;
  }

  /**
   * Subscribe to get updates to the user roles
   * @param observer
   */
  public WatchRole(observer: PartialObserver<any>): Subscription {
    return this._role.subscribe(observer);
  }

  /**
   * Updates the user profile.
   * The passed data is first validated, then sent to the server. It will result in an update to the user profile,
   * so all subscriptions should automatically update.
   * @param profile The user profile structure that you wish to update
   * @throws ValidationError
   */
  public async UpdateProfile(profile: IUserProfile): Promise<void> {
    let validatedProfile: IUserProfile = null;
    // Validate input
    try {
      validatedProfile = await UserProfileSchema.validate(profile, {
        strict: false,
        abortEarly: false,
        stripUnknown: true,
      });
    } catch (err) {
      return Promise.reject(err);
    }

    await this._docRef.update({
      profile: validatedProfile,
    });
  }

  public async UpdateEmail(email: string): Promise<void> {
    await this._fbUser.updateEmail(email);
  }

  public async UpdateFCMToken(token: string): Promise<void> {
    try {
      console.log("User doc: " + this._docRef.id);
      console.log("Updating fcm token to: " + token);
      await this._docRef.update({
        fcm: token,
      });
    } catch (err) {
      console.warn(err.message);
    }
  }

  public async UpdateDisplayLanguage(langCode: string): Promise<void> {
    try {
      await this._docRef.update({
        displayLang: langCode,
      });
    } catch (err) {
      console.warn(err.message);
    }
  }

  public async GetIDToken(): Promise<string> {
    return await this._fbUser.getIdToken(true);
  }

  /// ---------------------------- FACTORY METHODS ---------------------------- ///

  /**
   * Factory method for creating a user. This method will try find the user, create the profile if necessary and verify the user roles,
   * before creating an instance of the user.
   * @param fbUser The firebase user object
   * @param db The firestore database object
   * @param role The role we are logging in for
   * @param profile An optional profile, to use for creating a new profile
   */
  // tslint:disable-next-line: member-ordering
  public static async Init(
    fbUser: firebase.User,
    db: firebase.firestore.Firestore,
    role: ERoleType,
    iProfile?: IUserProfile | null
  ) {
    // Set up document reference
    const profileDocRef = db.collection("users").doc(fbUser.uid);

    let unsubscribe: any = null;
    let profile: IUserProfile | null = null;
    let success = false;

    // Start with unknown role
    const roleStruct: IRole = {
      access: ERoleAccess.denied,
      type: role,
    };

    await new Promise<boolean>((resolve, reject) => {
      // Subscribe to the snapshot listener
      unsubscribe = profileDocRef.onSnapshot(
        (snapshot: firebase.firestore.DocumentSnapshot) => {
          // Case 1: No profile exists for this user, create one
          if (!snapshot.exists) {
            if (iProfile) {
              // There is a profile, set it up as default one
              // This is async, but will not stop and wait for it, as we are expecting the snapshot to update
              this._setupProfile(profileDocRef, iProfile).catch((err) => {
                // If there was a problem, snapshot won't update so should reject
                console.log("Error setting up profile");
                reject(err);
              });
            } else {
              // No profile, are we signing in instead of signing-up ?
              reject("No profile was found and none was provided!");
            }
          } else {
            // Snapshot received, get profile
            profile = snapshot.get("profile");

            // Check the user role for this platform
            this._checkRoles(role, snapshot)
              .then((result: EAsyncResult) => {
                if (result === EAsyncResult.Negative) {
                  // Negative response, do not have permission to continue with this user
                  roleStruct.access = ERoleAccess.denied;
                  resolve(false);
                } else if (result === EAsyncResult.Positive) {
                  // Have permission !
                  roleStruct.access = ERoleAccess.granted;
                  success = true;
                  resolve(true);
                }
              })
              .catch((err) => {
                console.log(err);
                reject("Error creating or validating roles!");
              });
          }
        },
        () => {
          // .log('Error reading user snapshot.. TODO: should this fail the init process?');
          // NOT SURE IF I SHOULD PUT A REJECT HERE?
        }
      );

      // Start listening
      profileDocRef.get({ source: "server" }).catch((err: any) => {
        console.log(err);
      });
    }).catch((err) => {
      console.log(err);
    });

    // In all cases, unsubscribe
    if (unsubscribe) {
      unsubscribe();
    }

    if (success) {
      return new User(fbUser, db, fbUser.uid, roleStruct, profile);
    } else {
      return null;
    }
  }

  // tslint:disable-next-line: member-ordering
  private static async _setupProfile(
    profileDocRef: firebase.firestore.DocumentReference,
    profile: IUserProfile
  ) {
    await profileDocRef.set({
      profile: profile,
    });
    return true;
  }

  private static async _createRole(
    requestedRole: ERoleType,
    snapshot: firebase.firestore.DocumentSnapshot
  ) {
    const role: IRole = {
      type: requestedRole,
      access: ERoleAccess.granted,
    };

    // NOTE: MAY THROW
    await snapshot.ref.update({
      roles: [role],
    });
  }

  private static async _checkRoles(
    requestedRole: ERoleType,
    snapshot: firebase.firestore.DocumentSnapshot
  ): Promise<EAsyncResult> {
    const roles: IRole[] = snapshot.get("roles");

    if (!roles || roles.length === 0) {
      // No roles yet, add this person as a translator
      // May throw
      await this._createRole(requestedRole, snapshot);
      // The above will result in a recall of the callback, so return here
      return EAsyncResult.DoNothing;
    }

    // Have roles, go through
    const foundRole: IRole | undefined = roles.find((role: IRole) => {
      return role.type === requestedRole;
    });

    if (!foundRole) {
      // No roles yet, add this person as a translator
      // May throw
      //await this._createRole(requestedRole, snapshot);
      // The above will result in a recall of the callback, so return here
      return EAsyncResult.Negative;
    } else {
      // Have the role, is it granted or denied ?
      if (foundRole.access !== ERoleAccess.granted) {
        // Refuse, was deliberately denied by adming
        return EAsyncResult.Negative;
      } else {
        return EAsyncResult.Positive;
      }
    }
  }
}
