import * as yup from 'yup';
import { DeepCopyDocument, IDocument } from './IDocument';
import { ITraducteur } from './ITraducteur';


/**
 * State of the prestation
 */
export enum EnumPrestationState {
  Defining = 'defining',
  WaitingForTranslator = 'waiting-for-translator',
  CancelledByClient = 'cancelled-by-client',
  WaitingForPayment = 'waiting-for-payment',
  RefusedByTranslator = 'refused-by-translator',
  Translating = 'translating',
  WaitingForValidationFromClient = 'waiting-for-client-to-validate',
  Validated = 'validated',
  ValidatedPro = 'validated-pro',
}


export interface IPrice {
  // Price of the prestation based on transltor prices, HT
  traducteurHT: number;
  // The % margin added by hiero at the time of the order
  hieroMarginPercent: number;
  // The margin in € at the time of the order
  hieroMarginValue: number;
  // The subtotal HT
  subtotalHT: number;
  // Tax % of subtotal
  tva: number;
  // Tax value in €
  tvaValue: number;
  // Total TTC
  ttc: number;
}


/**
 * Une prestation pour un client à effectuer par un traducteur/
 */
export interface IPrestation {

  // User id who is creating this prestation
  uid: string;

  // State of the prestation
  state: EnumPrestationState;

  srcLanguageIso639: string;
  destLanguageIso639: string;
  srcCountryCode: string;

  // The unique folder id for storage on the device
  deviceStorageId: string;

  // Documents included in this prestation
  documents: IDocument[];

  // ID of the translator profile, once chosen
  traducteurId: string | null;

  // Traducteur details for display purposes
  traducteur: ITraducteur | null;

  // Price of the prestation
  price: IPrice;

  //// HISTORY ////

  // The date when this was sent to a translator (creation)
  // ALL DATES ARE IN MILLISECONDS SINCE JAN 1 1970
  sentToTranslatorAt: number;

  // Cancelled at
  cancelledByClientAt: number;

  // Validated at
  acceptedByTranslatorAt: number;
  refusedByTranslatorAt: number;

  // Paid at
  paidAt: number;

  // Due at (= due at + X hours)
  dueAt: number;

  // Completed by translator at
  completedAt: number;

  // Validated by client at
  validatedByClientAt: number;

  // Last modified at
  lastModifiedAt: number;

  // Created at
  createdAt: number;

  // Is a professional prestation?
  isProfessionalPrestation?: boolean

  // Number of page
  pageNumber?: number;
}



export const EmptyPrestation: IPrestation = {
  uid: '',
  state: EnumPrestationState.Defining,
  srcLanguageIso639: '',
  destLanguageIso639: '',
  srcCountryCode: '',
  documents: [],
  traducteurId: null,
  traducteur: null,
  price: {
    traducteurHT: 0,
    hieroMarginPercent: 0,
    hieroMarginValue: 0,
    subtotalHT: 0,
    tva: 0,
    tvaValue: 0,
    ttc: 0
  },

  deviceStorageId: '',

  sentToTranslatorAt: 0,
  cancelledByClientAt: 0,
  acceptedByTranslatorAt: 0,
  refusedByTranslatorAt: 0,
  dueAt: 0,
  paidAt: 0,
  completedAt: 0,
  validatedByClientAt: 0,
  lastModifiedAt: 0,
  createdAt: 0,
  isProfessionalPrestation: false
};

export const DeepCopyPrestation = (src: IPrestation) => {
  const docs: IDocument[] = src.documents.map(
    (doc: IDocument) => {
      return DeepCopyDocument(doc);
    }
  );

  const price: IPrice = Object.assign({}, src.price);

  let traducteur: any = null; // add type any by brin
  if (!!src.traducteur) {
    traducteur = Object.assign({}, src.traducteur);
  }

  const newDoc = Object.assign(
    {},
    src,
    {
      documents: docs,
      price: price,
      traducteur: traducteur
    }
  );

  return newDoc;
};

export const CreatePrestationSchema = yup.object({
  uid: yup.string().required(),
  srcLanguageIso639: yup.string().required(),
  destLanguageIso639: yup.string().required(),
  srcCountryCode: yup.string().required(),
  deviceStorageId: yup.string().required(),
  // Documents included in this prestation
  documents: yup.array()
    .of(
      yup.object({
        documentTypeId: yup.string().required(),
        deviceStorageId: yup.string().required(),
        original: yup.array()
          .of(
            yup.object({
              deviceStorageId: yup.string().required(),
              downloadURL: yup.string().required(),
              name: yup.string().required()
            })
          )
          .min(1)
      })
    )
    .min(1),

  // ID of the translator profile, once chosen
  traducteurId: yup.string().required(),

  // Price of the prestation
  price: yup.object({
    priceHT: yup.number().moreThan(0),
    subtotalHT: yup.number().moreThan(0),
    ttc: yup.number().moreThan(0)
  }),
});
