<script src="../../../../../../../../../../Downloads/prof.js"></script>
<div class="container-fluid">
  <div class="d-flex">
    <h3>{{ "consulter_facture" | localise }}</h3>
    <!--  <button class="ml-3 rounded-pill" style="background-color: #702963; color: white">Modifier mon addresse de facturation</button> -->
  </div>
  <table class="table table-striped" id="content" #content>
    <thead>
      <tr>
        <th scope="col" class="align-middle">
          {{ "section_date" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "section_numero_facture" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ "section_numero_commande" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ " section_type_prestation" | localise }}
        </th>
        <th scope="col" class="align-middle">
          {{ " section_price" | localise }}
        </th>
        <!--<th scope="col">Handle</th> -->
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let facture of factureList2
            | slice
              : (pageAsser - 1) * pageSize
              : (pageAsser - 1) * pageSize + pageSize;
          let indexOfelement = index
        "
      >
        <td class="align-middle">
          <div class="badge rounded-pill my-color p-2">
            {{ facture.data.date.toDate() | date: "dd/MM/yyyy" }}
          </div>
        </td>
        <td class="align-middle">{{ facture.data.numero }}</td>
        <td class="align-middle">{{ facture.data.prestation }}</td>
        <td class="align-middle">{{ facture.data.typePrestation }}</td>
        <td class="align-middle" *ngIf="isTVA">
          {{ facture.data.priceTTC }} &euro;
        </td>
        <td class="align-middle" *ngIf="!isTVA">
          {{ facture.data.priceHT }} &euro;
        </td>
        <td class="align-middle justify-content-around">
          <!-- <button
            class="btn mr-1 my-color"
            (click)="download(indexOfelement, 2)"
          >
            <i class="fa fa-download"></i>
            {{ "section_generate_facture" | localise }}
          </button>
          <button
            class="btn mr-1"
            style="background-color: black; color: white"
            (click)="download(indexOfelement, 1)"
          >
            <i class="fa fa-print"></i> {{ "section_print" | localise }}
          </button> -->
          <button
            class="btn"
            style="background-color: white; color: black"
            [routerLink]="[facture.id]"
          >
            <i class="fa fa-list-alt"></i> {{ "section_consulter" | localise }}
          </button>
          <!--(click)="savePDF()"-->
        </td>
      </tr>
    </tbody>
  </table>
  <ngb-pagination
    *ngIf="!!factureList"
    [collectionSize]="factureList.length"
    [page]="pageAsser"
    [pageSize]="pageSize"
    (pageChange)="onPageChange($event, 0)"
    aria-label="Default pagination"
  ></ngb-pagination>
</div>
