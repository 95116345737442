import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//const { connect } = require('twilio-video');
import { connect } from 'twilio-video'
//import { Interpretation } from '../../../../../../common/src/bdd/interpretations/Interpretation';
import { Traducteur } from '../../../../../../common/src/bdd/traducteur/Traducteur';
import { User } from '../../../../../../common/src/bdd/user/User';
import { HieroBDD } from '../../../../services/hierobdd.service';

// import { DOCUMENT } from '@angular/common';
//import { ZoomMtg } from '@zoomus/websdk';

const video_Url="https://video-app-7531-1291-dev.twil.io?passcode=16318775311291" 


@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss']
})
export class RoomsComponent implements OnInit {
  
  constructor(public httpClient: HttpClient, private hiero: HieroBDD) { }

  ngOnInit() {

  }
 
  getRoom() {
    window.open(video_Url, '_blank')
  }
 
}
