
export enum EnumTraducteurServiceExceptionType {
  DO_NOT_HANDLE = 'do_not_handle',
  DIFFERENT_PRICE = 'different_price'
}

export interface ITraducteurServiceException {
  templateId: string;
  type: EnumTraducteurServiceExceptionType;
  priceHT: number;
}


/**
 * This is a service offered by a translator, containing a source and destination language, and the types of documents that are handled.
 */
export interface ITraducteurService {
  /**
   * The ID of the translator who offers this service
   */
  traducteurId: string;

  /**
   * Source language ISO639
   */
  srcLanguageIso639: string;

  /**
   * Dest language ISO639
   */
  destLanguageIso639: string;

  /**
   * The list of exceptions. If no exceptions are specified, the service includes all countries, at the price
   * indicated by Hiero.
   */
  exceptions: ITraducteurServiceException[];

}
