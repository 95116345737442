import * as yup from "yup";
import { ICalendDateFormat } from "../../../../hiero/app/routes/app/interpretations/calendar/event-modal/event-modal.component";

export interface IGeoCoords {
  latitude: number;
  longitude: number;
}

export interface IAddress {
  formatted: string;
  number: string;
  street: string;
  city: string;
  country: string;
  code: string;
  extra: string;
}

export interface ITraducteur {
  uid: string;
  businessName: string;
  address: IAddress;
  coords: IGeoCoords;
  telephone: string;
  email: string;
  registration: string; // SIRET, SIREN, ...
  assermentation: boolean;
  assujettiTVA: boolean;
  tvaNumber: string;
  defaultException?: string;
  interpreter?: boolean;
  interpretationPrice?: string;
}

export interface Iinterpreter {
  traducteurId: string;
  traducteur: ITraducteur;
  isInterpreter: boolean;
  availability: ICalendDateFormat[];
}

export const TraducteurAddressSchema = yup.object({
  formatted: yup.string().default(""),
  number: yup.string().default(""),
  street: yup.string().default(""),
  city: yup.string().default(""),
  country: yup.string().default(""),
  code: yup.string().default(""),
  extra: yup.string().default(""),
});

export const TraducteurCoordsSchema = yup.object({
  latitude: yup.number().default(0),
  longitude: yup.number().default(0),
});

export const TraducteurProfileSchema = yup.object({
  uid: yup.string(),
  businessName: yup.string(),
  address: TraducteurAddressSchema,
  coords: yup.object({
    latitude: yup.number().default(0),
    longitude: yup.number().default(0),
  }),
  telephone: yup.string(),
  email: yup.string().email(),
  registration: yup.string().default(""),
  assermentation: yup.boolean().default(false),
  assujettiTVA: yup.boolean().default(false),
  tvaNumber: yup.string().default(""),
});

export const InterpreterSchema = yup.object({
  traducteurId: yup.string().default(""),
  traducteur: TraducteurProfileSchema,
  isInterpreter: yup.boolean().default(false),
  availability: yup.array<ICalendDateFormat>().default([]),
});

export const EmptyTraducteurProfile = TraducteurProfileSchema.cast({});

export const EmptyTraducteurAddress = TraducteurAddressSchema.cast({});
export const EmptyTraducteurCoords = TraducteurCoordsSchema.cast({});
export const EmptyInterpreter = InterpreterSchema.cast({});
