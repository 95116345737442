import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { ELoggedInStatus } from "../../../common/src/bdd/user/AuthService";
import { HieroBDD } from "../services/hierobdd.service";

@Injectable()
export class LoggedInGuard implements CanActivate {
  private _subs: Subscription;

  constructor(private hiero: HieroBDD, private router: Router) {}

  unsubscribe(result: ELoggedInStatus) {
    if (this._subs) {
      this._subs.unsubscribe();
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.hiero.Auth.LoggedInStatus === ELoggedInStatus.LoggedIn) {
      return Promise.resolve(true);
    } else if (this.hiero.Auth.LoggedInStatus === ELoggedInStatus.NotLoggedIn) {
      this.router.navigate(["/compte/connexion"]);
      return Promise.resolve(false);
    } else {
      return new Promise((resolve, reject) => {
        this._subs = this.hiero.Auth.WatchLoggedInStatus({
          next: (res) => {
            this.unsubscribe(res);

            switch (res) {
              case ELoggedInStatus.LoggedIn:
                return resolve(true);
              case ELoggedInStatus.NotLoggedIn:
                this.router.navigate(["/compte/connexion"]);
                return resolve(false);
            }
          },
        });
      });
    }
  }
}
