import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs/Subscription";
import {
  EmptyUserProfile,
  IUserProfile,
} from "../../../../../common/src/bdd/interfaces/IUserProfile";
import { User } from "../../../../../common/src/bdd/user/User";
import { HieroBDD } from "../../../services/hierobdd.service";
import {
  ISubmitFormInputErrors,
  SubmitForm,
} from "../../../../../common/src/utility/forms/submitform.class";
import { LocalisationService } from "../../../../../common/src/modules/localisation/localisation.service";
import * as firebase from "firebase";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { GoogleGeo, GeoCodeResult } from "../../../services/google.services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoginModalComponent } from "./login-modal/login-modal.component";
import { Router } from "@angular/router";
import { ITraducteur } from "../../../../../common/src/bdd/interfaces/ITraducteur";
import { Traducteur } from "../../../../../common/src/bdd/traducteur/Traducteur";
import { ProfilePictureService } from "../../../services/profile-picture.services";

@Component({
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  private userWatch: User;
  public profile: IUserProfile;
  public profileForm: FormGroup;
  public passwordForm: FormGroup;
  public parrainageForm: FormGroup;
  public user: firebase.User;
  public tradData: any;
  public photo: any;
  public updated: boolean = false;
  public updatedPwd: boolean = false;
  public isSent: boolean = false;
  public errorImg: boolean = false;
  public error: boolean = false;
  public errorLogin: boolean = false;
  public errorPwd: boolean = false;
  public errorSent: boolean = false;

  private userSubs: Subscription;
  private profSubs: Subscription;
  private tradSub: Subscription;
  public hasSubscription: any;

  constructor(
    private hiero: HieroBDD,
    private fb: FormBuilder,
    private geo: GoogleGeo,
    private modalService: NgbModal,
    private router: Router,
    private pfpService: ProfilePictureService
  ) {
    /* this.createForm(this.profile || EmptyUserProfile); */
  }

  ngOnInit() {
    // Add listeners for the data we want
    // These listeners will either fire immediately,
    // or they will fire when the data becomes available
    //this.getUserData();

    this.getCustomerSubscription().then(
      (value) => (this.hasSubscription = value)
    );

    this.createFormPwd();
    this.createFormPar();

    this.userSubs = this.hiero.Auth.WatchUser({
      next: (user: User) => {
        if (user) {
          // We have a user, save a reference
          this.userWatch = user;

          if (this.userWatch) {
            // The user is not null, watch for the user profile
            this.profSubs = this.userWatch.WatchProfile({
              next: (profile: IUserProfile) => {
                if (profile) {
                  this.profile = profile;
                  this.createForm(profile);
                  this.getUserData();
                }
              },
            });
          }
        }
      },
    });
  }

  ngOnDestroy() {
    this.profSubs.unsubscribe();
    this.userSubs.unsubscribe();
    this.tradSub.unsubscribe();
  }

  createForm(profile: IUserProfile) {
    this.profileForm = this.fb.group({
      social: ["", Validators.required],
      siret: [""],
      tva: ["", Validators.required],
      tvaNumber: [""],
      familyName: [profile.familyName, Validators.required],
      givenName: [profile.givenName, Validators.required],
      address: ["", Validators.required],
      address2: [""],
      email: [this.userWatch.Email, [Validators.required, Validators.email]],
      tel: [profile.telephone, Validators.required],
      asser: ["", Validators.required],
      interprete: ["", Validators.required],
    });
  }

  private createFormPwd() {
    this.passwordForm = this.fb.group(
      {
        password: ["", Validators.required],
        newPassword: ["", [Validators.required, Validators.minLength(8)]],
        newPassword2: ["", Validators.required],
      },
      {
        validator: this.mustMatch("newPassword", "newPassword2"),
      }
    );
  }

  private createFormPar() {
    this.parrainageForm = this.fb.group({
      type: ["", Validators.required],
      mail: ["", [Validators.required, Validators.email]],
    });
  }

  async onFileSelected(event) {
    this.errorImg = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      // store the image in the firebase storage at images/profile
      let storage: firebase.storage.Storage = this.hiero.Storage;
      const fileRef = storage.ref("images/profile/").child(this.user.uid);
      const result = await fileRef.put(file);

      // get the image stored and update the profile picture
      await storage
        .ref(result.ref.fullPath)
        .getDownloadURL()
        .then((photo) => {
          this.user
            .updateProfile({
              photoURL: photo,
            })
            .then(() => {
              this.photo = this.user.photoURL;
              this.pfpService.pfp$.next(this.photo);
              //window.location.reload();
            })
            .catch((error) => (this.errorImg = true));
        });
    }
  }

  private getUserData() {
    this.user = firebase.auth().currentUser;
    this.photo = this.user.photoURL;

    this.tradSub = this.hiero.WatchTraducteurProfile({
      next: (profileTrad: ITraducteur) => {
        if (profileTrad) {
          this.tradData = profileTrad;
          this.geo
            .geocode(this.tradData.address.formatted)
            .then((x) => this.profileForm.get("address").setValue(x[0]));
          this.profileForm
            .get("address2")
            .setValue(this.tradData.address.extra);
          this.profileForm.get("social").setValue(this.tradData.businessName);
          if (this.tradData.registration)
            this.profileForm.get("siret").setValue(this.tradData.registration);
          if (this.tradData.assujettiTVA)
            this.profileForm.get("tva").setValue("true");
          else this.profileForm.get("tva").setValue("false");
          if (this.tradData.tvaNumber)
            this.profileForm.get("tvaNumber").setValue(this.tradData.tvaNumber);
          if (
            this.tradData.assermentation === "true" ||
            this.tradData.assermentation
          )
            this.profileForm.get("asser").setValue("true");
          else this.profileForm.get("asser").setValue("false");
          if (this.tradData.interpreter === "true" || this.tradData.interpreter)
            this.profileForm.get("interprete").setValue("true");
          else this.profileForm.get("interprete").setValue("false");
        }
      },
    });
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((term) => (term.length < 2 ? [] : this.geo.geocode(term)))
    );

  formatter = (loc: GeoCodeResult) =>
    loc && loc.address ? loc.address.formatted : "";

  async getCustomerSubscription() {
    let db = firebase.firestore();

    const request = db
      .collection("customers")
      .doc(firebase.auth().currentUser.uid)
      .collection("subscriptions")
      .where("status", "in", ["trialing", "active"]);

    return request.get().then(async (snapshot) => {
      // In this implementation we only expect one active or trialing subscription to exist.
      const doc = snapshot.docs[0];
      if (doc) {
        return doc.data().items[0].price;
      } else {
        return false;
      }
    });
  }

  public submit() {
    this.updated = false;
    this.errorLogin = false;
    this.error = false;
    let formValue = this.profileForm.value;

    formValue.address.address.extra = formValue.address2;

    if (formValue.email != this.user.email) {
      const modal = this.modalService.open(LoginModalComponent);
      modal.componentInstance.type = "modif";
      modal.result.then((x) => {
        if (x === true) {
          this.user
            .updateEmail(formValue.email)
            .then(() => {
              const trad: ITraducteur = this.updateData(formValue);
              this.hiero.Traducteur.UpdateProfile(trad);
              //this.updateProfileMail(formValue);
              this.user.sendEmailVerification();
              this.router.navigate(["compte", "emailVerify", "2"]);
            })
            .catch((error) => {
              // TO DO : what to do if update email failed?
              console.log("update email failed");
            });
        }
        // TO DO : what to do if login failed?
        if (x === false) {
          this.errorLogin = true;
        }
      });
    }
    const trad: ITraducteur = this.updateData(formValue);
    this.hiero.Traducteur.UpdateProfile(trad);
    this.updateProfileInfo(formValue);
  }

  updateData(formValue): ITraducteur {
    if (!Boolean(JSON.parse(formValue.tva))) formValue.tvaNumber = "";

    const traducteur: ITraducteur = {
      uid: this.user.uid,
      businessName: formValue.social,
      email: formValue.email,
      telephone: formValue.tel,
      address: formValue.address.address,
      coords: formValue.address.coords,
      registration: formValue.siret,
      assermentation: Boolean(JSON.parse(formValue.asser)),
      interpreter: Boolean(JSON.parse(formValue.interprete)),
      assujettiTVA: Boolean(JSON.parse(formValue.tva)),
      tvaNumber: formValue.tvaNumber,
    };

    traducteur.address.extra = formValue.address.address.extra;

    return traducteur;
  }

  private updateProfileInfo(formValue) {
    let currentUser = this.hiero.Auth.User;
    currentUser
      .UpdateProfile({
        familyName: formValue.familyName,
        givenName: formValue.givenName,
        telephone: formValue.tel,
      })
      .then(() => {
        this.updated = true;
      })
      .catch((error) => {
        this.error = true;
      });
  }
  /* private updateProfileMail(formValue) {
    let currentUser = this.hiero.Auth.User;
    currentUser.UpdateEmail(formValue.email);
  } */

  public deleteAccount() {
    const modal = this.modalService.open(LoginModalComponent);
    modal.componentInstance.type = "delete";
    modal.result.then((x) => {
      if (x === true) {
        this.user
          .delete()
          .then(() => {
            this.router.navigate(["compte", "connexion"]);
          })
          .catch((error) => {
            console.log("delete error");
          });
      } else {
        console.log("delete error");
      }
    });
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  public submitPwd() {
    this.errorPwd = false;
    this.updatedPwd = false;
    const credentials = firebase.auth.EmailAuthProvider.credential(
      this.user.email,
      this.passwordForm.value.password
    );
    this.user
      .reauthenticateWithCredential(credentials)
      .then(() => {
        this.user
          .updatePassword(this.passwordForm.value.newPassword)
          .then(() => {
            this.updatedPwd = true;
          })
          .catch((error) => {
            this.errorPwd = true;
          });
      })
      .catch((error) => (this.errorPwd = true));
  }
  public submitPar() {
    let formPar = this.parrainageForm.value;
    this.errorSent = false;
    this.isSent = false;
    if ((formPar.type = "part")) {
      console.log(formPar.type);
    } else {
      console.log(formPar.type);
    }
  }
}
