import { Component } from '@angular/core';

@Component({
  templateUrl: './verifybase.component.html'
})

export class VerifyBaseComponent {
constructor(
  ) {
  }
}
