import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubmitForm, ISubmitFormInputErrors } from '../../../../../common/src/utility/forms/submitform.class';
import { FormBuilder, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { IAddress, IGeoCoords } from '../../../../../common/src/bdd/interfaces/ITraducteur';
import { Localisation } from '../../../../../common/src/bdd/settings/Localisation';
import { LocalisationService } from '../../../../../common/src/modules/localisation/localisation.service';

export function notZeroValidator(id: string): ValidationErrors|null {
  return (control: AbstractControl): {[key: string]: any} => {
    let noddress = false;
    if (control.parent) {
      const par = control.parent;
      const lat = par.get(id).value;

      let latNum = 0;
      try {
        latNum = parseFloat(lat);
      } catch {}

      noddress = (
        (!latNum)
      );
    }

    return (noddress) ? {'notZero': true} : null;
  };
}

@Component({
  selector: 'app-modal-manual-address',
  templateUrl: './modal-manual-address.component.html'
})
export class AppModalManualAddressComponent implements OnInit {
  @Input() address: IAddress;
  @Input() coords: IGeoCoords;

  addressform: SubmitForm;
  formattedAddress = '';

  constructor(public modal: NgbActiveModal, private fb: FormBuilder, private localisation: LocalisationService) {

    const address: IAddress = {
      city: '',
      code: '',
      country: '',
      number: '',
      street: '',
      formatted: '',
      extra: '',
    };

    const coords: IGeoCoords = {
      latitude: 0,
      longitude: 0
    };

    this.createForm(address, coords);
  }

  ngOnInit() {
    this.createForm(this.address, this.coords);
    this.formattedAddress = this.address.formatted;
  }

  createForm(address: IAddress, coords: IGeoCoords) {

    this.addressform = new SubmitForm(
      this.fb,
      [
        {
          name: 'number',
          value: address.number,
          validators: [Validators.required],
          type: 'text',
          title: this.localisation.localise('agency_address_number'),
          autocomplete: '',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
          ]
        },
        {
          name: 'street',
          value: address.street,
          validators: [Validators.required],
          type: 'text',
          title: this.localisation.localise('agency_address_street'),
          autocomplete: '',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
          ]
        },
        {
          name: 'code',
          value: address.code,
          validators: [Validators.required],
          type: 'text',
          title: this.localisation.localise('agency_address_code'),
          autocomplete: '',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
          ]
        },
        {
          name: 'city',
          value: address.city,
          validators: [Validators.required],
          type: 'text',
          title: this.localisation.localise('agency_address_city'),
          autocomplete: '',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
          ]
        },
        {
          name: 'country',
          value: address.country,
          validators: [Validators.required],
          type: 'text',
          title: this.localisation.localise('agency_address_country'),
          autocomplete: '',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
          ]
        },

        {
          name: 'latitude',
          value: coords.latitude,
          validators: [notZeroValidator('latitude')],
          type: 'number',
          title: this.localisation.localise('agency_address_coords_lat'),
          autocomplete: '',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
            <ISubmitFormInputErrors>{
              code: 'notZero',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
          ]
        },
        {
          name: 'longitude',
          value: coords.longitude,  
          validators: [notZeroValidator('longitude')],
          type: 'number',
          title: this.localisation.localise('agency_address_coords_long'),
          autocomplete: '',
          placeholder: '',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
            <ISubmitFormInputErrors>{
              code: 'notZero',
              message: this.localisation.localise('agency_setup_error_required_field'),
            },
          ]
        }
      ],
      // Submit callback
      (changes) => {

      },

      // Success callback
      () => {
      },

      // Fail callback
      err => {
        // What to do with login failuer
        console.error(err);
      },

      // Changes callback
      changes => {
        const addr = this.getAddress();
        this.formattedAddress = addr.formatted;
      }
    );
  }

  formatted(address: IAddress): string {
    return address.number + ', ' + address.street + ', ' + address.code + ' ' + address.city + ', ' + address.country;
  }

  getAddress(): IAddress {
    const address: IAddress = {
      city: this.addressform.GetValue('city'),
      code: this.addressform.GetValue('code'),
      country: this.addressform.GetValue('country'),
      number: this.addressform.GetValue('number'),
      street: this.addressform.GetValue('street'),
      extra: this.address.extra,
      formatted: ''
    };

    address.formatted = this.formatted(address);
    return address;

  }

  close() {
    const address = this.getAddress();
    let coords: IGeoCoords = {
      latitude: 0,
      longitude: 0,
    };
    try {
      coords = {
        latitude: parseFloat(this.addressform.GetValue('latitude')),
        longitude: parseFloat(this.addressform.GetValue('longitude')),
      };
    } catch {
      return;
    }

    this.modal.close({ address: address, coords: coords });
  }
}
