<div class="row ml-4 d-flex justify-content-between container-fluid">
  <div class="col-md-3 card text-center ml-n5 p-0 pt-3">
    <div class="mt-3">
      <input
        type="file"
        class="file-input"
        (change)="onFileSelected($event)"
        #fileUpload
        accept="image/x-png,image/gif,image/jpeg"
      />
      <img
        *ngIf="photo == null"
        src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
        alt="imageProfile"
        class="img-fluid border"
        (click)="fileUpload.click()"
      />
      <img
        class="img-fluid border"
        *ngIf="photo"
        [src]="photo"
        alt="imageProfile"
        (click)="fileUpload.click()"
      />
    </div>
    <div>
      <p *ngIf="errorImg" class="text-danger mt-2">
        Nous n'avons pas pu mettre à jour votre photo de profil.
      </p>
    </div>
    <div class="mt-5">
      <h3 class="p-color">
        {{ profile.givenName }} {{ profile.familyName | uppercase }}
      </h3>
      <p *ngIf="tradData" class="text-center font-italic text-muted">
        Traducteur
        <span
          *ngIf="tradData.interpreter === 'true' || tradData.interpreter"
          class="ml-n1"
          >/Interprete</span
        >
        <span
          *ngIf="
            tradData.assermentation === 'true' || tradData.assermentation;
            else classic
          "
        >
          Assermenté</span
        >
        <ng-template #classic> Classique</ng-template>
      </p>
    </div>
    <div class="sub">
      <hr />
      <div>
        <p class="text-center">
          {{ "inscription_date" | localise }}
          {{ user.metadata.creationTime | date: "dd/MM/yyyy" }}
        </p>
      </div>

      <hr />
      <p class="p-color d-flex flex-row justify-content-between pl-3 pr-4">
        Abonnement actuel
        <span *ngIf="hasSubscription; else noSub"
          ><button class="btn btn-sub ml-2">
            {{ hasSubscription.product.name }} :
            {{ (hasSubscription.unit_amount / 100).toFixed(2) }} €/mois
          </button></span
        >
        <ng-template #noSub> Aucun abonnement pour le moment</ng-template>
      </p>
    </div>
  </div>
  <div class="col-md-9 card">
    <div class="mt-3">
      <div class="d-flex justify-content-between">
        <div class="col-md-8">
          <h5 class="mb-3">{{ "my_info" | localise }}</h5>
          <form [formGroup]="profileForm" (ngSubmit)="submit()">
            <div class="d-flex m-0">
              <div class="form-group mr-2 mb-0">
                <label class="sr-only" for="social">{{
                  "agency_setup_companyname" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "agency_setup_companyname" | localise }}
                    </div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="social"
                    formControlName="social"
                    [placeholder]="
                      'agency_setup_companyname_placeholder' | localise
                    "
                  />
                </div>
                <div
                  *ngIf="
                    profileForm.get('social').hasError('required') &&
                    profileForm.get('social').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
              </div>
              <div class="form-group mb-0">
                <label class="sr-only" for="siret">{{
                  "agency_setup_siret" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "agency_setup_siret" | localise }}
                    </div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="siret"
                    formControlName="siret"
                    [placeholder]="'agency_setup_siret_placeholder' | localise"
                  />
                </div>
              </div>
            </div>
            <div class="mb-2">
              <small class="form-text text-muted">
                {{ "agency_companyname_help" | localise }}
              </small>
            </div>
            <div class="d-flex mb-0">
              <div class="form-group mr-2 mb-0">
                <label class="sr-only" for="tva">{{
                  "agency_setup_tva" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "agency_setup_tva" | localise }}
                    </div>
                  </div>
                  <select id="tva" class="form-control" formControlName="tva">
                    <option value="false">
                      {{ "prestation_document_confirm_non" | localise }}
                    </option>
                    <option value="true">
                      {{ "prestation_document_confirm_oui" | localise }}
                    </option>
                  </select>
                </div>
                <div
                  *ngIf="
                    profileForm.get('tva').hasError('required') &&
                    profileForm.get('tva').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
              </div>
              <div class="form-group mb-0">
                <label class="sr-only" for="tvaNumber">{{
                  "agency_setup_tva_number" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "agency_setup_tva_number" | localise }}
                    </div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="tvaNumber"
                    formControlName="tvaNumber"
                    [placeholder]="
                      'agency_setup_tva_number_placeholder' | localise
                    "
                  />
                </div>
              </div>
            </div>
            <div class="mb-2">
              <small class="form-text text-muted">
                {{ "agency_setup_tva_number_help" | localise }}
              </small>
            </div>
            <div class="d-flex">
              <div class="form-group mr-2 mb-0">
                <label class="sr-only" for="familyName">{{
                  "account_surname" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "account_surname" | localise }}
                    </div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="familyName"
                    formControlName="familyName"
                    [placeholder]="'account_surname_placeholder' | localise"
                  />
                </div>
                <div
                  *ngIf="
                    profileForm.get('familyName').hasError('required') &&
                    profileForm.get('familyName').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
              </div>
              <div class="form-group mb-0">
                <label class="sr-only" for="givenName">{{
                  "account_name" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "account_name" | localise }}
                    </div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="givenName"
                    formControlName="givenName"
                    [placeholder]="'account_name_placeholder' | localise"
                  />
                </div>
                <div
                  *ngIf="
                    profileForm.get('givenName').hasError('required') &&
                    profileForm.get('givenName').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
              </div>
            </div>
            <div class="mb-2">
              <small class="form-text text-muted">
                {{ "agency_name_help" | localise }}
              </small>
            </div>
            <div class="form-group mb-1">
              <label class="sr-only" for="address">{{
                "account_adresse" | localise
              }}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ "account_adresse" | localise }}
                  </div>
                </div>
                <input
                  id="address"
                  type="text"
                  class="form-control"
                  [ngbTypeahead]="search"
                  [inputFormatter]="formatter"
                  [resultFormatter]="formatter"
                  [editable]="false"
                  formControlName="address"
                  [placeholder]="'account_adresse_placeholder' | localise"
                />
              </div>
              <small id="address" class="form-text text-muted">
                {{ "agency_setup_address_help" | localise }}
              </small>
              <div
                *ngIf="
                  profileForm.get('address').hasError('required') &&
                  profileForm.get('address').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_required" | localise }}
                </small>
              </div>
            </div>
            <div class="form-group mb-1">
              <label class="sr-only" for="address2">{{
                "account_complement_adr" | localise
              }}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ "account_complement_adr" | localise }}
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control"
                  id="address2"
                  formControlName="address2"
                  [placeholder]="
                    'account_complement_adr_placeholder' | localise
                  "
                />
              </div>
              <small class="form-text text-muted">
                {{ "agency_setup_addresscompl_help" | localise }}
              </small>
            </div>
            <div class="d-flex">
              <div class="form-group mr-2 mb-0">
                <label class="sr-only" for="email">{{
                  "account_email" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "account_email" | localise }}
                    </div>
                  </div>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    formControlName="email"
                    [placeholder]="'account_email_placeholder' | localise"
                  />
                </div>
                <div
                  *ngIf="
                    profileForm.get('email').hasError('required') &&
                    profileForm.get('email').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
                <div
                  *ngIf="
                    profileForm.get('email').hasError('email') &&
                    profileForm.get('email').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_invalid_email" | localise }}
                  </small>
                </div>
              </div>
              <div class="form-group mb-0">
                <label class="sr-only" for="tel">{{
                  "account_telephone" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "account_telephone" | localise }}
                    </div>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    id="tel"
                    formControlName="tel"
                    [placeholder]="'account_telephone_placeholder' | localise"
                  />
                </div>
                <div
                  *ngIf="
                    profileForm.get('tel').hasError('required') &&
                    profileForm.get('tel').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
              </div>
            </div>
            <div class="mb-2">
              <small class="form-text text-muted">
                {{ "agency_contact_help" | localise }}
              </small>
            </div>
            <div class="d-flex">
              <div class="form-group mr-2">
                <label class="sr-only" for="asser">{{
                  "account_asser" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "account_asser" | localise }}
                    </div>
                  </div>
                  <select
                    id="asser"
                    class="form-control"
                    formControlName="asser"
                  >
                    <option value="false">
                      {{ "prestation_document_confirm_non" | localise }}
                    </option>
                    <option value="true">
                      {{ "prestation_document_confirm_oui" | localise }}
                    </option>
                  </select>
                </div>
                <div
                  *ngIf="
                    profileForm.get('asser').hasError('required') &&
                    profileForm.get('asser').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
              </div>
              <div class="form-group">
                <label class="sr-only" for="interprete">{{
                  "question_inter" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "question_inter" | localise }}
                    </div>
                  </div>
                  <select
                    id="interprete"
                    class="form-control"
                    formControlName="interprete"
                  >
                    <option value="true">
                      {{ "prestation_document_confirm_oui" | localise }}
                    </option>
                    <option value="false">
                      {{ "prestation_document_confirm_non" | localise }}
                    </option>
                  </select>
                </div>
                <div
                  *ngIf="
                    profileForm.get('interprete').hasError('required') &&
                    profileForm.get('interprete').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <button class="btn my-btn" [disabled]="profileForm.invalid">
                {{ "account_save" | localise }}
              </button>
            </div>
            <div>
              <p *ngIf="updated" class="text-success mt-2">
                {{ "modif_profile_success" | localise }}
              </p>
              <p *ngIf="error" class="text-danger mt-2">
                {{ "modif_profile_failed" | localise }}
              </p>
              <p *ngIf="errorLogin" class="text-danger mt-2">
                La mise à jour de l'email n'a pas reussi à cause de 3 erreurs
                consécutives des identifiants saisis
              </p>
            </div>
          </form>
        </div>

        <div class="col-md-4">
          <h4 class="p-color">
            {{ "agency_expl_1_title" | localise }}
            <i class="fa fa-check fa-check"></i>
          </h4>
          <p class="mt-1 mb-3 form-text text-muted font-italic">
            {{ "agency_expl_1_expl" | localise }}
          </p>
          <h4 class="p-color">
            {{ "agency_expl_2_title" | localise }}
            <i class="fa fa-map-marker"> </i>
          </h4>
          <p class="mt-1 mb-3 form-text text-muted font-italic">
            {{ "geolocation_paragraphe" | localise }}
          </p>
          <h4 class="p-color">
            {{ "label_assermentation" | localise }}
            <i class="fa fa-id-card fa-fw"></i>
          </h4>
          <p class="mt-1 mb-3 form-text text-muted text-justify font-italic">
            {{ "agency_expl_4_expl" | localise }}
          </p>
          <p class="mt-1 mb-3 form-text text-muted text-justify font-italic">
            {{ "agency_expl_4_expl_1" | localise }}
            <span [innerHTML]="'support_email' | localise"></span>
          </p>
          <p class="mt-1 mb-3 form-text text-muted text-justify font-italic">
            {{ "agency_expl_4_expl_2" | localise }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row ml-4 d-flex justify-content-between container-fluid mt-3">
  <div class="col-md-6 card ml-n5">
    <div class="card-body p-0 pt-3">
      <h5>{{ "update_password" | localise }}</h5>
      <p class="m-1 form-text text-muted text-justify font-italic">
        {{ "account_upt" | localise }}
      </p>
      <form [formGroup]="passwordForm" (ngSubmit)="submitPwd()" class="mt-3">
        <div class="form-group">
          <label class="sr-only" for="password">{{
            "account_password" | localise
          }}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                {{ "account_password" | localise }}
              </div>
            </div>
            <input
              type="password"
              class="form-control"
              id="password"
              formControlName="password"
            />
          </div>
          <div
            *ngIf="
              passwordForm.get('password').hasError('required') &&
              passwordForm.get('password').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_required" | localise }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label class="sr-only" for="newPassword">{{
            "account_new_password" | localise
          }}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                {{ "account_new_password" | localise }}
              </div>
            </div>
            <input
              type="password"
              class="form-control"
              id="newPassword"
              formControlName="newPassword"
            />
          </div>
          <div
            *ngIf="
              passwordForm.get('newPassword').hasError('required') &&
              passwordForm.get('newPassword').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_required" | localise }}
            </small>
          </div>
          <div
            *ngIf="
              passwordForm.get('newPassword').hasError('minlength') &&
              passwordForm.get('newPassword').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_min_password" | localise }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label class="sr-only" for="newPassword2">{{
            "account_confirm" | localise
          }}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                {{ "account_confirm" | localise }}
              </div>
            </div>
            <input
              type="password"
              class="form-control"
              id="newPassword2"
              formControlName="newPassword2"
            />
          </div>
          <div
            *ngIf="
              passwordForm.get('newPassword2').hasError('required') &&
              passwordForm.get('newPassword2').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_required" | localise }}
            </small>
          </div>
          <div
            *ngIf="
              passwordForm.get('newPassword2').hasError('mustMatch') &&
              passwordForm.get('newPassword2').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_passwords_do_not_match" | localise }}
            </small>
          </div>
        </div>
        <div>
          <button
            class="btn my-btn mb-1 float-right"
            [disabled]="passwordForm.invalid"
          >
            {{ "account_save" | localise }}
          </button>
        </div>
        <div>
          <p *ngIf="updatedPwd" class="text-success mt-2">
            {{ "modif_profile_success_pwd" | localise }}
          </p>
          <p *ngIf="errorPwd" class="text-danger mt-2">
            {{ "modif_profile_failed_pwd" | localise }}
          </p>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-6 card special-card">
    <div class="card-body p-0 pt-3">
      <h5>{{ "parrainer_client" | localise }}</h5>
      <p class="m-1 form-text text-muted text-justify font-italic">
        {{ "parrainage_paragraph" | localise }}
      </p>
      <form [formGroup]="parrainageForm" (ngSubmit)="submitPar()" class="mt-3">
        <div class="form-group">
          <label class="sr-only" for="mail">{{
            "account_parrainage_email" | localise
          }}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                Veuillez renseigner le type du client
              </div>
            </div>
            <select
              id="type"
              class="form-control"
              formControlName="type"
              disabled
            >
              <option value="part">Particulier</option>
              <option value="pro">Professionnel</option>
            </select>
          </div>
          <div
            *ngIf="
              parrainageForm.get('type')?.hasError('required') &&
              parrainageForm.get('type')?.touched
            "
          >
            <small class="text-danger">
              {{ "account_error_required" | localise }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label class="sr-only" for="mail">{{
            "account_parrainage_email" | localise
          }}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fa fa-envelope mr-2"></i>
                {{ "account_parrainage_email" | localise }}
              </div>
            </div>
            <input
              type="email"
              class="form-control"
              id="mail"
              formControlName="mail"
              [placeholder]="'account_email_placeholder' | localise"
              disabled
            />
          </div>
          <div
            *ngIf="
              parrainageForm.get('mail').hasError('required') &&
              parrainageForm.get('mail').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_required" | localise }}
            </small>
          </div>
        </div>
        <div>
          <button
            class="btn my-btn float-right"
            [disabled]="parrainageForm.invalid"
          >
            {{ "btn_parrainage" | localise }}
          </button>
        </div>
        <div>
          <p *ngIf="isSent" class="text-success mt-2">
            {{ "account_parrainage_success" | localise }}
          </p>
          <p *ngIf="errorSent" class="text-danger mt-2">
            {{ "account_parrainage_failed" | localise }}
          </p>
        </div>
      </form>
    </div>
  </div>
</div>
