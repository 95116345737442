import { ITemplate } from "../src/common/src/bdd/interfaces/ITemplate";
import { User } from "../src/common/src/bdd/user/User";
import { HieroBDD } from "../src/hiero/app/services/hierobdd.service";


const FIREBASE_TEMPLATE_COLLECTION = 'templates';
const FIREBASE_TEMPLATE_COLLECTION_FILTER = 'templates_filter';

export interface ITemplateFilter {
    srcLanguageIso639: string ;
    destLanguageIso639: string ;
    srcCountryCode: string;
    documentTypeId: string;
    priceHT: number;
    idTemplateOrigin: string;
}

export class NewTemplate {

    private dataFilter: ITemplateFilter = {
        srcLanguageIso639: '' ,
        destLanguageIso639: '' ,
        srcCountryCode: '',
        documentTypeId: '',
        priceHT: 0,
        idTemplateOrigin: ''
    }
    private datas = [] 

    constructor(private user: User,) {
        
    }

    public regeneratedTemplate() {

    
        const query = this.user.DB.collection(FIREBASE_TEMPLATE_COLLECTION)
        .get()
        .then(
            (snap) => {
               const toto =  snap.docs.map((document) => {
                    //this.datas.push(document.data())
                    
                    const data = document.data() as ITemplate
                 
                    this.dataFilter.destLanguageIso639 = data.destLanguageIso639
                    this.dataFilter.documentTypeId = data.documentTypeId
                    this.dataFilter.priceHT = data.priceHT
                    this.dataFilter.srcCountryCode = data.srcCountryCode
                    this.dataFilter.srcLanguageIso639 = data.srcLanguageIso639
                    this.dataFilter.idTemplateOrigin = document.id
                    
                    console.log(this.dataFilter)
                    
                })
                console.log(toto)
             

            })

        // for(let i = 0; i < documentFilter.length; i++) {
        //     this.user.DB.collection(FIREBASE_TEMPLATE_COLLECTION_FILTER)
        //     .doc()
        //     .set(documentFilter[i])
        // }

        
        
    }
}