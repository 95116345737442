import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoggedInGuard } from "./guards/loggedin.guard";
import { AccountComponent } from "./routes/account/account.component";
import { LoginComponent } from "./routes/account/login/login.component";
import { SignupComponent } from "./routes/account/signup/signup.component";
import { AppBaseComponent } from "./routes/app/appbase/appbase.component";
import { PrestationEditorComponent } from "./routes/app/prestations/prestation/editor/prestationEditor.component";
import { PrestationComponent } from "./routes/app/prestations/prestation/index/prestation.component";
import { PrestationBaseComponent } from "./routes/app/prestations/prestation/prestationBase.component";
import { PrestationListComponent } from "./routes/app/prestations/prestations.component";
import { ProfileComponent } from "./routes/app/profile/profile.component";
import { TraducteurServiceListComponent } from "./routes/app/services/traducteurServiceList.component";
import { SupportComponent } from "./routes/app/support/support.component";
import { TraducteurComponent } from "./routes/app/traducteur/traducteur.component";
import { BaseComponent } from "./routes/base/base.component";
import { NotFoundComponent } from "./routes/not_found/not_found.component";
import { ProfileCheckComponent } from "./routes/verify/profile/profilecheck.component";
import { VerifyBaseComponent } from "./routes/verify/verifybase/verifybase.component";
import { ResetPasswordComponent } from "./routes/account/reset-password/reset-password.component";
import { MaintenanceComponent } from "./routes/maintenance/maintenance.component";
import { InterpretationsComponent } from "./routes/app/interpretations/interpretations.component";
import { DashboardComponent } from "./routes/app/dashboard/dashboard.component";
import { FacturesComponent } from "./routes/app/factures/factures.component";
import { FactureDetailComponent } from "./routes/app/facture-detail/facture-detail.component";
import { SubscriptionComponent } from "./routes/app/subscription/subscription.component";
import { CheckoutComponent } from "./routes/app/checkout/checkout.component";
import { EmailComponent } from "./routes/app/emailVerify/email.component";
import { CardComponent } from "./routes/app/card/card.component";
import { InterpretationComponent } from "./routes/app/interpretations/interpretation/interpretation.component";

const appRoutes: Routes = [
  // Pages for logging in
  {
    path: "compte",
    component: AccountComponent,
    children: [
      {
        path: "connexion",
        component: LoginComponent,
      },
      {
        path: "emailVerify/:id",
        component: EmailComponent,
      },
      {
        path: "inscription",
        component: SignupComponent,
      },
      {
        path: "inscription/:id",
        component: SignupComponent,
      },
      {
        path: "resetPassword",
        component: ResetPasswordComponent,
      },
      { path: "", redirectTo: "connexion", pathMatch: "full" },
    ],
  },
  // Intermediate steps before getting to app section

  {
    path: "verify",
    canActivate: [LoggedInGuard],
    component: VerifyBaseComponent,
    children: [
      {
        path: "profile",
        component: ProfileCheckComponent,
      },
      { path: "**", component: ProfileCheckComponent },
      { path: "", redirectTo: "profile", pathMatch: "full" },
    ],
  },
  {
    path: "verify/:id",
    canActivate: [LoggedInGuard],
    component: VerifyBaseComponent,
    children: [
      {
        path: "profile",
        component: ProfileCheckComponent,
      },
      { path: "**", component: ProfileCheckComponent },
      { path: "", redirectTo: "profile", pathMatch: "full" },
    ],
  },

  // Validated section - user is connected
  {
    path: "app",
    canActivate: [LoggedInGuard],
    component: AppBaseComponent,
    children: [
      {
        path: "profile",
        component: ProfileComponent,
        data: { title: "section_account_title" },
      },
      /*{
        path: "traducteur",
        component: TraducteurComponent,
        data: { title: "section_agency_title" },
      },
      {
        path: 'home',
        component: HomeComponent,
        data: { title: 'Acceuil' }
      },*/
      {
        path: "support",
        component: SupportComponent,
        data: { title: "section_support_title" },
      },
      {
        path: "card",
        component: CardComponent,
        data: { title: "main_left_menu_section_parameters_subscription" },
      },
      // {
      //   path: "card/:id",
      //   component: card,
      //   data: { title: "main_left_menu_section_parameters_subscription" },
      // },
      {
        path: "interpretations",
        component: InterpretationsComponent,
        data: { title: "interpretation" },
      },
      {
        path: "interpretations/:id",
        component: InterpretationComponent,
      },

      {
        path: "dashboard",
        component: DashboardComponent,
        data: { title: "section_dashboard_title" },
      },
      {
        path: "factures",
        component: FacturesComponent,
        data: { title: "main_menu_left_section_navigation_factures" },
      },
      {
        path: "factures/:id",
        component: FactureDetailComponent,
      },
      {
        path: "services",
        component: TraducteurServiceListComponent,
        data: { title: "section_services_title" },
      },
      {
        path: "prestations",
        component: PrestationListComponent,
        data: { title: "section_tasks_title" },
      },
      {
        path: "prestation/:prestationId",
        component: PrestationBaseComponent,
        data: { title: "section_task_title" },
        children: [
          {
            path: "index",
            component: PrestationComponent,
          },
          {
            path: "document/:documentId",
            data: { title: "section_document_title" },
            component: PrestationEditorComponent,
          },
          { path: "**", component: PrestationComponent },
        ],
      },
      { path: "", redirectTo: "dashboard", pathMatch: "prefix" },
    ],
  },

  //maintenance
  {
    // brin
    path: "maintenance",
    component: MaintenanceComponent,
  },

  // Logged out page(s)
  {
    path: "",
    component: BaseComponent,
    children: [],
  },

  /// Fallbacks
  { path: "", redirectTo: "BaseComponent", pathMatch: "full" },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
      // { enableTracing: true } // <-- debugging purposes only
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
