
/*
export interface ISocketConfig {
  socket_origin: string;
  socket_subpath: string;
}
*/

export interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

export interface IAppConfig {
  /**
   * The origin of the url: https://hiero.bymeunivers.com
   */
  origin: string;
  /**
   * If this is hosted on a subpath, eg. /app
   */
  subpath: string;
}

export enum LoggingLevel {
  All = 5,
  Debug = 4,
  Info = 3,
  Warning = 2,
  Error = 1,
  Off = 0
}

export interface IConfig {
  /**
   * Configuration for Firebase
   */
  firebase: IFirebaseConfig;
  /**
   * Information about this app: what is the path/subpath ?
   */
  app: IAppConfig;
  /**
   * Is this production mode or not?
   */
  production: boolean;
  /**
   * Logging level
   */
  logLevel: LoggingLevel;

  /**
   * Bundle ID for the corresponding app, in case auth goes to app
   */
  appBundleId: string;
  appMinimumVersion: string;

  /**
   * Geocode API Key for google
   */
  google_geocode_apikey: string;

  /**
   * Redirect URL for password reset
   */
  resetRedirectUrl: string;

  /**
 * Stripe public key
 */
  public_key_stripe: string;

  /**
   * twilio public key
   */
  public_key_twilio?: string

  /**
   * twilio api key
   */
  twilio_api_key?: string
}





