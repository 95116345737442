import { PartialObserver, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { IMessage, EnumMessageSender } from '../interfaces/IMessaging';
import { User } from '../user/User';
import { Prestation } from '../prestation/Prestation';
import { Traducteur } from '../traducteur/Traducteur';
import { IFile } from '../interfaces/IFile';


const FIREBASE_MESSAGES_COLLECTION = 'messages';


export class MessageBox {

  private _user: User;
  private _query: firebase.firestore.Query;
  private _id: string;


  private _stopListeningMessages;

  private _messagesSubject: BehaviorSubject<IMessage[]>;

  private _messages: IMessage[];
  private _ascending: boolean;

  constructor(user: User, query: firebase.firestore.Query, snapshot: firebase.firestore.QuerySnapshot, ascending: boolean = true) {
    this._user = user;
    this._query = query;
    this._ascending = ascending;

    this._messages = [];
    this._messagesSubject = new BehaviorSubject(this._messages);

    this._stopListeningMessages = query.onSnapshot(
      (newSnap: firebase.firestore.QuerySnapshot) => {
        this.processMessages(newSnap);
      }
    );

  }

 
  public cleanup() {
    if (this._stopListeningMessages ) {
      this._stopListeningMessages();
      this._stopListeningMessages = null;
    }
  }

  get Id(): string {
    return this._id;
  }

 
  private processMessages(snapshot: firebase.firestore.QuerySnapshot) {
    this._messages = [];
    snapshot.forEach(
      (doc) => {

        const msg: IMessage = doc.data() as IMessage;
        this._messages.push(msg);
      }
    );

    this._messages = this._messages.sort(
      (a: IMessage, b: IMessage) => {
        if (this._ascending) {
          return (a.sentAt - b.sentAt);
        } else {
          return (b.sentAt - a.sentAt);
        }
      }
    );

    this._messagesSubject.next(this._messages);
  }

  public WatchMessages(observer: PartialObserver<IMessage[]>): Subscription {
    return this._messagesSubject.subscribe(observer);
  }

  public forPrestation(prestationId: string): IMessage[] {
    const filtered = this._messages.filter(msg => msg.prestationId === prestationId);
    const sorted = filtered.sort(
      (a, b) => {
        return a.sentAt - b.sentAt;
      }
    );
    return sorted;
  }


  public async AddMessage(prestation: Prestation, text: string, attachFile?: IFile) {

    let senderType: EnumMessageSender = EnumMessageSender.translator;
    if (prestation.Data.uid === this._user.Id) {
      senderType = EnumMessageSender.client;
    }

    const message: IMessage = {
      // Id of the user
      clientId: prestation.Data.uid,
      // Id of the translator
      translatorId: prestation.Data.traducteurId,
      // The id of the message box
      prestationId: prestation.Id,
      // The sender
      sender: senderType,

      // The sender uid
      senderUid: this._user.Id,
      // The text of the message
      text: text,
      // The send date
      sentAt: Date.now(),
    };

    if (!!attachFile) {
      message['file'] = attachFile;
    }

        // Add the message
    await this._user.DB.collection(FIREBASE_MESSAGES_COLLECTION).add(message);

  }

  public static async InitForClientAndPrestation(user: User, prestationId: string) {
    const query = user.DB.collection(FIREBASE_MESSAGES_COLLECTION)
      .where('clientId', '==', user.Id)
      .where('prestationId', '==', prestationId);
    // TODO: Limit ?

    const snapshot = await query.get();
    return new MessageBox(user, query, snapshot);

  }

  public static async InitForClient(user: User) {
    const query = user.DB.collection(FIREBASE_MESSAGES_COLLECTION)
      .where('clientId', '==', user.Id)
    // TODO: Limit ?

    const snapshot = await query.get();
    return new MessageBox(user, query, snapshot);

  }

  public static async InitForTranslatorAndPrestation(translator: Traducteur, prestation: Prestation) {
    const query = translator.User.DB.collection(FIREBASE_MESSAGES_COLLECTION)
      .where('translatorId', '==', translator.Id)
      .where('prestationId', '==', prestation.Id);
    // TODO: Limit ?

    const snapshot = await query.get();
    return new MessageBox(translator.User, query, snapshot, false);

  }


}
