import { PartialObserver } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { EmptyTraducteurProfile, ITraducteur, TraducteurProfileSchema } from '../interfaces/ITraducteur';
import { User } from '../user/User';
import { TraducteurServiceList } from './TraducteurService';

const FIREBASE_TRADUCTEUR_COLLECTION = 'traducteurs';

/**
 * Represents a translator. This class handles receiving and updating data about the translator with firebase.
 * This class can only be created by passing a valid User object.
 */
export class Traducteur {

  public get User(): User {
    return this._user;
  }

  private constructor(user: User, docId: string) {

    this._user = user;
    this._docId = docId;

    this._docRef = user.DB.collection(FIREBASE_TRADUCTEUR_COLLECTION).doc(docId);

    this._profileSubject = new BehaviorSubject<ITraducteur>(null);

    this._docRef.onSnapshot({
      next: (snapshot: firebase.firestore.DocumentSnapshot) => {
        this._profileSubject.next(snapshot.data() as ITraducteur);
      },
      error: (err) => {}
    });

  }

  public get Id(): string {
    return this._docId;
  }

  public async getTraducterServices(): Promise<TraducteurServiceList> {
    if (!this._traducteurServiceList) {
      this._traducteurServiceList = await TraducteurServiceList.Init(this);
    }
    return this._traducteurServiceList;
  }

  public async hasServices(): Promise<boolean> {
    const hasResult = await TraducteurServiceList.HasServices(this);
    return hasResult;
  }

  private _user: User;
  private _docId: string;
  private _profileSubject: BehaviorSubject<ITraducteur>;
  private _docRef: firebase.firestore.DocumentReference;
  private _traducteurServiceList: TraducteurServiceList|null;

  public async sync() {
    await this._docRef.get();
  }

  /** Watch for changes to the translator profile. */
  public WatchProfile(observer: PartialObserver<ITraducteur>): Subscription {
    return this._profileSubject.subscribe(observer);
  }

  /** Update the translator profile */
  public async UpdateProfile(profile: ITraducteur) {
    // let validatedProfile: ITraducteur;
    try {
      /* validatedProfile = await TraducteurProfileSchema.validate(profile,
        {
          strict: false,
          abortEarly: false,
          stripUnknown: true
        }
      );
      */ 

      await this._docRef.update(profile);

    } catch (err) {
      return Promise.reject(err);
    }

  }

  /** Instantiate a new Translator class */
  public static async Init(user: User): Promise<Traducteur> {
    const query = user.DB.collection(FIREBASE_TRADUCTEUR_COLLECTION).where('uid', '==', user.Id);

    try {
      const snapshot: firebase.firestore.QuerySnapshot = await query.get();
      let docId: string = null;
      if (snapshot.empty) {
        // No translator of this type, create one
        const traducteur: ITraducteur = EmptyTraducteurProfile;
        traducteur.uid = user.Id;

        const docRef: firebase.firestore.DocumentReference = await user.DB.collection(FIREBASE_TRADUCTEUR_COLLECTION).add(traducteur);
        
        await user.GetIDToken();


        docId = docRef.id;
      } else {
        docId = snapshot.docs[0].id;
      }

      const trad: Traducteur = new Traducteur(user, docId);
      await trad.sync();

      return trad;

    } catch (err) {
      console.error(err);
      throw err;
    }
  }

}
