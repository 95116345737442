import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-not-subscribe-modal',
  templateUrl: './not-subscribe-modal.component.html',
  styleUrls: ['./not-subscribe-modal.component.scss']
})
export class NotSubscribeModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private route: Router) { }

  ngOnInit() {
  }
  goSubscription(){
    this.route.navigate(['app', ''])
    this.activeModal.close()
  }
}
