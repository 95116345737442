import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { IPDFInput } from '../../../bdd/interfaces/IPDF';

@Component({
  selector: 'app-pdf-input',
  templateUrl: './pdfinput.component.html'
})
export class PDFInputComponent implements AfterViewInit, OnDestroy {
  @Input() input: IPDFInput;
  @Input() metaEditing = true;
  @ViewChild('value', { static: true }) inputValue: ElementRef;

  @Output() update: EventEmitter<IPDFInput> = new EventEmitter();

  private unsub: Subscription;

  constructor(
  ) {
  }

  ngAfterViewInit() {

    this.unsub = fromEvent(this.inputValue.nativeElement, 'keyup')
    .pipe(
      map((event: any) => {
        return event.target.value;
      }),
      debounceTime(1000),
      distinctUntilChanged(),
    )
    .subscribe(
      (text: string) => {
        console.log("Updating input item");
        this.input.text = text;
        this.update.emit(this.input);
      }
    );


  }

  ngOnDestroy() {
    if (this.unsub) {
      this.unsub.unsubscribe();
    }
  }

  onUpdate(value: string) {

  }


}
