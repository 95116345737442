module.exports = {
  fr: {
    /************************* Bloc pour les nouvelles phrases *************************/
    //trad
    tasks_received_on: "Reçu le",
    intervention_visio:
      "Le client a demandé une intervention par visioconférence.",
    intervention_tel: "Le client a demandé une intervention par téléphone.",
    intervention_place: "Cette intervention nécessite un déplacement.",
    intervention_payment:
      "Une fois la prestation acceptée, le client procédera au paiement, qui vous sera versé une fois l'intervention terminée.",

    /********************** fin du bloc pour les nouvelles phrases *********************/

    /************ Bloc pour les nouvelles phrases envoyées pour vérification ***********/
    /******** fin du bloc pour les nouvelles phrases envoyées pour vérification ********/

    /***************** Bloc pour les phrases utilisées que sur le trad *****************/
    account_asser: "Êtes-vous assermenté(e) ?",
    agency_companyname_help:
      "Si vous n'êtes pas un professionnel, veuillez choisir un nom d'affichage. Ne renseignez le SIRET que si vous êtes une entreprise.",
    agency_contact_help:
      "Renseignez votre mail et n° de téléphone pour pouvoir discuter avec vos clients et pour recevoir des notifications.",
    parrainer_client: "Parrainer des clients",
    parrainage_paragraph:
      "Vous souhaitez centraliser toutes les demandes de vos clients ? Invitez vos clients à utiliser HIERØ pour recevoir toutes leurs demandes sur votre espace.",
    btn_parrainage: "Envoyer une invitation",
    account_parrainage_success:
      "Votre mail de parrainage a été envoyé avec succés.",
    account_parrainage_failed: "Le mail n'a pas pu être envoyé.",
    account_parrainage_email: "Inviter un client",
    consult_missions: "Consulter mes missions",
    prestation_accept_order:
      "Après avoir accepté la commande, le client procèdera au paiement. Une fois le paiement effectué, vous pourrez débuter la traduction.",
    prestation_reject_order:
      "En cas de refus de votre part, notre logiciel soumettra automatiquement la commande à un autre traducteur.",
    intervention_next_action_accept_order: "Accepter l'intervention",
    intervention_next_action_reject_order: "Refuser l'intervention",
    intervention_next_action_start_order: "Débuter l'intervention",
    intervention_order_details: "Détails de l'intervention",
    intervention_order_for: "Pour le",
    intervention_address: "Adresse de l'intervention",
    intervention_info_supp: "Informations supplémentaires sur l'intervention",
    agency_setup_tva: "Êtes-vous assujetti à la TVA ?",
    agency_setup_tva_placeholder: "",
    agency_setup_tva_number: "Numéro de la TVA",
    agency_setup_tva_number_placeholder: "",
    agency_setup_tva_number_help:
      "Renseignez votre numéro de TVA si vous êtes assujetti à la TVA",
    dashboard_turnover: "Mon chiffre d'affaires",
    dashboard_evolution_month: "Evolution au cours du mois",
    dashboard_trad_month: "Traductions ce mois-ci",
    dashboard_interpretation_month: "Interprétariat ce mois-ci",
    dashboard_new_missions: "Nouvelles missions",
    dashboard_my_missions: "Mes missions en cours",
    dashboard_command_date: "Date de commande :",
    dashboard_type: "Type :",
    dashboard_total_sells: "Total des ventes depuis mon inscription",
    dashboard_doc_type: "Type de document les plus traduits",
    dashboard_my_clients: "Mes clients récurrents",
    dashboard_trad: "Traduction",
    dashboard_interpretation: "Interprétariat",
    dashboard_particulier: "Particulier",
    dashboard_pro: "Professionnel",
    dashboard_prestation_lang: "Mes prestations par langue",
    dashboard_evolution_year: "Evolution sur l'année en cours",
    dashboard_status: "Statut :",
    agree: "En cliquant, vous acceptez nos",
    our_term: "conditions générales d'utilisation",
    and_the: "et le",
    stripe_agreement: "contrat de compte connecté Stripe. ",
    created_at: "Créé le ",
    updated_at: "Mise à jour le ",
    services_none_yet_subscribe: "Abonnez-vous pour activer vos prestations",
    asser_placeholder: "Êtes-vous un(e) traducteur(rice) assermenté(e) ?",
    agency_expl_4_expl:
      "Il est nécessaire de nous fournir un justificatif prouvant votre assermentation avant de pouvoir utiliser nos services.",
    agency_expl_4_expl_1: "Vous pouvez l'envoyer par ",
    agency_expl_4_expl_2:
      "Pour toute question concernant cette procédure, n'hésitez pas à nous contacter.",
    label_assermentation: "Assermentation",
    login_main_title: "Espace traducteur",
    login_main_subtitle:
      "Accédez à vos missions ainsi qu'à l'éditeur de document grâce à l'espace traducteur Hiero !",
    signup_caption_title: "Hierø - Espace traducteur",
    signup_main_subtitle:
      "Créez votre compte, rejoignez notre interface et devenez visible auprès de nos nombreux utilisateurs !",
    main_header_subtitle: "Espace traducteur",
    main_left_menu_title: "Traducteur",
    main_left_menu_section_navigation_missions: "Mes missions",
    main_left_menu_section_parameters_subscription: "Mon Abonnement",
    agency_setup_title: "À propos de votre profil",
    order_translate_to: "vers le ",
    section_services_title: "Mes services",
    section_tasks_title: "Mes missions",
    section_task_title: "Effectuer une mission",
    section_document_title: "Traduire un document",
    tasks_src_lang: "Langue d'origine",
    prestation_breadcrumb_tasks: "Mes missions",
    prestation_order_number: "N° de commande :",
    prestation_next_action_title: "Prochaines actions",
    prestation_next_action_accept_order: "Accepter la commande",
    prestation_next_action_accept_order_expl:
      "Cliquez ici pour accepter la commande. Le client procèdera ensuite au paiement. Une fois le paiement effectué, vous pourrez commencer la traduction.",
    prestation_next_action_reject_order: "Refuser la commande",
    prestation_next_action_reject_order_expl:
      "Cliquez ici pour refuser la commande.",
    prestation_next_action_rejected: "Vous avez refusé cette commande.",
    prestation_next_action_waiting_for_payment:
      "En attente du paiement par le client.",
    prestation_next_action_translate_expl:
      "Veuillez procéder à la traduction de chaque document de cette commande en cliquant sur le bouton 'Traduire'. Une fois que tous les documents sont prêts, cliquez sur le bouton 'Envoyer au client'. Pour toute question ou précision, vous pouvez directement échanger avec le client via le messagerie.",
    prestation_next_action_translate_all_done_expl:
      "Vous avez terminé tous les documents de cette commande.",
    prestation_next_action_translate_confirm:
      "Envoyer au client pour validation",
    prestation_next_action_waiting_for_validation:
      "Le client est en train de valider la traduction.",
    prestation_next_action_validated: "Commande validée par le client.",
    prestation_order_details: "Détails de la commande :",
    prestation_order_to: "à",
    prestation_order_country_origin: "Pays d'origine :",
    prestation_order_document_count: "Documents à traduire :",
    prestation_order_price: "Prix (HT) :",
    prestation_order_date: "Commande créée le :",
    prestation_order_documents_title: "Documents à traiter :",
    prestation_order_document_originals: "Originaux",
    prestation_order_document_translated: "Traduit(s)",
    prestation_order_document_not_yet_translated: "Pas encore de traduction",
    prestation_order_document_translate: "Traduire !",
    prestation_order_confirm_ok: "OK",
    prestation_order_confirm_cancel: "Annuler",
    prestation_order_validate_title: "Confirmer",
    prestation_order_validate_msg:
      "Etes-vous sûr de vouloir valider cette commande ? Le client recevra une notification et aura désormais accès aux documents traduits.",
    prestation_order_accept_title: "Confirmer",
    prestation_order_accept_msg:
      "Etes-vous sûr de vouloir accepter cette commande ?",
    prestation_order_refuse_title: "Confirmer",
    prestation_order_refuse_msg:
      "Etes-vous sûr de vouloir refuser cette commande ?",
    prestation_document_validate: "Valider la traduction",
    prestation_document_show_original:
      "Visionner l'original dans un autre onglet :",
    prestation_document_button_reset: "Réinitialiser le document",
    prestation_document_confirm_title: "Confirmer",
    prestation_document_confirm_message:
      "Êtes-vous sûr de vouloir réinitialiser la traduction ?  Toutes vos modifications seront perdues !",
    services_add_button: "Ajouter une nouvelle offre...",
    services_none_yet:
      "Pour être visible sur la plateforme Hierø, veuillez préciser les types de prestations que vous proposez.",
    services_add_expl:
      "Veuillez préciser les les langues que vous pratiquez, puis cliquez sur 'Ajouter'.",
    services_add_src_lang: "Langue d'origine",
    services_add: "Ajouter",
    services_error_add:
      "Cette combinaison de langues n'est pas encore possible avec Hierø. Contactez-nous pour plus d'informations !",
    services_dont_see_title: "Je n'arrive pas à consulter mes langues.",
    services_dont_see_response:
      "Hierø facilite votre travail de traduction en vous fournissant des modèles de vos documents prêts à l'emploi. Nous développons continuellement notre gamme de documents. Veuillez nous contacter à  pour demander d'autres langues.",
    services_list_title: "Votre liste de services activés",
    services_expl:
      "Cliquez sur chaque offre afin de personnaliser les documents que vous gérez. Vous pouvez soit choisir de refuser un certain type de document en désactivant la bouton, soit préciser votre prix pour traiter ce type de document.",
    services_list_active: "Activé",
    services_list_country: "Pays",
    services_list_document_type: "Type de document",
    services_list_average_price: "Prix moyen (HT)",
    services_list_your_price: "Votre prix (HT)",
    services_list_your_price_reset: "Réinitialiser",
    services_list_delete: "Supprimer",
    temps_ex: "Temps estimé",
    question_inter: "Êtes-vous interprète ?",
    question_inter_price: "Prix pour une heure :",
    question_inter_1:
      "Vous réalisez des missions d'interprétariat sur notre logiciel pour",
    calendar: " Calendrier",
    calendar_hidden: "Cacher le calendrier",
    interpretation_label_menu: "Interprétariat",
    interpretation_label: "Interprétariat",
    room_inter: "Salle d’interprétariat",
    once_page_price: "Vos tarifs à la page",
    once_page_information:
      "Nous vous invitons à donner votre tarif pour la traduction à la page d'un document (250 mots). Ce tarif sera proposé au client si le document qu'il souhaite traduire n'est pas enregistré par notre système.",
    facture_pro: "Professionnel",
    /************** fin du bloc pour les phrases utilisées que sur le trad *************/

    /**************** Bloc pour les phrases utilisées sur le pro et trad ***************/
    agency_name_help:
      "En cas de problèmes, il sera plus facile à notre équipe de vous retrouver avec votre nom et prénom.",
    menu_label_tel: "Par téléphone",
    section_numero_commande: "N° de commande",
    dashboard_traductor_asser: "Traduction assermentée",
    dashboard_traductor_classic: "Traduction classique",
    dashboard_month_janv: "Janv",
    dashboard_month_fevr: "Févr",
    dashboard_month_mars: "Mars",
    dashboard_month_avr: "Avr",
    dashboard_month_mai: "Mai",
    dashboard_month_juin: "Juin",
    dashboard_month_juill: "Juil",
    dashboard_month_aout: "Août",
    dashboard_month_sept: "Sept",
    dashboard_month_oct: "Oct",
    dashboard_month_nov: "Nov",
    dashboard_month_dec: "Déc",
    sub_subscription: "Abonnement",
    main_header_title: "Hierø -",
    main_left_menu_section_navigation: "Menu de navigation",
    main_left_menu_section_parameters: "Paramètres",
    agency_setup_explanation:
      "Hierø propose vos services aux clients proches de vos locaux.<br>Merci de nous préciser le nom de votre entreprise ainsi que votre adresse postale. <br>Ces informations seront proposées aux clients lorsqu'ils cherchent un traducteur et/ou un interprète.",
    section_prestation: "Prestation",
    delai_intervention: "Durée de l'intervention",
    type_doc_commande: "Type de document demandé",
    price_total_ht: "Montant total HT",
    section_dashboard_title: "Tableau de bord",
    tasks_price: "Prix (HT)",
    support_tel_es: "Tel : (+34) 911 23 88 87",
    support_tel_en: "Tel : (+1) 929 274 5104",
    type_intervention: "Type d'intervention demandée",
    once_page_price_default: "Prix par defaut (HT)",
    client: "Client",
    /************ fin du bloc pour les phrases utilisées sur le pro et trad ************/

    /************ Bloc pour les phrases utilisées sur le trad et particulier ***********/
    modal_supp_title: "Confirmer la suppression de votre compte",
    facture_part: "Particulier",
    tasks_filters: "Filtres",
    /******** fin du bloc pour les phrases utilisées sur le trad et particulier ********/

    /*************** Bloc pour les phrases utilisées sur les 3 solutions ***************/
    menu_label_event_name: "Objet de l'intervention",
    modif_profile_success_pwd:
      "Votre mot de passe a été mis à jour avec succès.",
    modif_profile_failed_pwd:
      "Une erreur s'est produite lors de la modification de votre mot de passe. Veuillez vérifier que vous avez saisi le bon mot de passe.",
    modal_modif_title: "Confirmer la modification de votre adresse mail",
    modif_profile_failed_login:
      "Adresse mail ou mot de passe erroné. Veuillez réessayer.",
    augmente_budget:
      "Pour augmentter votre solde, vous pouvez nous adresser un chèque ou bien procéder à un virement.",
    no_budget: "Vous n'avez aucun budget pour l'instant",
    validation_mail_registration:
      "HIERØ vous souhaite la bienvenue ! Pour pouvoir commencer à utiliser nos services, vous devez d’abord confirmer votre adresse mail.",
    validation_modif_mail:
      "La modification de votre adresse email s'est faite avec succès. Pour pouvoir utiliser nos services, vous devez à nouveau confirmer votre adresse mail.",
    validation_question:
      "Vous n’avez pas reçu le mail de confirmation ? Cliquez sur le bouton pour renvoyer le mail de confirmation.",
    validation_mail_resend: "Renvoyer le mail de confirmation",
    message_verification:
      "Veuillez vérifier votre boite mail pour confirmer votre adresse mail.",
    non_valid_address: "Adresse mail non validée",
    validation_mail_success: "Mail de confirmation envoyé avec succès.",
    account_error_invalid_email: "L'adresse saisie n'est pas valide.",
    account_error_min_password: "8 carctères minimum",
    account_error_passwords_do_not_match:
      "Les mots de passe ne correspondent pas",
    account_confirm: "Confirmez votre mot de passe",
    modif_profile_success: "Votre profil a été mis à jour avec succès.",
    modif_profile_failed:
      "Une erreur s'est produite lors de la modification de votre profil. Veuillez vérifier vos informations et réessayer.",
    facture_conditions: "Conditions",
    facture_comptant: "Comptant",
    facture_echeance: "Échéance",
    facture_adress: "adresse de facturation",
    facture_qte: "Qté",
    facture_price: "Prix",
    facture_unitaire: "unitaire",
    facture_montant: "Montant",
    facute_solde: "Solde à payer",
    facture_tva_fr: "20 % TVA FR",
    order_language_from: "De",
    order_language_to: "Vers",
    account_hist_pro_date: "Date d'intervention",
    select_choose: "Choisir...",
    tasks_price_tva: "TVA",
    order_no_asser_document_name: "Nom du document",
    menu_label_traductor_asser: "Traduction assermentée",
    menu_label_traductor_classic: "Traduction classique",
    menu_label_interprestation: "Interprétariat",
    menu_label_all: "Tout afficher",
    login_caption_title: "Hierø - Espace traducteur",
    login_caption_subtitle:
      "Un logiciel pensé par des traducteurs pour des traducteurs.",
    login_main_logo: "Hierø",
    login_main_connect: "Se connecter",
    login_main_email: "E-mail",
    login_main_email_placeholder: "Renseignez votre adresse mail",
    login_main_email_help: "Veuillez vous identifier avec votre adresse mail.",
    login_main_password: "Mot de passe",
    login_main_password_placeholder: "Indiquez votre mot de passe",
    login_main_password_help: "",
    login_main_button: "Se connecter",
    login_main_to_signup: "Pas encore inscrit ? Inscrivez-vous !",
    login_main_copyright: "© Hierø - HIERØ 2022",
    login_error_required_field: "Champ obligatoire",
    login_error_invalid_email: "L'adresse saisie n'est pas valide.",
    login_error_email_not_found: "L'adresse mail n'existe pas.",
    signup_caption_subtitle: "Choisissez Hierø et gagnez en visibilité !",
    signup_main_logo: "Hierø",
    signup_main_title: "Inscription",
    signup_main_email: "Adresse mail",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Mot de passe",
    signup_main_password_placeholder: "",
    signup_main_password_help: "",
    signup_main_confirm: "Confirmer votre mot de passe",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Nom",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Prénom",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Téléphone",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "S'inscrire",
    signup_main_to_signup: "Déjà membre ? Se connecter",
    signup_main_copyright: "© Hierø - HIERØ 2022",
    signup_error_required_field: "Champ obligatoire",
    signup_error_invalid_email: "L'adresse saisie n'est pas valide.",
    signup_error_min_password: "8 caractères minimum",
    signup_error_max_password: "30 caractères maximum",
    signup_error_passwords_do_not_match:
      "Les mots de passe ne correspondent pas",
    login_forgot_password: "Mot de passe oublié ?",
    reset_password_main: "Réinitialiser votre mot de passe",
    reset_password_email_title: "E-mail",
    reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Envoyé",
    reset_password_login: "Connexion",
    reset_password_success:
      "Un e-mail a été envoyé à votre adresse. Cliquez ensuite sur le lien pour modifier votre mot de passe.",
    main_left_menu_section_parameters_agency: "Mon entreprise",
    main_left_menu_section_parameters_services: "Mes services",
    main_left_menu_section_parameters_user: "Mon profil",
    main_left_menu_section_parameters_support: "Support",
    reset_password_error_required: "Champ obligatoire",
    reset_password_error_invalid_email: "L'adresse saisie n'est pas valide.",
    reset_password_error_user_not_found: "L'adresse mail n'existe pas.",
    main_header_user_menu_logout: "Se déconnecter",
    agency_setup_companyname: "Raison sociale",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help:
      "Le nom de l’entreprise qui s’affiche pour toutes les commandes et factures, etc.",
    agency_setup_address: "Adresse",
    agency_setup_siret: "N° de SIRET",
    agency_setup_siret_placeholder: "",
    agency_setup_siret_help:
      "Entrer votre numéro de SIRET s'il s'agit d'une entreprise",
    agency_setup_address_placeholder: "",
    agency_setup_address_help:
      "Commencez à écrire votre adresse, et sélectionnez la bonne adresse dans le menu déroulant.",
    agency_setup_addresscompl: "Complément d'adresse",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help:
      "Bâtiment, escalier, digicode, etc. Lorsque vos clients viennent chercher leurs documents, ils doivent avoir toutes les informations nécessaires.",
    agency_setup_tel: "Télephone",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help:
      "Le numéro de téléphone à utiliser pour toute correspondance avec vos clients.",
    agency_setup_email: "Adresse mail",
    agency_setup_email_placeholder: "",
    agency_setup_email_help:
      "L'adresse mail à utiliser pour toute correspondance avec vos clients. Veuillez en indiquer une autre s'il ne s'agit pas de votre adresse d'inscription sur Hierø.",
    agency_setup_save: "Enregistrer",
    agency_setup_error_required_field: "Champ obligatoire",
    agency_setup_error_invalid_email: "L'adresse saisie n'est pas valide.",
    agency_setup_error_valid_address:
      "Veuillez sélectionner une des adresses proposées dans le menu déroulant.",
    agency_address_not_found: "Votre adresse est introuvable ?",
    agency_address_title: "Saisir manuellement vos coordonnées",
    agency_address_address: "Adresse :",
    agency_address_number: "N°",
    agency_address_street: "Rue",
    agency_address_code: "Code postal",
    agency_address_city: "Ville",
    agency_address_country: "Pays",
    agency_address_coords: "Coordonnées GPS :",
    agency_address_coords_lat: "Latitude",
    agency_address_coords_long: "Longitude",
    agency_address_cancel: "Annuler",
    agency_address_validate: "Valider",
    main_left_menu_section_navigation_dashboard: "Tableau de bord",
    main_menu_left_section_navigation_factures: "Mes factures",
    head_facture: "Facture",
    section_date: "Date",
    section_numero_facture: "Facture n°",
    section_type_prestation: "Type de prestation",
    section_price: "Montant total",
    section_print: "Imprimer",
    section_generate_facture: "Générer la facture en PDF",
    section_consulter: "Consulter",
    prestation: "Prestation",
    commande_qte: "Quantité",
    commande_produit: "Produit",
    commande_desc: "Description",
    total_ht: "Total HT",
    total_tva: "Total TVA",
    total_ttc: "Total TTC",
    retour_btn: "Retour",
    progress_commande: "Progression de la commande",
    commande_numero: "Commande n° ",
    capital_sasu: "SASU au capital social de 7 000,00 €",
    rcs_infos: "RCS EVRY B 843 119 728 ",
    rcs_infos_new: "RCS PARIS B 843 119 728 ",
    siret: " SIRET 84311972800023",
    siret_new: " SIRET 84311972800031",
    tva_fr: "N° de TVA : FR00843119728",
    condition_paragraphe_1: "Pénalités de retard (taux annuel): 10%",
    condition_paragraphe_2: "Pas d'escompte en cas de paiement anticipé",
    condition_paragraphe_3:
      "Indemnité forfaitaire pour frais de recouvrement en cas de retard de paiement : 40€",
    prestataire: "Prestataire",
    my_info: "Mes informations",
    geolocation_paragraphe:
      "Le logiciel a besoin de connaître votre position pour rechercher les traducteurs et interprètes les plus proches de vous avec plus de précision.",
    update_password: "Modifier mon mot de passe",
    account_upt:
      "Si vous le souhaitez, vous pouvez modifier votre mot de passe",
    send: "Envoyer",
    close_modal: "Fermer",
    inscription_date: "Inscrit depuis le ",
    order_translate: "du ",
    montant_total_euro: "Montant total en euros",
    rules_conditions: "Conditions de règlement",
    rules_conditions_p: "Paiement à la réception de la facture",
    section_agency_title: "Mon entreprise",
    section_account_title: "Mon profil",
    section_support_title: "Support",
    notifications_new_task: "Vous avez une nouvelle mission !",
    notifications_task_confirmed:
      "La mission a été confirmée. Vous pouvez débuter la traduction.",
    notifications_task_validated:
      "La mission vient d'être validée par le client",
    tasks_sent_on: "Envoyé le",
    tasks_src_country: "Pays d'origine",
    tasks_dst_country: "Traduire vers",
    tasks_state: "Progression",
    state_cancelled: "Annulé par le client",
    state_waiting_for_translator: "Nouveau",
    state_waiting_for_payment: "En attente de paiement",
    state_refused_by_translator: "Mission refusée",
    state_translating: "En cours de traduction",
    state_waiting_for_client_validation:
      "Finie. En attente de validation par le client.",
    state_validated: "Validée par le client",
    state_validated_pro: "Validé",
    prestation_order_from: "De",
    prestation_order_document_type: "Type de document",
    prestation_order_messaging_title: "Messagerie",
    prestation_order_messaging_send: "Envoyé",
    prestation_order_messaging_client: "Client :",
    prestation_order_messaging_me: "Moi :",
    prestation_document_edit: "Éditeur de document",
    prestation_document_edit_expl:
      "Le texte dans le champ ci-dessus est souligné dans le document ci-dessous. En saisissant le text traduit dans le champ, le document sera automatiquement mis à jour. Utilisez les flèches pour à travers le document.",
    prestation_document_get_browser:
      "Vous ne voyez pas le PDF ? Veuillez utiliser un navigateur récent comme",
    prestation_document_confirm_oui: "Oui",
    prestation_document_confirm_non: "Non",
    account_save: "Enregistrer",
    account_surname: "Nom",
    account_surname_placeholder: "",
    account_name: "Prénom",
    account_name_placeholder: "",
    account_telephone: "Téléphone",
    account_telephone_placeholder: "",
    account_adresse: "Adresse",
    account_adresse_placeholder: "Adresse",
    account_complement_adr: "Complément d’adresse",
    account_complement_adr_placeholder: "Complément d’adresse",
    account_email: "E-mail",
    account_email_placeholder: "e-mail",
    account_password: "Mot de passe actuel",
    account_new_password: "Nouveau mot de passe",
    account_error_required: "Champ obligatoire",
    agency_expl_1_title: "Raison sociale",
    agency_expl_1_expl:
      "Précisez le nom de votre entreprise afin que vos clients puissent vous retrouver.",
    agency_expl_2_title: "Géolocalisation",
    agency_expl_2_expl:
      "L'application nécessite vos coordonnées afin de vous mettre en relation avec les clients les plus proches de vous.",
    agency_expl_3_title: "Activation du compte",
    agency_expl_3_expl:
      "Votre profil sera visible en ligne une fois la sauvegarde des informations effectuée.",
    services_dst_lang: "Vers",
    support_title: "Nos locaux",
    support_company: "Hiero",
    support_addr1: "7 Rue Montespan",
    support_addr1_paris: "83 boulevard de Magenta",
    support_addr2: "91000 Évry-Courcouronnes",
    support_addr2_paris: "75010 Paris",
    support_addr3: "France",
    support_contact: "Contact",
    support_tel: "Tel : (+33) 01 76 38 01 40",
    support_email: "contact@hiero-solution.com",
    not_found_title: "Élement introuvable",
    not_found_subtitle: "La page que vous avez demandé n'existe pas",
    not_found_action: "Contactez-nous si ",
    not_found_home: "Accueil",
    menu_label_sur_place: "Sur place",
    menu_label_visio: "Par visioconférence",
    consulter_facture: "Consulter mes factures",
    facture_numero: "Facture n°",
    recap_tva: "RÉCAPITULATIF DE LA TVA",
    recap_taux: "TAUX",
    recap_mt_tva: "MONTANT TVA",
    recap_base_ht: "BASE HT",
    action: "Action",
    hours: "Heures",
    join_room: "Rejoindre la salle",
    rooms: "Les salles (Rooms)",
    warning: "Attention ! ",
    conf: "La conférence",
    warning_1: "Il faut respecter l'orthographe du nom des salles.",
    interpretation_info: "Explication",
    interpretation_paragraphe_1:
      "Pour participer à la mission d'interprétariat, il vous faut au minimum deux onglets. Dans le premier, vous serez avec l'interprète, dans le second avec tous les participants.",
    interpretation_paragraphe_2:
      "Pour accéder à la salle de conférence, le nom de la salle (Room) à indiquer correspond à ",
    interpretation_paragraphe_3:
      ". C’est là où se trouvent tous les participants.",
    interpretation_paragraphe_4:
      "Pour accéder à la salle d'interprétariat, le nom de la salle (Room) à indiquer correspond à ",
    interpretation_paragraphe_5:
      ". C’est là où se trouvent vos intervenants et l'interprète choisi. ",
    interpretation_paragraphe_6:
      "Pour réaliser correctement la mission d'interprétariat à distance, il est nécessaire que l'intervenant et l'interprète ne parlent pas en même temps dans la même salle.",
    interpretation_paragraphe_7:
      "C'est pourquoi vous êtes dans deux salles différentes. Ainsi, les participants n'entendront que vous dans la salle de conférence et vous n'entendrez que les intervenants dans la salle d'interprétation.",
    interpretation_paragraphe_8:
      "Pour des raisons de performance, la capacité maximale d'une salle est de 50 participants. Vous pouvez créer autant de salles correspondant à votre audience que vous le souhaitez et communiquer les noms de ces salles à votre interprète.",
    room_conf: " Salle de conférence",
    traduction_assermentee: "Traduction assermentée",
    traduction_classic: "Traduction classique",
    interpretation: "Interprétariat",
    AF: "Afghanistan",
    ZA: "Afrique du Sud",
    AL: "Albanie",
    DZ: "Algérie",
    DE: "Allemagne",
    AD: "Andorre",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctique",
    AG: "Antigua-et-Barbuda",
    AN: "Antilles néerlandaises",
    SA: "Arabie saoudite",
    AR: "Argentine",
    AM: "Arménie",
    AW: "Aruba",
    AU: "Australie",
    AT: "Autriche",
    AZ: "Azerbaïdjan",
    BS: "Bahamas",
    BH: "Bahreïn",
    BD: "Bangladesh",
    BB: "Barbade",
    BY: "Bélarus",
    BE: "Belgique",
    BZ: "Belize",
    BJ: "Bénin",
    BM: "Bermudes",
    BT: "Bhoutan",
    BO: "Bolivie",
    BA: "Bosnie-Herzégovine",
    BW: "Botswana",
    BR: "Brésil",
    BN: "Brunéi Darussalam",
    BG: "Bulgarie",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodge",
    CM: "Cameroun",
    CA: "Canada",
    CV: "Cap-Vert",
    EA: "Ceuta et Melilla",
    CL: "Chili",
    CN: "Chine",
    CY: "Chypre",
    CO: "Colombie",
    KM: "Comores",
    CG: "Congo-Brazzaville",
    KP: "Corée du Nord",
    KR: "Corée du Sud",
    CR: "Costa Rica",
    CI: "Côte d’Ivoire",
    HR: "Croatie",
    CU: "Cuba",
    DK: "Danemark",
    DG: "Diego Garcia",
    DJ: "Djibouti",
    DM: "Dominique",
    EG: "Égypte",
    SV: "El Salvador",
    AE: "Émirats arabes unis",
    EC: "Équateur",
    ER: "Érythrée",
    ES: "Espagne",
    EE: "Estonie",
    VA: "État de la Cité du Vatican",
    FM: "États fédérés de Micronésie",
    US: "États-Unis",
    ET: "Éthiopie",
    FJ: "Fidji",
    FI: "Finlande",
    FR: "France",
    GA: "Gabon",
    GM: "Gambie",
    GE: "Géorgie",
    GS: "Géorgie du Sud et les îles Sandwich du Sud",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Grèce",
    GD: "Grenade",
    GL: "Groenland",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernesey",
    GN: "Guinée",
    GQ: "Guinée équatoriale",
    GW: "Guinée-Bissau",
    GY: "Guyana",
    GF: "Guyane française",
    HT: "Haïti",
    HN: "Honduras",
    HU: "Hongrie",
    BV: "Île Bouvet",
    CX: "Île Christmas",
    CP: "Île Clipperton",
    AC: "Île de l'Ascension",
    IM: "Île de Man",
    NF: "Île Norfolk",
    AX: "Îles Åland",
    KY: "Îles Caïmans",
    IC: "Îles Canaries",
    CC: "Îles Cocos - Keeling",
    CK: "Îles Cook",
    FO: "Îles Féroé",
    HM: "Îles Heard et MacDonald",
    FK: "Îles Malouines",
    MP: "Îles Mariannes du Nord",
    MH: "Îles Marshall",
    UM: "Îles Mineures Éloignées des États-Unis",
    SB: "Îles Salomon",
    TC: "Îles Turks et Caïques",
    VG: "Îles Vierges britanniques",
    VI: "Îles Vierges des États-Unis",
    IN: "Inde",
    ID: "Indonésie",
    IQ: "Irak",
    IR: "Iran",
    IE: "Irlande",
    IS: "Islande",
    IL: "Israël",
    IT: "Italie",
    JM: "Jamaïque",
    JP: "Japon",
    JE: "Jersey",
    JO: "Jordanie",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KG: "Kirghizistan",
    KI: "Kiribati",
    KW: "Koweït",
    LA: "Laos",
    LS: "Lesotho",
    LV: "Lettonie",
    LB: "Liban",
    LR: "Libéria",
    LY: "Libye",
    LI: "Liechtenstein",
    LT: "Lituanie",
    LU: "Luxembourg",
    MK: "Macédoine",
    MG: "Madagascar",
    MY: "Malaisie",
    MW: "Malawi",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malte",
    MA: "Maroc",
    MQ: "Martinique",
    MU: "Maurice",
    MR: "Mauritanie",
    YT: "Mayotte",
    MX: "Mexique",
    MD: "Moldavie",
    MC: "Monaco",
    MN: "Mongolie",
    ME: "Monténégro",
    MS: "Montserrat",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibie",
    NR: "Nauru",
    NP: "Népal",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigéria",
    NU: "Niue",
    NO: "Norvège",
    NC: "Nouvelle-Calédonie",
    NZ: "Nouvelle-Zélande",
    OM: "Oman",
    UG: "Ouganda",
    UZ: "Ouzbékistan",
    PK: "Pakistan",
    PW: "Palaos",
    PA: "Panama",
    PG: "Papouasie-Nouvelle-Guinée",
    PY: "Paraguay",
    NL: "Pays-Bas",
    PE: "Pérou",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Pologne",
    PF: "Polynésie française",
    PR: "Porto Rico",
    PT: "Portugal",
    QA: "Qatar",
    HK: "R.A.S. chinoise de Hong Kong",
    MO: "R.A.S. chinoise de Macao",
    QO: "Régions éloignées de l’Océanie",
    CF: "République centrafricaine",
    CD: "République démocratique du Congo",
    DO: "République dominicaine",
    CZ: "République tchèque",
    RE: "Réunion",
    RO: "Roumanie",
    GB: "Royaume-Uni",
    RU: "Russie",
    RW: "Rwanda",
    EH: "Sahara occidental",
    BL: "Saint-Barthélemy",
    KN: "Saint-Kitts-et-Nevis",
    SM: "Saint-Marin",
    MF: "Saint-Martin",
    PM: "Saint-Pierre-et-Miquelon",
    VC: "Saint-Vincent-et-les Grenadines",
    SH: "Sainte-Hélène",
    LC: "Sainte-Lucie",
    WS: "Samoa",
    AS: "Samoa américaines",
    ST: "Sao Tomé-et-Principe",
    SN: "Sénégal",
    RS: "Serbie",
    CS: "Serbie-et-Monténégro",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapour",
    SK: "Slovaquie",
    SI: "Slovénie",
    SO: "Somalie",
    SD: "Soudan",
    LK: "Sri Lanka",
    SE: "Suède",
    CH: "Suisse",
    SR: "Suriname",
    SJ: "Svalbard et Île Jan Mayen",
    SZ: "Swaziland",
    SY: "Syrie",
    TJ: "Tadjikistan",
    TW: "Taïwan",
    TZ: "Tanzanie",
    TD: "Tchad",
    TF: "Terres australes et antarctiques françaises",
    IO: "Territoire britannique de l'océan Indien",
    PS: "Territoire palestinien",
    TH: "Thaïlande",
    TL: "Timor oriental",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinité-et-Tobago",
    TA: "Tristan da Cunha",
    TN: "Tunisie",
    TM: "Turkménistan",
    TR: "Turquie",
    TV: "Tuvalu",
    UA: "Ukraine",
    EU: "Union européenne",
    UY: "Uruguay",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Viêt Nam",
    WF: "Wallis-et-Futuna",
    YE: "Yémen",
    ZM: "Zambie",
    ZW: "Zimbabwe",
    /************ fin du bloc pour les phrases utilisées sur les 3 solutions ***********/

    /******************** Bloc pour les phrases utilisées null part ********************/
    order_asser_or_not: "S'agit-il d'une traduction assermentée ?",
    dashboard_pro_command_company_priceHT: "Prix HT:",
    new_orders: "Passer une nouvelle commande",
    state_pro_wait: "En attente",
    account_expl_pro_cheque: "Merci d'adresser un chèque à Hiero",
    account_expl_pro_viremement: "Virements ",
    account_expl_pro_iban: "IBAN FR 000",
    account_hist_pro_debit: "Débit ",
    account_hist_pro_credit: "Crédit ",
    account_hist_pro_way: "Moyen ",
    account_hist_pro_ref: "Référence ",
    agency_pro_budget_help:
      "Le budget que vous souhaitez allouer à vos traductions.",
    sub_training: "Formation d'équipe ",
    sub_search: "Recherche automatique de traducteur / interprète ",
    sub_geo: "Géolocalisation du traducteur et/ou de l'interprète ",
    sub_dem: "Dématérialisation de toutes les commandes ",
    sub_save: "Sauvegarde des documents avec gestionnaire de fichiers ",
    sub_recap: "Récapitulatif des commandes au mois ",
    sub_proj: "Evolution des projets en temps réel ",
    sub_management: "Gestion de budget en temps réel ",
    sub_message: "Messagerie automatique",
    sub_send: "Livraison de vos traductions par courrier ",
    sub_impl: "Implantation du budget voté sur 12 mois ",
    sub_account: "Account manager à vos côtés ",
    sub_orientation:
      "Orientation personnalisée des langues les plus demandées ",
    sub_split:
      "Division du budget en plusieurs départements, selon les utilisateurs",
    sub_event: "Enlèvement par coursier ",
    choose: "Choisir",
    sub_function: "Fonctionnalités",
    sub_standard: "Standard",
    sub_essen: "Essentiel",
    sub_premium: "Premium",
    sub_month: "mois",
    sub_associations_label: "Associations",
    sub_cabinet_label: "Cabinets d'avocats",
    sub_bank_label: "Mobilité internationale",
    sub_academia_label: "Etablissement academiques",
    sub_who_are_you: "Vous êtes ?",
    sub_explanation_academia:
      "Nous préférons travailler sous forme de partenariats avec les établissements académiques, type universités, grandes écoles ...",
    sub_explanation_systeme: "2 systèmes sont disponibles: ",
    sub_system_one:
      "L'utilisation de l'application mobile, s'ils ne sont pas conventionnés.",
    sub_system_two:
      "La négociation d'une mise en place de l'espace professionnel s'ils sont conventionnés.",
    sub_credit_card_message:
      "Activer les services en payant votre abonnement. Dans l'onglet ",
    validate_credit_card_message: "Votre commande a été prise en compte",
    subscriber_choose: "Choisir un abonnement",
    find_out_more: "En savoir plus",
    menu_label_relecture: "Relecture",
    signup_error_email_not_found: "L'adresse mail n'existe pas.",
    main_left_menu_section_accueil: "Accueil",
    agency_setup_email_help_pro:
      "L'adresse mail à utiliser pour toute correspondance avec nos traducteurs et l'équipe Hiero.",
    main_menu_left_section_navigation_entreprise: "Mon entreprise",
    main_menu_left_section_navigation_messagerie: "Service de messagerie",
    main_menu_left_section_navigation_gestion_des_fichiers:
      "Gestion des fichiers",
    montant_total: "Montant total",
    single_commande_p1:
      "Le traducteur télécharge le document pour le traduire ",
    single_commande_p2:
      "Une fois , il l'ajoute à cette commande pour que vous puissiez valider la traduction et confirmer la commande.",
    load_intervention: "Intervention en cours",
    commande_statut: "Statut de votre commande",
    commande_details: "Détails de la commande",
    trad_name: "Nom du traducteur",
    visioner: "Visioner",
    infos_interv: "Informations supplémentaires sur l'intervention",
    intervention_time_date: "Date et heure de l'intervention",
    loading_order: "Mes demandes de traduction en cours",
    view_all_orders: "Voir toutes les commandes",
    last_payment: "Mes derniers paiments effectués",
    view_all_payment: "Voir toutes mes paiements",
    last_send_msg: "Derniers messages envoyés",
    state_accept_order: "Commande acceptée",
    account_expl:
      "Veuillez renseigner vos coordonnées, pour vous contacter si necessaire.",
    account_surname_help: "",
    account_name_help: "",
    account_telephone_help: "",
    account_poste_help: "",
    account_adresse_help: "",
    account_complement_adr_help: "",
    account_email_help: "",
    account_password_help: "",
    account_new_password_help: "",
    not_found_action1: "Le problème persiste. ",
    menu_label_interpretation: "Demander une prestation d'interprétariat",
    table_header_statut: "Statut",
    head_professionnal:
      "Le logiciel a besoin de connaitre votre position pour rechercher les traducteurs les plus proches de vous avec plus de précision",
    head_change_password:
      "Si vous le souhaitez, vous pouvez changer votre mot de passe",
    recap_tva_fr: "20% TVA FR",
    client_particulier_name: "Raison sociale",
    client_particulier_email: "E-mail",
    client_particulier_address: "Adresse ",
    client_particulier_code_postal: "Ville",
    particulier_name: "Nom du particulier",
    infos_entrep: "",
    infos_commandes: "",
    address_facturation: "Modifier mon addresse de facturation",
    /***************** fin du bloc pour les phrases utilisées null part ****************/
  },
  en: {
    /***************** Bloc pour les phrases utilisées que sur le trad *****************/
    account_asser: "Are you sworn/certified?",
    agency_companyname_help:
      "If you are not a professional, please enter your chosen name. Only fill in the Company Registration Number if you are a company.",
    agency_contact_help:
      "Your email and phone number are required to chat with your customers and receive notifications.",
    parrainer_client: "Sponsor my clients",
    parrainage_paragraph:
      "Do you want to centralize all your projects, from all your clients? Sponsor them to use HIERØ, and you'll receive all their orders on your account.",
    btn_parrainage: "Send an invitation",
    account_parrainage_success:
      "Your sponsoring email has been successfully sent.",
    account_parrainage_failed: "The email could not be sent.",
    account_parrainage_email: "Invite a client",
    consult_missions: "View my assignments",
    prestation_accept_order:
      "After accepting the order, the customer will proceed to payment. Once the payment has been made, you can start the translation.",
    prestation_reject_order:
      "If you refuse, our software will automatically submit the order to another translator.",
    intervention_next_action_accept_order: "Accept the intervention",
    intervention_next_action_reject_order: "Refuse the intervention",
    intervention_next_action_start_order: "Begin the intervention",
    intervention_order_details: "Details of the intervention",
    intervention_order_for: "Due on",
    intervention_address: "Location of the intervention (address)",
    intervention_info_supp: "Additional information about the intervention",
    agency_setup_tva: "Are you subject to VAT?",
    agency_setup_tva_placeholder: "",
    agency_setup_tva_number: "VAT number",
    agency_setup_tva_number_placeholder: "",
    agency_setup_tva_number_help:
      "If you are subject to VAT, please enter your VAT number.",
    dashboard_turnover: "My turnover",
    dashboard_evolution_month: "Evolution during the month",
    dashboard_trad_month: "Translations this month",
    dashboard_interpretation_month: "Interpretations this month",
    dashboard_new_missions: "New assignments",
    dashboard_my_missions: "My current assignments",
    dashboard_command_date: "Order date:",
    dashboard_type: "Type:",
    dashboard_total_sells: "Total sales since registration",
    dashboard_doc_type: "Type of documents most translated",
    dashboard_my_clients: "My recurring customers",
    dashboard_trad: "Translation",
    dashboard_interpretation: "Interpretation",
    dashboard_particulier: "Private individuals",
    dashboard_pro: "Professional",
    dashboard_prestation_lang: "My services by language",
    dashboard_evolution_year: "Evolution over the current year",
    dashboard_status: "Status :",
    agree: "By clicking, you agree to",
    our_term: "our terms and conditions ",
    and_the: "and the",
    stripe_agreement: "Stripe Connected Account Agreement. ",
    created_at: "Created on ",
    updated_at: "Updated on ",
    services_none_yet_subscribe: "Subscribe to activate your services",
    asser_placeholder: "Are you a sworn/certified translator?",
    agency_expl_4_expl:
      "To use our services, we need to confirm you are indeed a sworn/certified translator.",
    agency_expl_4_expl_1: "You can send any supporting documents by ",
    agency_expl_4_expl_2:
      "Should you have any questions regarding this procedure, please contact us.",
    label_assermentation: "Sworn/certified translation",
    login_main_title: "Translator Account",
    login_main_subtitle:
      "Access your assignments and the document editor using the Hierø translator account!",
    signup_caption_title: "Hierø - Translator Account",
    signup_main_subtitle:
      "Create your account, join our interface and become more visible to our users!",
    main_header_subtitle: "Translator Account",
    main_left_menu_title: "translator",
    main_left_menu_section_navigation_missions: "My assignments",
    main_left_menu_section_parameters_subscription: "My subscription",
    agency_setup_title: "About you",
    order_translate_to: "to ",
    section_services_title: "My services",
    section_tasks_title: "My assignments",
    section_task_title: "Task",
    section_document_title: "Translate a document",
    tasks_src_lang: "Original language",
    prestation_breadcrumb_tasks: "My assignments",
    prestation_order_number: "Order number:",
    prestation_next_action_title: "Next steps",
    prestation_next_action_accept_order: "Accept the order",
    prestation_next_action_accept_order_expl:
      "Click here to accept the order. The client will then proceed with the payment. Once the payment has been completed, you can start the translation.",
    prestation_next_action_reject_order: "Refuse the order",
    prestation_next_action_reject_order_expl: "Click here to refuse the order.",
    prestation_next_action_rejected: "You refused the order.",
    prestation_next_action_waiting_for_payment:
      "Waiting. The client is proceeding with the payment.",
    prestation_next_action_translate_expl:
      "Please proceed with the translation of each document in this order by clicking on the 'Translate' button. When all documents are ready, click on the button 'Send to client'. For any questions or details, you can chat directly with the client through our messaging service.",
    prestation_next_action_translate_all_done_expl:
      "You have finished all the documents in this order.",
    prestation_next_action_translate_confirm: "Send to client for verification",
    prestation_next_action_waiting_for_validation:
      "The client is checking the translation.",
    prestation_next_action_validated:
      "The translation was verified by the client.",
    prestation_order_details: "Order details",
    prestation_order_to: "to",
    prestation_order_country_origin: "Country of origin :",
    prestation_order_document_count: "Documents to translate :",
    prestation_order_price: "Price (excl. VAT) :",
    prestation_order_date: "Order created on :",
    prestation_order_documents_title: "Documents to translate",
    prestation_order_document_originals: "Originals",
    prestation_order_document_translated: "Translated",
    prestation_order_document_not_yet_translated: "No translation yet!",
    prestation_order_document_translate: "Translate!",
    prestation_order_confirm_ok: "OK",
    prestation_order_confirm_cancel: "Cancel",
    prestation_order_validate_title: "Confirm",
    prestation_order_validate_msg:
      "Are you sure you want to finalise this order? The client will receive a notification to access to the the translated documents.",
    prestation_order_accept_title: "Confirm",
    prestation_order_accept_msg: "Are you sure you want to accept this order?",
    prestation_order_refuse_title: "Confirm",
    prestation_order_refuse_msg: "Are you sure you want to refuse this order?",
    prestation_document_validate: "Finalize and save!",
    prestation_document_show_original: "Show the original in another tab:",
    prestation_document_button_reset: "Reset the document",
    prestation_document_confirm_title: "Confirmation",
    prestation_document_confirm_message:
      "Are you sure you want to reset this document? You will lose all your changes!",
    services_add_button: "Add a new service...",
    services_none_yet:
      "To be visible in the Hierø app, you need to specify what kind of translations you provide.",
    services_add_expl:
      "Please select the language you can translate from and to, then click 'Add'.",
    services_add_src_lang: "Original language",
    services_add: "Add!",
    services_error_add:
      "This combination of languages is not yet supported by Hierø. Please contact us for more information!",
    services_dont_see_title: "I can't see my languages.",
    services_dont_see_response:
      "Hierø simplifies your translations by providing ready-to-use templates for specific documents. We are continually adding languages to our database, so do not hesitate to contact us at <a href='mailto:contact@hiero-solution.com'>contact@hiero-solution.com</a> to add more languages.",
    services_list_title: "Your list of services",
    services_expl:
      "Click on each service in order to customize the documents you will accept. You can either choose not to handle certain document types by disabling the toggle, or you can modify the amount you charge for that document by filling in the field for that document type.",
    services_list_active: "Enabled",
    services_list_country: "Country",
    services_list_document_type: "Document type",
    services_list_average_price: "Average price (excl. VAT)",
    services_list_your_price: "Your price (excl. VAT)",
    services_list_your_price_reset: "Reset",
    services_list_delete: "Remove",
    temps_ex: "Estimated time",
    question_inter: "Are you an interpreter?",
    question_inter_price: "Rate per hour:",
    question_inter_1:
      "You agree to carry out interpretation missions on our software for",
    calendar: "Schedule",
    calendar_hidden: "Hide my schedule",
    interpretation_label_menu: "Interpretation",
    interpretation_label: "Interpretation",
    room_inter: "Interpretation room",
    once_page_price: "Rate per page",
    once_page_information:
      "Please provide your rate for the translation of one page (250 words). This rate will be presented to the client if the document he wishes to translate isn't available on the software.",
    facture_pro: "Professional",
    /************** fin du bloc pour les phrases utilisées que sur le trad *************/

    /**************** Bloc pour les phrases utilisées sur le pro et trad ***************/
    agency_name_help:
      "If there is an issue, it'll be easier for the team to find you with your first and last name.",
    menu_label_tel: "By phone",
    section_numero_commande: "Order No.",
    dashboard_traductor_asser: "Sworn / certified translation",
    dashboard_traductor_classic: "Regular translation",
    dashboard_month_janv: "Jan",
    dashboard_month_fevr: "Feb",
    dashboard_month_mars: "Mar",
    dashboard_month_avr: "Apr",
    dashboard_month_mai: "May",
    dashboard_month_juin: "June",
    dashboard_month_juill: "July",
    dashboard_month_aout: "Aug",
    dashboard_month_sept: "Sept",
    dashboard_month_oct: "Oct",
    dashboard_month_nov: "Nov",
    dashboard_month_dec: "Dec",
    sub_subscription: "Subscription",
    main_header_title: "Hierø -",
    main_left_menu_section_navigation: "Menu",
    main_left_menu_section_parameters: "Configuration",
    agency_setup_explanation:
      "Hierø offers your services to clients close to your offices. <br>Please provide the name of your company and your address.<br>This information will be provided to clients while searching for a translator.",
    section_prestation: "Service",
    delai_intervention: "Duration of the intervention",
    type_doc_commande: "Type of document requested",
    price_total_ht: "Total (excl. VAT)",
    section_dashboard_title: "Dashboard",
    tasks_price: "Price (excl. VAT)",
    support_tel_es: "Tel (es) : (+34) 911 23 88 87",
    support_tel_en: "Tel (en) : (+1) 929 274 5104",
    type_intervention: "Type of intervention required",
    once_page_price_default: "Default rate (excl. VAT)",
    client: "Customer",
    /************ fin du bloc pour les phrases utilisées sur le pro et trad ************/

    /************ Bloc pour les phrases utilisées sur le trad et particulier ***********/
    modal_supp_title: "Confirm the deletion of your account",
    facture_part: "Private individuals",
    tasks_filters: "Filters",
    /******** fin du bloc pour les phrases utilisées sur le trad et particulier ********/

    /*************** Bloc pour les phrases utilisées sur les 3 solutions ***************/
    menu_label_event_name: "Purpose of the intervention",
    modif_profile_success_pwd: "Your password has been successfully updated.",
    modif_profile_failed_pwd:
      "An error occurred while changing your password. Please check that you have entered the correct password.",
    modal_modif_title: "Confirm email change",
    modif_profile_failed_login: "Wrong email address or password. Try again.",
    augmente_budget:
      "To increase your balance, you can send us a check or make a transfer.",
    no_budget: "You don't have a budget for now",
    validation_mail_registration:
      "Welcome to HIERO! To start using our services, you must first confirm your email address.",
    validation_modif_mail:
      "Your email address has been successfully changed. To use our services, you must confirm your email address again.",
    validation_question:
      "Didn't receive the confirmation email? Click the button to send the confirmation email again.",
    validation_mail_resend: "Send confirmation email again",
    message_verification:
      "Please confirm your email address. To do so, please check your inbox.",
    non_valid_address: "Email address not confirmed",
    validation_mail_success: "Confirmation email successfully sent.",
    account_error_invalid_email: "The email you provided is not valid.",
    account_error_min_password: "8 characters minimum",
    account_error_passwords_do_not_match: "Your passwords do not match",
    account_confirm: "Confirm your password",
    modif_profile_success: "Your profile has been successfully updated",
    modif_profile_failed:
      "An error has occurred while updating your profile. Please check the information and try again.",
    facture_echeance: "Due on",
    facture_conditions: "Payment",
    facture_comptant: "Upfront",
    facture_adress: "Billing Address",
    facture_qte: "Qty",
    facture_price: "Unit",
    facture_unitaire: "Price",
    facture_montant: "Amount",
    facute_solde: "Total due",
    facture_tva_fr: "French VAT 20%",
    order_language_from: "From",
    order_language_to: "To",
    account_hist_pro_date: "Date of Intervention",
    select_choose: "Choose...",
    tasks_price_tva: "VAT",
    order_no_asser_document_name: "Name of the document",
    menu_label_traductor_asser: "Sworn/certified translation",
    menu_label_traductor_classic: "Regular translation",
    menu_label_interprestation: "Interpretation",
    menu_label_all: "Show all",
    login_caption_title: "Hierø - Translator Account",
    login_caption_subtitle:
      "A software designed by translators for translators.",
    login_main_logo: "Hierø",
    login_main_connect: "Log in",
    login_main_email: "Email",
    login_main_email_placeholder: "Enter your email address",
    login_main_email_help: "Use your email address to log in.",
    login_main_password: "Password",
    login_main_password_placeholder: "Please enter your password.",
    login_main_password_help: "",
    login_main_button: "Log in",
    login_main_to_signup: "Do not have an account? Sign up here!",
    login_main_copyright: "© Hierø - HIERØ 2022",
    login_error_required_field: "Required field",
    login_error_invalid_email: "The email you provided is not valid.",
    login_error_email_not_found: "This email is not registered.",
    signup_caption_subtitle: "Choose Hierø and increase your visibility!",
    signup_main_logo: "Hierø",
    signup_main_title: "Sign up",
    signup_main_email: "Email",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Password",
    signup_main_password_placeholder: "Password",
    signup_main_password_help: "",
    signup_main_confirm: "Confirm your password",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Surname",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Name",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Phone",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "Sign up!",
    signup_main_to_signup: "Already have an account? Log in",
    signup_main_copyright: "© Hierø - HIERØ 2022",
    signup_error_required_field: "Required field",
    signup_error_invalid_email: "The email you provided is not valid.",
    signup_error_min_password: "8 characters minimum",
    signup_error_max_password: "30 characters maximum",
    signup_error_passwords_do_not_match: "Your passwords do not match",
    login_forgot_password: "Forgot your password?",
    reset_password_main: "Reset your password",
    reset_password_email_title: "Email",
    reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Send",
    reset_password_login: "Log in",
    reset_password_success:
      "Email sent successfully. Please check your email and click the link in order to set a new password.",
    main_left_menu_section_parameters_agency: "My company",
    main_left_menu_section_parameters_services: "My services",
    main_left_menu_section_parameters_user: "My account",
    main_left_menu_section_parameters_support: "Assistance",
    reset_password_error_required: "Required field.",
    reset_password_error_invalid_email: "The email you provided is not valid.",
    reset_password_error_user_not_found: "This email is not registered.",
    main_header_user_menu_logout: "Log out",
    agency_setup_companyname: "Company name",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help:
      "The name of the company that will be shown in orders, invoices, etc.",
    agency_setup_address: "Address",
    agency_setup_siret: "Company Registration Number",
    agency_setup_siret_placeholder: "",
    agency_setup_siret_help:
      "Enter your SIRET number (or Company Registration Number) if you are a business.",
    agency_setup_address_placeholder: "",
    agency_setup_address_help:
      "Start typing your address, then select the correct one in the dropdown menu.",
    agency_setup_addresscompl: "Additional address information",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help:
      "Building, staircase, code, etc. When your clients pick up their documents, they must have all the necessary information.",
    agency_setup_tel: "Phone number",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help:
      "The phone number to use to discuss with your clients.",
    agency_setup_email: "Email",
    agency_setup_email_placeholder: "",
    agency_setup_email_help:
      "The email address to use when corresponding with your clients. Please indicate a new email, should it not be your Hierø registration email.",
    agency_setup_save: "Save",
    agency_setup_error_required_field: "Required field",
    agency_setup_error_invalid_email: "The email you provided is not valid.",
    agency_setup_error_valid_address:
      "You must select a valid address from the dropdown menu.",
    agency_address_not_found: "Your address was not found?",
    agency_address_title: "Manually enter your details",
    agency_address_address: "Address:",
    agency_address_number: "No.",
    agency_address_street: "Street",
    agency_address_code: "Postal code",
    agency_address_city: "City",
    agency_address_country: "Country",
    agency_address_coords: "GPS coordinates:",
    agency_address_coords_lat: "Latitude",
    agency_address_coords_long: "Longitude",
    agency_address_cancel: "Cancel",
    agency_address_validate: "Continue",
    main_left_menu_section_navigation_dashboard: "Dashboard",
    main_menu_left_section_navigation_factures: "My invoices",
    head_facture: "Invoice",
    section_date: "Date",
    section_numero_facture: "Invoice number",
    section_type_prestation: "Type of service",
    section_price: "Total",
    section_print: "Print",
    section_generate_facture: "Generate the invoice in PDF",
    section_consulter: "View",
    prestation: "Service",
    commande_qte: "Quantity",
    commande_produit: "Product",
    commande_desc: "Description",
    total_ht: "Total excl. VAT",
    total_tva: "Total VAT",
    total_ttc: "Total incl. VAT",
    retour_btn: "Go back",
    progress_commande: "Order progression",
    commande_numero: "Order no.",
    capital_sasu: "SASU with a share capital of €7,000",
    rcs_infos: "Trade and Companies Register (RCS) of EVRY B 843 119 728 ",
    rcs_infos_new: "Trade and Companies Register (RCS) of PARIS B 843 119 728 ",
    siret: " Company Registration Number 84311972800023",
    siret_new: " Company Registration Number 84311972800031",
    tva_fr: "VAT number: FR00843119728",
    condition_paragraphe_1: "Late-payment penalties (annual rate): 10%",
    condition_paragraphe_2: "No discount for early payment",
    condition_paragraphe_3:
      "Standard payment rate for recovery fees in the ent of late payment: €40",
    prestataire: "Service provider",
    my_info: "My details",
    geolocation_paragraphe:
      "The software needs to know your location so it can search accurately the closest translator to take your order. ",
    update_password: "Change my password ",
    account_upt: "You can change your password as you wish ",
    send: "Send",
    close_modal: "Close",
    inscription_date: "Registration date:",
    order_translate: "from",
    montant_total_euro: "Total amount in EUR",
    rules_conditions: "TERMS OF PAYMENT",
    rules_conditions_p: "Payment due upon receipt of the invoice",
    section_agency_title: "My company",
    section_account_title: "My account",
    section_support_title: "Assistance",
    notifications_new_task: "You have a new assignment!",
    notifications_task_confirmed:
      "Your assignment has just been confirmed. You can start translating.",
    notifications_task_validated: "Your client validated the assignment.",
    tasks_sent_on: "Sent on",
    tasks_src_country: "Original country",
    tasks_dst_country: "Translate to",
    tasks_state: "Order progress",
    state_cancelled: "Canceled by the client",
    state_waiting_for_translator: "New!",
    state_waiting_for_payment: "Waiting for payment",
    state_refused_by_translator: "Mission refused",
    state_translating: "Translation in progress",
    state_waiting_for_client_validation: "Done, waiting for client approval",
    state_validated: "Approved by client",
    state_validated_pro: "Validated",
    prestation_order_from: "From",
    prestation_order_document_type: "Document type",
    prestation_order_messaging_title: "Chat",
    prestation_order_messaging_send: "Send",
    prestation_order_messaging_client: "Client:",
    prestation_order_messaging_me: "Me:",
    prestation_document_edit: "Document editor",
    prestation_document_edit_expl:
      "The text in the field above is highlighted in the document below. When you modify the text above, the document will automatically be updated. Use the arrows to navigate between phrases in the document.",
    prestation_document_get_browser:
      "You do not see the translated PDF? Please use an up-to-date browser like",
    prestation_document_confirm_oui: "Yes",
    prestation_document_confirm_non: "No",
    account_save: "Save",
    account_surname: "Surname",
    account_surname_placeholder: "",
    account_name: "Name",
    account_name_placeholder: "",
    account_telephone: "Phone number",
    account_telephone_placeholder: "",
    account_adresse: "Address",
    account_adresse_placeholder: "Address",
    account_complement_adr: "Additional address information",
    account_complement_adr_placeholder: "Additional address information",
    account_email: "Email",
    account_email_placeholder: "Email",
    account_password: "Current password",
    account_new_password: "New password",
    account_error_required: "Required",
    agency_expl_1_title: "Business name",
    agency_expl_1_expl:
      "Specify your business name so that your clients can find you.",
    agency_expl_2_title: "Geolocation",
    agency_expl_2_expl:
      "The application needs your coordonates to put you in touch with clients close to you.",
    agency_expl_3_title: "Activate your account",
    agency_expl_3_expl:
      "Your profile will be visible once you have saved the information.",
    services_dst_lang: "To",
    support_title: "Our offices",
    support_company: "Hiero",
    support_addr1: "7 Rue Montespan",
    support_addr1_paris: "83 boulevard de Magenta",
    support_addr2: "91000 Évry-Courcouronnes",
    support_addr2_paris: "75010 Paris",
    support_addr3: "France",
    support_contact: "Contact",
    support_tel: "Tel: (+33) 1 76 38 01 40",
    support_email: "contact@hiero-solution.com",
    not_found_title: "Element not found",
    not_found_subtitle: "The page you requested does not exist",
    not_found_action: "Contact",
    not_found_home: "Home",
    menu_label_sur_place: "On site",
    menu_label_visio: "By video conference",
    consulter_facture: "View my invoices",
    facture_numero: "Invoice no.",
    recap_tva: "VAT SUMMARY",
    recap_taux: "RATE",
    recap_mt_tva: "VAT AMOUNT",
    recap_base_ht: "RATE EXCL. VAT",
    action: "Action",
    hours: "Time",
    join_room: "Enter the room",
    rooms: "Rooms",
    warning: "Warning!",
    conf: "Conference",
    warning_1: "You must pay attention to the correct spelling of each room.",
    interpretation_info: "Rules",
    interpretation_paragraphe_1:
      " To begin the intervention, you need to open two different tabs. You'll be with the interpreter in the first room, and all participants in the second room.",
    interpretation_paragraphe_2:
      " To access the conference room, the name to enter is",
    interpretation_paragraphe_3:
      ". Every participant attending the conference will be in this room, listening.",
    interpretation_paragraphe_4:
      " To access the interpretation room, the name to enter is",
    interpretation_paragraphe_5:
      " . Every speaker attending the conference will be with you in this room. The name of this room is likely to be the name of your client.",
    interpretation_paragraphe_6:
      " To carry out this internvention correctly, this remote conference requires that the speaker and the interpreter do not speak at the same time, in the same room.",
    interpretation_paragraphe_7:
      " That's why there are two different rooms. All participants will only hear your interpretation in the conference room while you'll only hear the speaker in the interpretation room.",
    interpretation_paragraphe_8:
      " For performance reasons, the maximum capacity in a room is 50 participants. You can create additional rooms depending on your audience. Then you can give the name of each room to your interpreter.",
    room_conf: "Conference room",
    traduction_assermentee: "Sworn / Certified Translation",
    traduction_classic: "Regular translation",
    interpretation: "Interpretation",
    AF: "Afghanistan",
    ZA: "South Africa",
    AL: "Albania",
    DZ: "Algeria",
    DE: "Germany",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AN: "Netherlands Antilles",
    SA: "Saudi Arabia",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BR: "Brazil",
    BN: "Brunei",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    EA: "Ceuta and Melilla",
    CL: "Chile",
    CN: "China",
    CY: "Cyprus",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo-Brazzaville",
    KP: "North Korea",
    KR: "South Korea",
    CR: "Costa Rica",
    CI: "Ivory Coast",
    HR: "Croatia",
    CU: "Cuba",
    DK: "Denmark",
    DG: "Diego Garcia",
    DJ: "Djibouti",
    DM: "Dominica",
    EG: "Egypt",
    SV: "El Salvador",
    AE: "United Arab Emirates",
    EC: "Ecuador",
    ER: "Eritrea",
    ES: "Spain",
    EE: "Estonia",
    VA: "Vatican",
    FM: "Micronesia",
    US: "United States of America",
    ET: "Ethiopia",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    GS: "South Georgia and the South Sandwich Islands",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GD: "Granada",
    GL: "Greenland",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GQ: "Equatorial Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    GF: "French Guyana",
    HT: "Haiti",
    HN: "Honduras",
    HU: "Hungary",
    BV: "Bouvet Island",
    CX: "Christmas Island",
    CP: "Clipperton Island",
    AC: "Ascension Island",
    IM: "Isle of Man",
    NF: "Norfolk Island",
    AX: "Åland Islands",
    KY: "Cayman Islands",
    IC: "Canary Islands",
    CC: "Cocos (Keeling) Islands",
    CK: "Cook Islands",
    FO: "Faroe Islands",
    HM: "Heard Island and McDonald Islands",
    FK: "Falkland Islands",
    MP: "Northern Mariana Islands",
    MH: "Marshall Islands",
    UM: "United States Minor Outlying Islands",
    SB: "Solomon Islands",
    TC: "Turks and Caicos Islands",
    VG: "British Virgin Islands",
    VI: "U.S. Virgin Islands",
    IN: "India",
    ID: "Indonesia",
    IQ: "Iraq",
    IR: "Iran",
    IE: "Ireland",
    IS: "Iceland",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KG: "Kyrgyzstan",
    KI: "Kiribati",
    KW: "Kuwait",
    LA: "Laos",
    LS: "Lesotho",
    LV: "Latvia",
    LB: "Lebanon",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MK: "Macedonia",
    MG: "Madagascar",
    MY: "Malaysia",
    MW: "Malawi",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MA: "Morocco",
    MQ: "Martinique",
    MU: "Mauritius",
    MR: "Mauritania",
    YT: "Mayotte",
    MX: "Mexico",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NO: "Norway",
    NC: "New Caledonia",
    NZ: "New Zealand",
    OM: "Oman",
    UG: "Uganda",
    UZ: "Uzbekistan",
    PK: "Pakistan",
    PW: "Palau",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    NL: "Netherlands",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PF: "French Polynesia",
    PR: "Puerto Rico",
    PT: "Portugal",
    QA: "Qatar",
    HK: "Hong Kong",
    MO: "Macao",
    QO: "Remote Oceania",
    CF: "Central African Republic",
    CD: "Democratic Republic of the Congo",
    DO: "Dominican Republic",
    CZ: "Czech Republic",
    RE: "Reunion",
    RO: "Romania",
    GB: "United Kingdom",
    RU: "Russia",
    RW: "Rwanda",
    EH: "Western Sahara",
    BL: "Saint Barthélemy",
    KN: "Saint Kitts and Nevis",
    SM: "San Marino",
    MF: "Saint Martin Island",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    SH: "Saint Helena",
    LC: "Saint Lucia",
    WS: "Samoa",
    AS: "American Samoa",
    ST: "São Tomé and Príncipe",
    SN: "Senegal",
    RS: "Serbia",
    CS: "Serbia and Montenegro",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SO: "Somalia",
    SD: "Sudan",
    LK: "Sri Lanka",
    SE: "Sweden",
    CH: "Switzerland",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SZ: "Swaziland",
    SY: "Syria",
    TJ: "Tajikistan",
    TW: "Taiwan",
    TZ: "Tanzania",
    TD: "Chad",
    TF: "French Southern and Antarctic Lands",
    IO: "British Indian Ocean Territory",
    PS: "Palestinian territories",
    TH: "Thailand",
    TL: "East Timor",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TA: "Tristan da Cunha",
    TN: "Tunisia",
    TM: "Turkmenistan",
    TR: "Turkey",
    TV: "Tuvalu",
    UA: "Ukraine",
    EU: "European Union",
    UY: "Uruguay",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    WF: "Wallis and Futuna",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
    /************ fin du bloc pour les phrases utilisées sur les 3 solutions ***********/

    /******************** Bloc pour les phrases utilisées null part ********************/
    order_asser_or_not: "Do you require a sworn or certified translation?",
    dashboard_pro_command_company_priceHT: "Price (excl. VAT):",
    new_orders: "Place an order",
    state_pro_wait: "Pending",
    account_expl_pro_cheque: "Please send a check to ...",
    account_expl_pro_viremement: "Wire transfer ",
    account_expl_pro_iban: "IBAN GB 000",
    account_hist_pro_debit: "Debit ",
    account_hist_pro_credit: "Credit ",
    account_hist_pro_way: "Means ",
    account_hist_pro_ref: "Reference ",
    agency_pro_budget_help: "The budget you wish to use for your translations.",
    sub_training: "Team training ",
    sub_search: "Immediate translator / interpreter research ",
    sub_geo: "Translator / interpreter geolocation ",
    sub_dem: "Digital account ",
    sub_save: "File storage and management ",
    sub_recap: "Monthly order recap ",
    sub_proj: "Live project progression ",
    sub_management: "Live budget management ",
    sub_message: "Messaging service",
    sub_send: "Delivery of your translations by post ",
    sub_impl: "Full budget management ",
    sub_account: "Account manager ",
    sub_orientation:
      "Custom orientation, depending on the most requested languages",
    sub_split:
      "Division of the budget within several departments, depending on its users ",
    sub_event: "Delivery of your translations by courier ",
    choose: "Choose",
    sub_function: "Features",
    sub_standard: "Standard",
    sub_essen: "Essential",
    sub_premium: "Premium",
    sub_month: "month",
    sub_associations_label: "Non-Profit Organizations",
    sub_cabinet_label: "Law firms",
    sub_bank_label: "International Mobility",
    sub_academia_label: "Academic institutions",
    sub_who_are_you: "Are you…",
    sub_explanation_academia:
      "We believe a partnership would be better for academic institutions such as universities, higher education institutions, etc. ",
    sub_explanation_systeme: "You can select one of our 2 propositions: ",
    sub_system_one:
      "Using our mobile application if your students cover their own costs.",
    sub_system_two:
      "Negotiating the implementation of a specific account if your students don’t cover their own costs. ",
    sub_credit_card_message:
      "To activate your services, please proceed to the payment of your subscription fee. To do so, go to the 'My Subscription' tab",
    validate_credit_card_message: "Your order has been processed. ",
    subscriber_choose: "Choose a subscription",
    find_out_more: "Find out more",
    menu_label_relecture: "Proofreading",
    signup_error_email_not_found: "This email is not registered.",
    main_left_menu_section_accueil: "Home",
    agency_setup_email_help_pro:
      "The email address to be used when discussing with the Hierø team.",
    main_menu_left_section_navigation_entreprise: "My company",
    main_menu_left_section_navigation_messagerie: "Messaging service",
    main_menu_left_section_navigation_gestion_des_fichiers: "File Manager",
    montant_total: "Total amount",
    single_commande_p1:
      "The translator downloads the document for translation. ",
    single_commande_p2:
      "Once translated, he’ll add the translation to the order so you can validate the translation and confirm the order.",
    load_intervention: "Intervention in process",
    commande_statut: "Order status",
    commande_details: "Order details",
    trad_name: "Name of the translator",
    visioner: "View",
    infos_interv: "Additional information about this intervention",
    intervention_time_date: "Date and time of the intervention",
    loading_order: "My current translation orders",
    view_all_orders: "View all my orders",
    last_payment: "My latest payments",
    view_all_payment: "View all my payments",
    last_send_msg: "Last messages sent",
    state_accept_order: "Order accepted",
    account_expl:
      "Please indicate your personal contact details in the event we need to get in touch.",
    account_surname_help: "",
    account_name_help: "",
    account_telephone_help: "",
    account_poste_help: "",
    account_adresse_help: "",
    account_complement_adr_help: "",
    account_email_help: "",
    account_password_help: "",
    account_new_password_help: "",
    not_found_action1: " if the problem persists.",
    menu_label_interpretation: "Request an interpreter",
    table_header_statut: "Status",
    head_professionnal:
      "The software needs to know your location so it can find the closest translator for your order.",
    head_change_password: "You can change your password here, if you wish to.",
    recap_tva_fr: "French VAT 20% ",
    client_particulier_name: "Name",
    client_particulier_email: "Email",
    client_particulier_address: "Postal address",
    client_particulier_code_postal: "Zip code - CITY",
    particulier_name: "Name",
    infos_entrep: "",
    infos_commandes: " ",
    address_facturation: "Change my billing address",
    /***************** fin du bloc pour les phrases utilisées null part ****************/
  },
  es: {
    /***************** Bloc pour les phrases utilisées que sur le trad *****************/
    account_asser: "¿Eres un(a) traductor(a) jurado(a)?",
    agency_companyname_help:
      "Si no eres un profesional, por favor introduzcas el nombre deseado. Rellenas el SIRET (o NIF) sólo si eres una empresa.",
    agency_contact_help:
      "Tienes que entrar tu correo electrónico y tu número de teléfono para chatear con los traductores, recibir notificaciones y descargar los documentos.",
    parrainer_client: "Patrocinar mis clientes",
    parrainage_paragraph:
      "¿Quieres centralizar todas los pedidos de tus clientes? Puedes invitar a tus clientes a utilisar HIERØ, recibirás todos los proyectos en tu cuenta.",
    btn_parrainage: "Enviar una invitación",
    account_parrainage_success:
      "Tu correo electrónico de patrocino ha sido enviado con éxito.",
    account_parrainage_failed: "No se pudo enviar el correo electrónico.",
    account_parrainage_email: "Invitar a un cliente",
    consult_missions: "Consultar mis proyectos",
    prestation_accept_order:
      "Una vez aceptado el proyecto, el cliente procederá al pago. Una vez realizado el pago, puedes empezar la traducción.",
    prestation_reject_order:
      "En caso de rechazo, nuestro software enviará automáticamente el pedido a otro traductor.",
    intervention_next_action_accept_order: "Aceptar la intervención",
    intervention_next_action_reject_order: "Rechazar la intervención",
    intervention_next_action_start_order: "Iniciar la intervención",
    intervention_order_details: "Detalles de la intervención",
    intervention_order_for: "Para el",
    intervention_address: "Dirección de la intervención",
    intervention_info_supp: "Información adicional sobre la intervención",
    agency_setup_tva: "¿Estás sujeto al IVA?",
    agency_setup_tva_placeholder: "",
    agency_setup_tva_number: "Número de IVA",
    agency_setup_tva_number_placeholder: "",
    agency_setup_tva_number_help:
      "Añadir tu número de IVA si estás sujeto a IVA",
    dashboard_turnover: "Mi volumen de negocios",
    dashboard_evolution_month: "Evolución durante el mes",
    dashboard_trad_month: "Traducciones del mes",
    dashboard_interpretation_month: "Interpretaciones del mes",
    dashboard_new_missions: "Nuevas proyectos",
    dashboard_my_missions: "Mis proyectos actuales",
    dashboard_command_date: "Fecha del pedido :",
    dashboard_type: "Tipo:",
    dashboard_total_sells: "Ventas totales desde mi registro",
    dashboard_doc_type: "Tipo de documento más traducido",
    dashboard_my_clients: "Mis clientes recurrentes",
    dashboard_trad: "Traducción",
    dashboard_interpretation: "Interpretación",
    dashboard_particulier: "Individuos",
    dashboard_pro: "Profesional",
    dashboard_prestation_lang: "Mis servicios por idioma",
    dashboard_evolution_year: "Evolución durante el año en curso",
    dashboard_status: "Estatus :",
    agree: "Al hacer clic, aceptará nuestras ",
    our_term: "condiciones de uso y de venta",
    and_the: "y el",
    stripe_agreement: " Contrato de Cuenta Conectada de Stripe. ",
    created_at: "Enviado el ",
    updated_at: "Actualizado el ",
    services_none_yet_subscribe: "Suscríbete para activar tus servicios",
    asser_placeholder: "¿Eres un(a) traductor(a) jurado(a)?",
    agency_expl_4_expl:
      "Antes de utilizar nuestros servicios es necesario que nos envíes el documento que acredite que eres un(a) traductor(a) jurado(a) realizado por el organismo competente",
    agency_expl_4_expl_1: "Puedes enviarlo por",
    agency_expl_4_expl_2:
      "Si tienes alguna pregunta sobre este procedimiento, no dude en ponerse en contacto con nosotros.",
    label_assermentation: "Traducción jurada",
    login_main_title: "Interfaz del traductor",
    login_main_subtitle:
      "¡Accede a sus proyectos y el editor de documentos con la interfaz traductor Hierø!",
    signup_caption_title: "Hierø - Interfaz del traductor",
    signup_main_subtitle:
      "¡Cree su cuenta, únase a nuestra interfaz y hágase en visible para nuestros usuarios!",
    main_header_subtitle: "Interfaz del traductor",
    main_left_menu_title: "Traductor ",
    main_left_menu_section_navigation_missions: "Mis proyectos",
    main_left_menu_section_parameters_subscription: "Mi suscripción",
    agency_setup_title: "A propósito de su agencia",
    order_translate_to: "al",
    section_services_title: "Mis servicios",
    section_tasks_title: "Mis proyectos",
    section_task_title: "Efectuar una misión",
    section_document_title: "Traducir un documento",
    tasks_src_lang: "Idioma original",
    prestation_breadcrumb_tasks: "Mis proyectos",
    prestation_order_number: "Número de pedido:",
    prestation_next_action_title: "Próximas etapas",
    prestation_next_action_accept_order: "Aceptar el pedido",
    prestation_next_action_accept_order_expl:
      "Haga clic aquí para aceptar el pedido. Luego, el cliente procederá al pago. Una vez el pago realizado, puede empezar la traducción.",
    prestation_next_action_reject_order: "Rechazar el pedido",
    prestation_next_action_reject_order_expl:
      "Haga clic aquí para rechazar el pedido.",
    prestation_next_action_rejected: "Usted rechazó el pedido.",
    prestation_next_action_waiting_for_payment:
      "Esperando el pago por el cliente.",
    prestation_next_action_translate_expl:
      "Puede proceder a la traducción de cada documento en este pedido haciendo clic en el botón 'Traducir'. Una vez los documentos listos, haga clic en el botón 'Enviar al cliente'. Para cualquier pregunta o precisión, puede conversar con el cliente directamente por medio de la mensajería electrónica.",
    prestation_next_action_translate_all_done_expl:
      "Usted acabó todos los documentos de este pedido.",
    prestation_next_action_translate_confirm:
      "Enviar al cliente para validación",
    prestation_next_action_waiting_for_validation:
      "El cliente está validando la traducción.",
    prestation_next_action_validated: "Pedido confirmado por el cliente.",
    prestation_order_details: "Detalles del pedido",
    prestation_order_to: "Para",
    prestation_order_country_origin: "País de origen:",
    prestation_order_document_count: "Documentos para traducir:",
    prestation_order_price: "Precio (neto):",
    prestation_order_date: "Pedido hecho el:",
    prestation_order_documents_title: "Documentos para traducir",
    prestation_order_document_originals: "Originales",
    prestation_order_document_translated: "Traducido(s)",
    prestation_order_document_not_yet_translated: "Aún no hay traducción",
    prestation_order_document_translate: "¡Traducir!",
    prestation_order_confirm_ok: "Ok",
    prestation_order_confirm_cancel: "Cancelar",
    prestation_order_validate_title: "Confirmar",
    prestation_order_validate_msg:
      "¿Usted está seguro de que quiere confirmar el pedido? El cliente recibirá una notificación y podrá acceder a los documentos traducidos.",
    prestation_order_accept_title: "Confirmar",
    prestation_order_accept_msg:
      "¿Usted está seguro de que quiere aceptar este pedido?",
    prestation_order_refuse_title: "Confirmar",
    prestation_order_refuse_msg:
      "¿Usted está seguro de que quiere rechazar este pedido?",
    prestation_document_validate: "¡Confirmar la traducción!",
    prestation_document_show_original: "Ver el original en otra pestaña:",
    prestation_document_button_reset: "Reiniciar el documento",
    prestation_document_confirm_title: "Confirmar",
    prestation_document_confirm_message:
      "¿Usted está seguro de que quiere reiniciar la traducción? ¡Todos los cambios se perderán!",
    services_add_button: "Añadir una nueva oferta…",
    services_none_yet:
      "Para ser visible en la plataforma Hierø, especifique los tipos de traducciones que ofrece.",
    services_add_expl:
      "Por favor, especifique los idiomas que practica y luego haga clic en 'Añadir'.",
    services_add_src_lang: "Idioma original",
    services_add: "¡Añadir!",
    services_error_add:
      "Esta combinación de idiomas aún no es posible con Hierø. ¡Contáctenos para tener más información!",
    services_dont_see_title: "No puedo consultar mis idiomas.",
    services_dont_see_response:
      "Hierø simplifica vuestra traducción al proporcionar unos modelos de documentos listos par usar. Siempre desarrollamos nuestros modelos. Contàctenos por <a href='mailto:contact@hiero-solution.com'>contact@hiero-solution.com</a> para añadir otros idiomas.",
    services_list_title: "Su lista de servicios",
    services_expl:
      "Haga clic en cada servicio para personalizar los documentos que acepta. Puede rechazar un tipo de documento, deshabilitando el botón, o especificar su precio para procesar este tipo de documento.",
    services_list_active: "Activado",
    services_list_country: "País",
    services_list_document_type: "Tipo de documento",
    services_list_average_price: "Precio medio (neto)",
    services_list_your_price: "Su precio (neto)",
    services_list_your_price_reset: "Reiniciar",
    services_list_delete: "Suprimir",
    temps_ex: "Tiempo estimado",
    question_inter: "¿Eres intérprete?",
    question_inter_price: "Precio de una hora:",
    question_inter_1:
      "Realizará tareas de interpretación en nuestro software para",
    calendar: " Calendario",
    calendar_hidden: "Ocultar calendario",
    interpretation_label_menu: "Interpretación",
    interpretation_label: "Interpretación",
    room_inter: "Sala de interpretación ",
    once_page_price: "Sus tarifas en la página",
    once_page_information:
      "Le invitamos a indicar su tarifa para la traducción por página de un documento (250 palabras). Esta tarifa se ofrecerá al cliente si el documento que desea traducir no está registrado por nuestro sistema.",
    facture_pro: "Profesional",
    /************** fin du bloc pour les phrases utilisées que sur le trad *************/

    /**************** Bloc pour les phrases utilisées sur le pro et trad ***************/
    agency_name_help:
      "En caso de problemas, será más fácil para el equipo encontrarte con tu nombre y apellido.",
    menu_label_tel: "Por teléfono",
    section_numero_commande: "Número del pedido",
    dashboard_traductor_asser: "Traducción jurada",
    dashboard_traductor_classic: "Traducción clásica",
    dashboard_month_janv: "Ene",
    dashboard_month_fevr: "Feb",
    dashboard_month_mars: "Mar",
    dashboard_month_avr: "Abr",
    dashboard_month_mai: "Mayo",
    dashboard_month_juin: "Jun",
    dashboard_month_juill: "Jul",
    dashboard_month_aout: "Ago",
    dashboard_month_sept: "Sept",
    dashboard_month_oct: "Oct",
    dashboard_month_nov: "Nov",
    dashboard_month_dec: "Dic",
    sub_subscription: "Suscripción",
    main_header_title: "Hierø -",
    main_left_menu_section_navigation: "Menú de navegación",
    main_left_menu_section_parameters: "Configuración",
    agency_setup_explanation:
      "Hierø ofrece sus servicios a los clientes cercanos de sus locales. <br> Por favor especifica su razón social y su dirección. <br>Se ofrecerá las informaciones a los clientes cuando busquen un traductor.",
    section_prestation: "Servicio",
    delai_intervention: "Duración de la intervención",
    type_doc_commande: "Tipo de documento solicitado",
    price_total_ht: "Total sin IVA",
    section_dashboard_title: "Panel de control",
    tasks_price: "Precio (neto)",
    support_tel_es: "Tel (es) : (+34) 911 23 88 87",
    support_tel_en: "Tel (en) : (+1) 929 274 5104",
    type_intervention: "Tipo de intervención solicitada",
    once_page_price_default: "Precio predeterminado (SIN IVA)",
    client: "Cliente",
    /************ fin du bloc pour les phrases utilisées sur le pro et trad ************/

    /************ Bloc pour les phrases utilisées sur le trad et particulier ***********/
    modal_supp_title: "Confirmar la supresión de tu cuenta",
    facture_part: "Particular",
    tasks_filters: "Filtros",
    /******** fin du bloc pour les phrases utilisées sur le trad et particulier ********/

    /*************** Bloc pour les phrases utilisées sur les 3 solutions ***************/
    menu_label_event_name: "objetivo de la intervención",
    modif_profile_success_pwd: "Tu contraseña ha sido actualizada con éxito.",
    modif_profile_failed_pwd:
      "Ocurrió un error al cambiar tu contraseña. Por favor, compruebes que tu contraseña es correcta.",
    modal_modif_title: "Confirmar la modificación de tu correo electrónico",
    modif_profile_failed_login:
      "Correo electrónico o contraseña incorrecta. Intentar otra vez.",
    augmente_budget:
      "Para aumentar tu saldo, puedes enviarnos un cheque o hacer una transferencia",
    no_budget: "No tienes fondos por el momento",
    validation_mail_registration:
      "¡Bievenido a HIERO! Para utilizar nuestros servicios, debes confirmar tu correo electrónico.",
    validation_modif_mail:
      "Tu correo electrónico se ha cambiado con éxito. Para utilizar nuestros servicios, debes confirmar tu correo electrónico otra vez.",
    validation_question:
      "¿No recibiste el correo de confirmación? Haga clic en el botón para enviar de nuevo el correo.",
    validation_mail_resend:
      "Enviar de nuevo el correo electrónico de confirmación",
    message_verification:
      "Por favor, confirmes tu correo electrónico y revises tu buzón de correo.",
    non_valid_address: "Correo electrónico no confirmado",
    validation_mail_success:
      "Correo electrónico de confirmación enviado con éxito.",
    account_error_invalid_email: "La dirección electrónica no es válida.",
    account_error_min_password: "Al menos 8 caracteres",
    account_error_passwords_do_not_match: "Las contraseñas no coinciden",
    account_confirm: "Confirmar vuestra contraseña",
    modif_profile_success: "¡Tu perfil has sido actualizado!",
    modif_profile_failed:
      "Se ha producido un error al editar tu perfil. Por favor, comprueba tus datos y inténtalo de nuevo.",
    facture_echeance: "Vencimiento",
    facture_conditions: "Condiciones de pago",
    facture_comptant: "En efeectivo",
    facture_adress: "Enviar a",
    facture_qte: "Cantidad",
    facture_price: "Precio",
    facture_unitaire: "Unitario",
    facture_montant: "Importe",
    facute_solde: "Total",
    facture_tva_fr: "IVA FR 20%",
    order_language_from: "Del",
    order_language_to: "Al",
    account_hist_pro_date: "Fecha de la intervención",
    select_choose: "Elegir...",
    tasks_price_tva: "IVA",
    order_no_asser_document_name: "Nombre del documento",
    menu_label_traductor_asser: "Traducción jurada",
    menu_label_traductor_classic: "Traducción clásica",
    menu_label_interprestation: "Interpretación",
    menu_label_all: "Ver todo",
    login_caption_title: "Hierø - Interfaz traductor",
    login_caption_subtitle:
      "Un programa informático diseñado por traductores para los traductores.",
    login_main_logo: "Hierø",
    login_main_connect: "Conectarse",
    login_main_email: "Dirección electrónica",
    login_main_email_placeholder: "Indicar la dirección electrónica",
    login_main_email_help: "Inicie la sesión con su dirección electrónica",
    login_main_password: "Contraseña",
    login_main_password_placeholder: "Indicar su contraseña",
    login_main_password_help: "",
    login_main_button: "Conectarse",
    login_main_to_signup: "¿No dispone de una cuenta? ¡Registrarse!",
    login_main_copyright: "© Hierø - HIERØ 2020",
    login_error_required_field: "Campo obligatorio",
    login_error_invalid_email: "La dirección electrónica no es válida.",
    login_error_email_not_found: "La dirección electrónica no existe.",
    signup_caption_subtitle: "¡Elija Hierø y gane en visibilidad!",
    signup_main_logo: "Hierø",
    signup_main_title: "Inscripción",
    signup_main_email: "Dirección electrónica",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Contraseña",
    signup_main_password_placeholder: "",
    signup_main_password_help: "",
    signup_main_confirm: "Confirmar vuestra contraseña",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Apellido",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Nombre",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Teléfono",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "Suscribir",
    signup_main_to_signup: "¿Dispone de una cuenta? Conectarse",
    signup_main_copyright: "© Hierø - HIERØ 2020",
    signup_error_required_field: "Campo obligatorio",
    signup_error_invalid_email: "La dirección electrónica no es válida.",
    signup_error_min_password: "Al menos 8 caracteres",
    signup_error_max_password: "30 caracteres máximo",
    signup_error_passwords_do_not_match: "Las contraseñas no coinciden",
    login_forgot_password: "¿Has olvidado la contraseña?",
    reset_password_main: "Reiniciar la contraseña ",
    reset_password_email_title: "Dirección electrónica",
    reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Enviar",
    reset_password_login: "Conexión",
    reset_password_success:
      "Se ha enviado un correo a su dirección electrónica. Luego haga clic en el enlace para cambiar la contraseña.",
    main_left_menu_section_parameters_agency: "Mi empresa",
    main_left_menu_section_parameters_services: "Mis servicios",
    main_left_menu_section_parameters_user: "Mi cuenta",
    main_left_menu_section_parameters_support: "Soporte",
    reset_password_error_required: "Campo obligatorio",
    reset_password_error_invalid_email: "La dirección electrónica no es válida",
    reset_password_error_user_not_found: "La dirección electrónica no existe",
    main_header_user_menu_logout: "Desconectar",
    agency_setup_companyname: "Razón social",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help:
      "El nombre de la empresa a utilizar para los pedidos, las facturas, etc...",
    agency_setup_address: "Dirección",
    agency_setup_siret: "NIF",
    agency_setup_siret_placeholder: "",
    agency_setup_siret_help: "Ingrese su número de siret si es una empresa",
    agency_setup_address_placeholder: "",
    agency_setup_address_help:
      "Empieza a escribir la dirección y después selecciona la dirección adecuada en el menú desplegable.",
    agency_setup_addresscompl: "Complementos de dirección",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help:
      "Edificio, escalera, código de acceso, etc. Cuando tus clientes recogen los documentos, necesitan todas las informaciones.",
    agency_setup_tel: "Teléfono",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help:
      "El número de teléfono a utilizar para conversar con tus clientes.",
    agency_setup_email: "Dirección electrónica",
    agency_setup_email_placeholder: "",
    agency_setup_email_help:
      "La dirección electrónica a utilizar para conversar con tus clientes. Por favor indica otra si no es la dirección de registro de Hierø.",
    agency_setup_save: "Guardar",
    agency_setup_error_required_field: "Campo obligatorio",
    agency_setup_error_invalid_email: "La dirección electrónica no es válida.",
    agency_setup_error_valid_address:
      "Por favor escoge una de las direcciones propuestas en el menú desplegable.",
    agency_address_not_found: "¿No se puede encontrar su dirección?",
    agency_address_title: "Introducir manualmente sus datos",
    agency_address_address: "Dirección :",
    agency_address_number: "N.°",
    agency_address_street: "Calle",
    agency_address_code: "Código postal",
    agency_address_city: "Ciudad",
    agency_address_country: "País",
    agency_address_coords: "Coordenadas GPS:",
    agency_address_coords_lat: "Latitud",
    agency_address_coords_long: "Longitud",
    agency_address_cancel: "Cancelar",
    agency_address_validate: "Validar",
    main_left_menu_section_navigation_dashboard: "Panel de control",
    main_menu_left_section_navigation_factures: "Mis facturas",
    head_facture: "Factura",
    section_date: "Fecha",
    section_numero_facture: "Factura",
    section_type_prestation: "Tipo de servicio",
    section_price: "Total",
    section_print: "Imprimir",
    section_generate_facture: "Generar la factura en PDF",
    section_consulter: "Consultar",
    prestation: "Servicio",
    commande_qte: "Cantidad",
    commande_produit: "Producto",
    commande_desc: "Descripción",
    total_ht: "Total antes de impuestos",
    total_tva: "Total con IVA",
    total_ttc: "Total, con IVA incluido",
    retour_btn: "Regresar",
    progress_commande: "Seguimiento del pedido",
    commande_numero: "Nº de pedido°",
    capital_sasu: "SASU con un capital social de 7 000,00 euros",
    rcs_infos: "RCS EVRY B 843 119 728 ",
    rcs_infos_new: "RCS PARIS B 843 119 728 ",
    siret: " NIF 84311972800023",
    siret_new: " NIF 84311972800031",
    tva_fr: "Número de IVA: FR00843119728",
    condition_paragraphe_1: "Penalidad por retraso (tasa anual): 10%",
    condition_paragraphe_2: "No hay descuento por pago anticipado",
    condition_paragraphe_3:
      "Indemnización fija para los costes de cobro en caso de retraso en el pago: 40€",
    prestataire: "Proveedor",
    my_info: "Mi información",
    geolocation_paragraphe:
      "El software necesita conocer tu ubicación para buscar traductores cerca de ti con precisiónn",
    update_password: "Cambiar mi contraseña",
    account_upt: "Si quieres, puedes cambiar tu contraseña.",
    send: "enviado",
    close_modal: "Cerrar",
    inscription_date: "Registrado desde ",
    order_translate: "del ",
    montant_total_euro: "Total en EUR",
    rules_conditions: "CONDICIONES DE PAGO",
    rules_conditions_p: "pago al recibir la factura",
    section_agency_title: "Mi empresa",
    section_account_title: "Mi cuenta",
    section_support_title: "Soporte",
    notifications_new_task: "¡Usted recibió una nueva misión!",
    notifications_task_confirmed:
      "La misión fue confirmada. Usted puede empezar la traducción.",
    notifications_task_validated: "El cliente validó la misión.",
    tasks_sent_on: "Enviado el",
    tasks_src_country: "País de origen",
    tasks_dst_country: "Traducir al",
    tasks_state: "Estado",
    state_cancelled: "Cancelado por el cliente",
    state_waiting_for_translator: "Nuevo",
    state_waiting_for_payment: "Esperando el pago",
    state_refused_by_translator: "Misión rechazada",
    state_translating: "Traducción pendiente",
    state_waiting_for_client_validation:
      "Terminada. Esperando la validación por el cliente",
    state_validated: "Validado por el cliente",
    state_validated_pro: "Validado",
    prestation_order_from: "De",
    prestation_order_document_type: "Tipo de documento",
    prestation_order_messaging_title: "Mensajería electrónica",
    prestation_order_messaging_send: "Enviar",
    prestation_order_messaging_client: "Cliente:",
    prestation_order_messaging_me: "Yo:",
    prestation_document_edit: "Editor de documento",
    prestation_document_edit_expl:
      "El texto en el campo más arriba es subrayado en el documento más abajo. Al entrar el texto traducido en el campo, el documento se actualizará automáticamente. Utilice las flechas para navegar el documento.",
    prestation_document_get_browser:
      "¿Usted no consigue ver el PDF? Por favor utilice un navegador reciente como",
    prestation_document_confirm_oui: "Sí",
    prestation_document_confirm_non: "No",
    account_save: "Guardar",
    account_surname: "Apellido",
    account_surname_placeholder: "",
    account_name: "Nombre",
    account_name_placeholder: "",
    account_telephone: "Teléfono",
    account_telephone_placeholder: "",
    account_adresse: "Dirección",
    account_adresse_placeholder: "Dirección",
    account_complement_adr: "Dirección adicional",
    account_complement_adr_placeholder: "Dirección adicional",
    account_email: "Correo electrónico",
    account_email_placeholder: "Correo electrónico",
    account_password: "Contraseña actual",
    account_new_password: "Nueva contraseña",
    account_error_required: "Campo obligatorio",
    agency_expl_1_title: "Razón social",
    agency_expl_1_expl:
      "Especifique el nombre de su empresa para que los clientes pueden encontrarlo.",
    agency_expl_2_title: "Localización",
    agency_expl_2_expl:
      "La aplicación necesita su información de contact para ponerlo en  contacto con los clientes más cercano a usted.",
    agency_expl_3_title: "Activar su cuenta",
    agency_expl_3_expl:
      "Vuestro perfil será visible en línea, una vez que guarde sus informaciones de contacto.",
    services_dst_lang: "Al",
    support_title: "Nuestros locales",
    support_company: "Hiero",
    support_addr1: "7 Rue Montespan",
    support_addr1_paris: "83 boulevard de Magenta",
    support_addr2: "91000 Évry-Courcouronnes",
    support_addr2_paris: "75010 Paris",
    support_addr3: "Francia",
    support_contact: "Contacto",
    support_tel: "Tel.: (+33) 1 80 38 30 46",
    support_email: "contact@hiero-solution.com",
    not_found_title: "Elemento no encontrado",
    not_found_subtitle: "La página que solicitó no existe.",
    not_found_action: "Contactar el soporte ",
    not_found_home: "Inicio",
    menu_label_sur_place: "En el lugar",
    menu_label_visio: "Por videoconferencia",
    consulter_facture: "Consultar mis facturas",
    facture_numero: "Factura N°",
    recap_tva: "RESUMEN DEL IVA",
    recap_taux: "TASA",
    recap_mt_tva: "TOTAL DEL IVA",
    recap_base_ht: "BASE SIN IVA",
    action: "Acción",
    hours: "Horas",
    join_room: "Entrar en la sala",
    rooms: "Las salas (salas de reuniones)",
    warning: "¡Atención! ",
    conf: "La conferencia",
    warning_1: "Debe respetarse la ortografía de los nombres de las salas.",
    interpretation_info: "Explicación",
    interpretation_paragraphe_1:
      "Para participar en la misión de interpretación, necesita al menos dos ventanas. En la primera, estarás con el intérprete, en la segunda con todos los participantes.",
    interpretation_paragraphe_2:
      "Para acceder a la sala de conferencias, el nombre de la sala a indicar corresponde a ",
    interpretation_paragraphe_3:
      ". Aquí se encuentran todos los participantes.",
    interpretation_paragraphe_4:
      "Para acceder a la sala de interpretación, el nombre de la sala a indicar corresponde a ",
    interpretation_paragraphe_5:
      ". Aquí se encuentran sus oradores y el intérprete seleccionado.",
    interpretation_paragraphe_6:
      "Para realizar correctamente el trabajo de interpretación de esta conferencia a distancia, es necesario que el orador y el intérprete no hablen al mismo tiempo en la misma sala.",
    interpretation_paragraphe_7:
      "Por eso están en dos salas diferentes. De este modo, los participantes sólo le oirán a usted en la sala de conferencias y usted sólo oirá a los oradores en la sala de interpretación.",
    interpretation_paragraphe_8:
      "Por razones de rendimiento, la capacidad máxima de una sala es de 50 participantes. Puede crear tantas salas correspondientes a su público como desee y comunicar los nombres de estas salas a su intérprete.",
    room_conf: " Sala de conferencias",
    traduction_assermentee: "Traducción jurada",
    traduction_classic: "Traducción clásica",
    interpretation: "Interpretación",
    AF: "Afganistán",
    ZA: "Sudáfrica",
    AL: "Albania",
    DZ: "Argelia",
    DE: "Alemania",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguila",
    AQ: "Antártida",
    AG: "Antigua y Barbuda",
    AN: "Antillas Neerlandesas",
    SA: "Arabia Saudita",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaiyán",
    BS: "Bahamas",
    BH: "Baréin",
    BD: "Bangladés",
    BB: "Barbados",
    BY: "Belarús",
    BE: "Bélgica",
    BZ: "Belice",
    BJ: "Benín",
    BM: "Bermudas",
    BT: "Bután",
    BO: "Bolivia",
    BA: "Bosnia y Herzegovina",
    BW: "Botsuana",
    BR: "Brasil",
    BN: "Brunéi",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Camboya",
    CM: "Camerún",
    CA: "Canadá",
    CV: "Cabo Verde",
    EA: "Ceuta y Melilla",
    CL: "Chile",
    CN: "China",
    CY: "Chipre",
    CO: "Colombia",
    KM: "Comoras",
    CG: "República del Congo",
    KP: "Corea del Norte",
    KR: "Corea del Sur",
    CR: "Costa Rica",
    CI: "Costa de Marfil",
    HR: "Croacia",
    CU: "Cuba",
    DK: "Dinamarca",
    DG: "Diego García",
    DJ: "Yibuti",
    DM: "Dominica",
    EG: "Egipto",
    SV: "El Salvador",
    AE: "Emiratos Árabes Unidos",
    EC: "Ecuador",
    ER: "Eritrea",
    ES: "España",
    EE: "Estonia",
    VA: "Estado de la Ciudad del Vaticano",
    FM: "Estados Federados de Micronesia",
    US: "Estados Unidos",
    ET: "Etiopia",
    FJ: "Fiyi",
    FI: "Finlandia",
    FR: "Francia",
    GA: "Gabón",
    GM: "Gambia",
    GE: "Georgia",
    GS: "Islas Georgias del Sur y Sándwich del Sur",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Grecia",
    GD: "Granada",
    GL: "Groenlandia",
    GP: "Guadalupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GQ: "Guinea ecuatorial",
    GW: "Guinea-Bisáu",
    GY: "Guyana",
    GF: "Guayana Francesa",
    HT: "Haití",
    HN: "Honduras",
    HU: "Hungría",
    BV: "Isla Bouvet",
    CX: "Isla de Navidad",
    CP: "Isla Clipperton",
    AC: "Isla Ascensión",
    IM: "Isla de Man",
    NF: "Isla Norfolk",
    AX: "Islas Åland",
    KY: "Islas Caimán",
    IC: "Islas Canarias",
    CC: "Islas Cocos / Islas Keeling",
    CK: "Islas Cook",
    FO: "Islas Feroe",
    HM: "Islas Heard y McDonald",
    FK: "Islas Malvinas",
    MP: "Islas Marianas del Norte",
    MH: "República de las Islas Marshall",
    UM: "Islas Ultramarinas Menores de los Estados Unidos",
    SB: "Islas Salomón",
    TC: "Islas Turcas y Caicos",
    VG: "Islas Vírgenes Británicas",
    VI: "Islas Vírgenes de os Estados Unidos",
    IN: "India",
    ID: "Indonesia",
    IQ: "Irak",
    IR: "Irán",
    IE: "Irlanda",
    IS: "Islandia",
    IL: "Israel",
    IT: "Italia",
    JM: "Jamaica",
    JP: "Japón",
    JE: "Jersey",
    JO: "Jordania",
    KZ: "Kazajistán",
    KE: "Kenia",
    KG: "Kirguistán",
    KI: "Kiribati",
    KW: "Kuwait",
    LA: "Laos",
    LS: "Lesoto",
    LV: "Letonia",
    LB: "Líbano",
    LR: "Liberia",
    LY: "Libia",
    LI: "Liechtenstein",
    LT: "Lituania",
    LU: "Luxemburgo",
    MK: "Macedonia",
    MG: "Madagascar",
    MY: "Malasia",
    MW: "Malaui",
    MV: "Maldivas",
    ML: "Malí",
    MT: "Malta",
    MA: "Marruecos",
    MQ: "Martinica",
    MU: "Mauricio",
    MR: "Mauritania",
    YT: "Mayotte",
    MX: "México",
    MD: "Moldavia",
    MC: "Mónaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NI: "Nicaragua",
    NE: "Níger",
    NG: "Nigeria",
    NU: "Niue",
    NO: "Noruega",
    NC: "Nueva Caledonia",
    NZ: "Nueva Zelandia",
    OM: "Omán",
    UG: "Uganda",
    UZ: "Uzbekistán",
    PK: "Pakistán",
    PW: "Palaos",
    PA: "Panamá",
    PG: "Papúa Nueva Guinea",
    PY: "Paraguay",
    NL: "Países Bajos",
    PE: "Perú",
    PH: "Filipinas",
    PN: "Islas Pitcairn",
    PL: "Polonia",
    PF: "Polinesia Francesa",
    PR: "Puerto Rico",
    PT: "Portugal",
    QA: "Catar",
    HK: "R.A.E de Hong Kong de la República Popular China",
    MO: "R.A.E. de Macao",
    QO: "Oceanía Lejana",
    CF: "República Centroafricana",
    CD: "República Democrática del Congo",
    DO: "República Dominicana",
    CZ: "República Checa",
    RE: "Reunión",
    RO: "Rumanía",
    GB: "Reino Unido",
    RU: "Rusia",
    RW: "Ruanda",
    EH: "Sahara Occidental",
    BL: "San Bartolomé",
    KN: "San Cristóbal y Nieves",
    SM: "San Marino",
    MF: "Isla San Martín",
    PM: "San Pedro y Miquelón",
    VC: "San Vicente y las Granadinas",
    SH: "Isla Santa Elena",
    LC: "Santa Lucia",
    WS: "Samoa",
    AS: "Samoa Americana",
    ST: "Santo Tomé y Príncipe",
    SN: "Senegal",
    RS: "Serbia",
    CS: "Serbia y Montenegro",
    SC: "Seychelles",
    SL: "Sierra Leona",
    SG: "Singapur",
    SK: "Eslovaquia",
    SI: "Eslovenia",
    SO: "Somalia",
    SD: "Sudán",
    LK: "Sri Lanka",
    SE: "Suecia",
    CH: "Suiza",
    SR: "Surinam",
    SJ: "Svalbard y Jan Mayen",
    SZ: "Suazilandia",
    SY: "Siria",
    TJ: "Tayikistán",
    TW: "Taiwán",
    TZ: "Tanzania",
    TD: "Chad",
    TF: "Tierras Australes y Antárticas Francesas",
    IO: "Territorio Británico del Océano Índico",
    PS: "Territorios Palestinos",
    TH: "Tailandia",
    TL: "Timor Oriental",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad y Tobago",
    TA: "Tristán da Cunha",
    TN: "Túnez",
    TM: "Turkmenistán",
    TR: "Turquía",
    TV: "Tuvalu",
    UA: "Ucrania",
    EU: "Unión Europea",
    UY: "Uruguay",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    WF: "Wallis y Futuna",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabue",
    /************ fin du bloc pour les phrases utilisées sur les 3 solutions ***********/

    /******************** Bloc pour les phrases utilisées null part ********************/
    order_asser_or_not: "¿Necesitas una traducción jurada?",
    dashboard_pro_command_company_priceHT: "Precio (sin IVA):",
    new_orders: "Hacer un nuevo pedido",
    state_pro_wait: "Pendiente",
    account_expl_pro_cheque: "Por favor envíe un cheque a Hiero",
    account_expl_pro_viremement: "Transferencias ",
    account_expl_pro_iban: "IBAN ES 000",
    account_hist_pro_debit: "Débito ",
    account_hist_pro_credit: "Crédito ",
    account_hist_pro_way: "Medio ",
    account_hist_pro_ref: "Referencia ",
    agency_pro_budget_help:
      "El presupuesto que desea asignar a sus traducciones.",
    sub_training: "Formación de equipo ",
    sub_search: "Búsqueda automática de traductor/intérprete ",
    sub_geo: "Geolocalización del traductor y/o del intérprete ",
    sub_dem: "Desmaterialización de todos tus pedidos ",
    sub_save: "Copia de seguridad de tus documentos con gestión de archivos ",
    sub_recap: "Resumen de tus pedidos por mes",
    sub_proj: "Progreso de tus proyectos en tiempo real ",
    sub_management: "Gestión de presupuesto en tiempo real ",
    sub_message: "Mensajería automática",
    sub_send: "Entrega de tus traducciones por correo ",
    sub_impl: "Implementación del presupuesto asignado para 12 meses ",
    sub_account: "Account manager a tu lado ",
    sub_orientation:
      "Orientación personalizada según los idiomas más solicitados",
    sub_split:
      "División del presupuesto en varios departamentos según los usuarios ",
    sub_event: "Entrega de tus traducciones por mensajero ",
    choose: "Elegir",
    sub_function: "Características",
    sub_standard: "Estándar",
    sub_essen: "Esencial",
    sub_premium: "Premium",
    sub_month: "mes",
    sub_associations_label: "Asociaciones",
    sub_cabinet_label: "Despachos de abogados",
    sub_bank_label: "Movilidad internacional",
    sub_academia_label: "Instituciones académicas",
    sub_who_are_you: "¿Eres…?",
    sub_explanation_academia:
      "Queremos trabajar en colaboración con las instituciones académicas, como universidades.",
    sub_explanation_systeme: "Ofrecemos dos sistemas diferentes: ",
    sub_system_one:
      "Utilizar nuestra aplicación móvil s la institución académica no cubre las traducciones.",
    sub_system_two:
      "Negociar la implantación de nuestro espacio profesional si la institución académica cubre las traducciones.",
    sub_credit_card_message:
      " Debes pagar su suscripción para activar tus servicios. En la pestaña",
    validate_credit_card_message: "Tu pedido ha sido procesado.",
    subscriber_choose: "Elija tu suscripción. ",
    find_out_more: "Más información",
    menu_label_relecture: "Relectura",
    signup_error_email_not_found: "La dirección electrónica no existe.",
    main_left_menu_section_accueil: "Inicio",
    agency_setup_email_help_pro:
      "El correo electrónico que se utilizará para corresponder con nuestros traductores y el equipo de Hierø.",
    main_menu_left_section_navigation_entreprise: "Mi empresa",
    main_menu_left_section_navigation_messagerie: "Mis mensajes",
    main_menu_left_section_navigation_gestion_des_fichiers:
      "Gestión de archivos",
    montant_total: "Importe total",
    single_commande_p1: "El traductor descarga el documento para traducirlo. ",
    single_commande_p2:
      "Una vez traducido, lo añade al pedido para que puedes validar la traducción y confirmar el pedido.",
    load_intervention: "Intervención en proceso",
    trad_name: "Nombre del traductor",
    visioner: "Ver",
    infos_interv: "Información adicional sobre la participación",
    intervention_time_date: "Fecha y hora de la intervención",
    loading_order: "Mis pedidos de traducción en curso",
    view_all_orders: "Ver todos los pedidos",
    last_payment: "Mis últimos pagos realizados",
    view_all_payment: "Ver todos mis pagos",
    last_send_msg: "Últimos mensajes enviados",
    state_accept_order: "Pedido aceptado",
    account_expl:
      "Indique sus datos para que podemos contactarle si es necesario.",
    account_surname_help: "",
    account_name_help: "",
    account_telephone_help: "",
    account_poste_help: "",
    account_adresse_help: "",
    account_complement_adr_help: "",
    account_email_help: "",
    account_password_help: "",
    account_new_password_help: "",
    not_found_action1: "si el problema persiste. ",
    menu_label_interpretation: "Solicitar una interpretación ",
    table_header_statut: "Estatus",
    head_professionnal:
      "El software necesita conocer tu posición para buscar los traductores más cercanos con una mayor precisión",
    head_change_password: "Si lo deseas, puedes cambiar tu contraseña",
    recap_tva_fr: "20% IVA FR",
    client_particulier_name: "Razón social",
    client_particulier_email: "Correo electrónico",
    client_particulier_address: "Dirección ",
    client_particulier_code_postal: "Ciudad",
    particulier_name: "Nombre del individuo",
    infos_entrep: "",
    infos_commandes: "",
    address_facturation: " Cambiar mi dirección de facturación",
    /***************** fin du bloc pour les phrases utilisées null part ****************/
  },
  pt: {
    /***************** Bloc pour les phrases utilisées que sur le trad *****************/
    account_asser: "Você é juramento(a)?",
    agency_companyname_help:
      "Se não é um profissional, indique o nome desejado. Só preencha o SIRET (ou NIF) se for uma empresa.",
    agency_contact_help:
      "Seu correio eletónico e número de telefone são necessários para conversar com os tradutores e receber notificações.",
    parrainer_client: "Indicar clientes",
    parrainage_paragraph:
      "Deseja centralizar todas as encomendas de seus clientes? Convide os seus clientes a utilizar HIERØ, para receber todos os seus pedidos na sua conta.",
    btn_parrainage: "Enviar um convite",
    account_parrainage_success:
      "Seu correio eletrónico de apoio foi enviado com sucesso.",
    account_parrainage_failed: "O correio eletriónico não pôde ser enviado.",
    account_parrainage_email: "Convide um cliente",
    consult_missions: "Consultar as minhas missões",
    prestation_accept_order:
      "Após aceitar a encomenda, o cliente procederá ao pagamento. Uma vez que o pagamento foi feito, você pode iniciar a tradução.",
    prestation_reject_order:
      "Se você recusar, nosso software enviará automaticamente a encomenda para outro tradutor.",
    intervention_next_action_accept_order: "Aceitar a intervenção",
    intervention_next_action_reject_order: "Rejeitar a intervenção",
    intervention_next_action_start_order: "Iniciar a intervenção",
    intervention_order_details: "Detalhes da intervenção",
    intervention_order_for: "Para o",
    intervention_address: "Endereço de intervenção",
    intervention_info_supp: "Informações adicionais da intervenção",
    agency_setup_tva: "Está sujeito a IVA?",
    agency_setup_tva_placeholder: "",
    agency_setup_tva_number: "Número de IVA",
    agency_setup_tva_number_placeholder: "",
    agency_setup_tva_number_help:
      "Indique o número de IVA se estiver sujeito a IVA",
    dashboard_turnover: "O meu volume de negocios",
    dashboard_evolution_month: "Evolução durante o mês",
    dashboard_trad_month: "Traduções este mês",
    dashboard_interpretation_month: "Interpretação este mês",
    dashboard_new_missions: "Novas missões",
    dashboard_my_missions: "As minhas missões pendentes",
    dashboard_command_date: "Data de encomenda:",
    dashboard_type: "Tipo:",
    dashboard_total_sells: "Total de vendas desde a minha inscrição",
    dashboard_doc_type: "Tipo de documento mais traduzido",
    dashboard_my_clients: "O meus clientes recorrentes",
    dashboard_trad: "Tradução",
    dashboard_interpretation: "Interpretação",
    dashboard_particulier: "Particulares",
    dashboard_pro: "Profissional",
    dashboard_prestation_lang: "Os meus serviços por língua",
    dashboard_evolution_year: "Evolução durante o ano corrente",
    dashboard_status: "Estado:",
    agree: "Ao clicar, você concorda com as nossas ",
    our_term: "condições de utilização",
    and_the: "e o",
    stripe_agreement: " Contrato de Conta Conecta da Stripe. ",
    created_at: "Criado em",
    updated_at: "Actualizado em ",
    services_none_yet_subscribe: "Subscrever para activar os seus serviços",
    asser_placeholder: "Você é um(a) tradutor(a) ajuarmentado(a)?",
    agency_expl_4_expl:
      "Antes de utilizar os nossos serviços, você tem que nos mande o documento comprovatido de que é um(a) tradutor(a) ajuramentado(a) pelo organismo competente.",
    agency_expl_4_expl_1: "Pode mandá-lo por",
    agency_expl_4_expl_2:
      "Se tivera uma pregunta sobre este procedimiento, pode contactar-nos.",
    label_assermentation: "Tradução ajuramentada",
    login_main_title: "Interface do tradutor",
    login_main_subtitle:
      "Acede aos seus projetos e editor de documentos com a interface do tradutor Hierø!",
    signup_caption_title: "Hierø - Interface do tradutor",
    signup_main_subtitle:
      "Crie a sua conta, junte-se à nossa interface e torna-se visível para os nossos usuários!",
    main_header_subtitle: "Interface do tradutor",
    main_left_menu_title: "Tradutor",
    main_left_menu_section_navigation_missions: "Os meus projetos",
    main_left_menu_section_parameters_subscription: "Minha subscrição",
    agency_setup_title: "A propósito",
    order_translate_to: "ao",
    section_services_title: "Os meus serviços",
    section_tasks_title: "Os meus projetos",
    section_task_title: "Realizar uma missão",
    section_document_title: "Traduzir um documento",
    tasks_src_lang: "Língua original",
    prestation_breadcrumb_tasks: "Os meus projetos",
    prestation_order_number: "Número de encomenda:",
    prestation_next_action_title: "Etapas seguintes",
    prestation_next_action_accept_order: "Aceitar a encomenda",
    prestation_next_action_accept_order_expl:
      "Clique aqui para aceitar a encomenda. Depois, o cliente procederá ao pagamento. Uma vez efectuado o pagamento, você pode iniciar a tradução.",
    prestation_next_action_reject_order: "Rejeitar a encomenda",
    prestation_next_action_reject_order_expl:
      "Clique aqui para rejeitar a encomenda.",
    prestation_next_action_rejected: "A encomenda foi rejeitada.",
    prestation_next_action_waiting_for_payment:
      "A espera do pagamento por parte do cliente.",
    prestation_next_action_translate_expl:
      "Pode traduzir cada documento nesta encomenda, clicando no botão 'Traduzir'. Quando os documentos estiverem prontos, clique no botão 'Enviar ao cliente'. Se tiver preguntas ou dúvidas, pode  falar diretamente com o cliente via o chat.",
    prestation_next_action_translate_all_done_expl:
      "Você terminou os documentos da encomenda.",
    prestation_next_action_translate_confirm:
      "Enviar ao cliente para validação.",
    prestation_next_action_waiting_for_validation:
      "O cliente está validando a tradução.",
    prestation_next_action_validated: "Encomenda confirmada pelo cliente.",
    prestation_order_details: "Detalhes da encomenda",
    prestation_order_to: "Para",
    prestation_order_country_origin: "País de origem:",
    prestation_order_document_count: "Documentos a traduzir:",
    prestation_order_price: "Preço (sem IVA):",
    prestation_order_date: "Encomenda realizara em:",
    prestation_order_documents_title: "Documentos a traduzir",
    prestation_order_document_originals: "Originais",
    prestation_order_document_translated: "Traduzido(s)",
    prestation_order_document_not_yet_translated: "Ainda sem tradução.",
    prestation_order_document_translate: "Traduzir!",
    prestation_order_confirm_ok: "OK",
    prestation_order_confirm_cancel: "Cancelar",
    prestation_order_validate_title: "Confirmar",
    prestation_order_validate_msg:
      "Você quer confirmar a encomenda? O cliente receberá uma notificação e poderá ver os documentos traduzidos.",
    prestation_order_accept_title: "Confirmar",
    prestation_order_accept_msg: "Você quer aiceta a encomenda?",
    prestation_order_refuse_title: "Confirmar",
    prestation_order_refuse_msg: "Você quer rejeita a encomenda?",
    prestation_document_validate: "Confirmar a tradução!",
    prestation_document_show_original: "Ver o original em otra aba:",
    prestation_document_button_reset: "Reiniciar o documento",
    prestation_document_confirm_title: "Confirmar",
    prestation_document_confirm_message:
      "Você quer reiniciar a tradução? Todas as mudanças se perderão!",
    services_add_button: "Adicionar um novo serviço…",
    services_none_yet:
      "Para ser visível na plataforma Hierø, especifique os tipos de traduções que oferece.",
    services_add_expl:
      "Por favor, especifique as línguas practicadas e, depois, clique em 'Adicionar'.",
    services_add_src_lang: "Língua original",
    services_add: "Adicionar",
    services_error_add:
      "Esta combinação linguística ainda não é possível. Contacte-nos para mais informações!",
    services_dont_see_title: "Não consegue ver as minhas línguas.",
    services_dont_see_response:
      "Hierø simplifica a tradução, fornecendo modelos prontos a usar para os seus documentos. Desenvolvemos sempre os nossos modelos. Contacte-os em <a href='mailto:contact@hiero-solution.com'>contact@hiero-solution.com</a> para adicionar outras línguas.",
    services_list_title: "Minha lista de serviços",
    services_expl:
      "Clique em cada serviço para personalizar os documentos que aceita. Pode rejeitar um tipo de documento desactivando o botão, ou especificar o seu preço para traduzir este tipo de documento.",
    services_list_active: "Activado",
    services_list_country: "País",
    services_list_document_type: "Tipo de documento",
    services_list_average_price: "Preço medio (sem IVA)",
    services_list_your_price: "O seu preço (sem IVA)",
    services_list_your_price_reset: "Reiniciar",
    services_list_delete: "Excluir",
    temps_ex: "Tempo estimado",
    question_inter: "Você é um intérprete?",
    question_inter_price: "Preço para uma hora:",
    question_inter_1:
      "Realizará projetos de interpretação no nosso software para",
    calendar: " Calendário",
    calendar_hidden: "Esconder o calendário",
    interpretation_label_menu: "Interpretação",
    interpretation_label: "Interpretação",
    room_inter: "Sala de interpretação ",
    once_page_price: "O seu preço por página",
    once_page_information:
      "Convidamo-lo a indicar o seu preço para a tradução por página de um documento (250 palavras). O preço será oferecida ao cliente se o documento a ser traaduzido não estiver no nosso sistema.",
    facture_pro: "Profissional",
    /************** fin du bloc pour les phrases utilisées que sur le trad *************/

    /**************** Bloc pour les phrases utilisées sur le pro et trad ***************/
    agency_name_help:
      "Se tiver um problema, será mais fácil para a equipe encontrá-lo com seu nome e sobrenome.",
    menu_label_tel: "Pelo telefone",
    section_numero_commande: "Número de encomenda",
    dashboard_traductor_asser: "Tradução juramentada",
    dashboard_traductor_classic: "Tradução clássica",
    dashboard_month_janv: "Jan",
    dashboard_month_fevr: "Fev",
    dashboard_month_mars: "Mar",
    dashboard_month_avr: "Abr",
    dashboard_month_mai: "Maio",
    dashboard_month_juin: "Jun",
    dashboard_month_juill: "Jul",
    dashboard_month_aout: "Ago",
    dashboard_month_sept: "Set",
    dashboard_month_oct: "Out",
    dashboard_month_nov: "Nov",
    dashboard_month_dec: "Dez",
    sub_subscription: "Subscrição",
    main_header_title: "Hierø -",
    main_left_menu_section_navigation: "Menu de navegação",
    main_left_menu_section_parameters: "Configuração",
    agency_setup_explanation:
      "Hierø ofrece o seus serviços a clientes pertos da sua localização. <br> Por favor especifique o seu nome comercial e o seu endereço. <br>A informação será fornecida aos clientes quando eles estiverem à procura de um tradutor.",
    section_prestation: "Serviço",
    delai_intervention: "Duração da intervenção",
    type_doc_commande: "Tipo de documento pedido",
    price_total_ht: "Total sem IVA",
    section_dashboard_title: "Lista de indicadores",
    tasks_price: "Preço (sem IVA)",
    support_tel_es: "Tel. (ES) : (+34) 911 23 88 87",
    support_tel_en: "Tel. (EN) : (+1) 929 274 5104",
    type_intervention: "Tipo de intervenção pedida",
    once_page_price_default: "Preço sugerido (sem IVA)",
    client: "Cliente",
    /************ fin du bloc pour les phrases utilisées sur le pro et trad ************/

    /************ Bloc pour les phrases utilisées sur le trad et particulier ***********/
    modal_supp_title: "Confirmar a supressão da sua conta",
    facture_part: "Particular",
    tasks_filters: "Filtros",
    /******** fin du bloc pour les phrases utilisées sur le trad et particulier ********/

    /*************** Bloc pour les phrases utilisées sur les 3 solutions ***************/
    menu_label_event_name: "objetivo da intervenção",
    modif_profile_success_pwd: "Sua senha foi atualizada com sucesso.",
    modif_profile_failed_pwd:
      "Ocorreu um erro ao alterar sua senha. Por favor, verifique se você indicou a senha correta.",
    modal_modif_title: "Confirmar a modificação do seu correio eletrónico",
    modif_profile_failed_login:
      "Correio eletrónico ou senha incorreta. Tente novamente.",
    augmente_budget:
      "Para aumentar seu saldo, você pode nos enviar um cheque ou fazer uma transferência",
    no_budget: "Você não tem fundos no momento",
    validation_mail_registration:
      "Bem-vindo ao HIERO! Para utilizar os nossos serviços, você deve primeiro confirmar seu correio eletrónico.",
    validation_modif_mail:
      "Seu correio eletrónico foi modificado com sucesso. Para utilizar os nossos serviços, você deve confirmar seu correio eletrónico novamente.",
    validation_question:
      "Não recebeu o correio de confirmação? Clique no botão para enviar novamente o correio de confirmação.",
    validation_mail_resend: "Enviar o correio de confirmação novamente",
    message_verification:
      "Por favor, valide seu correio eletrónico e verifique a sua caixa de entrada.",
    non_valid_address: "Correio eletrónico não confirmado",
    validation_mail_success: "Correio de confirmação enviado com sucesso.",
    account_error_invalid_email: "O correio eletrónico não é válido.",
    account_error_min_password: "8 caracteres mínimo",
    account_error_passwords_do_not_match: "As senhas não correspondem",
    account_confirm: "Confirmar sua senha",
    modif_profile_success: "O seu perfil foi actualizado",
    modif_profile_failed:
      "Ocorreu um erro durante a edição do seu perfil. Por favor, confira a sua informação e tente novamente.",
    facture_echeance: "Data de vencimento",
    facture_conditions: "Pagamento",
    facture_comptant: "A vista",
    facture_adress: "Endereço de facturação",
    facture_qte: "Qtd",
    facture_price: "Preço",
    facture_unitaire: "por Unidade",
    facture_montant: "Quantia",
    facute_solde: "Total",
    facture_tva_fr: "IVA FR 20%",
    order_language_from: "Do",
    order_language_to: "Al",
    account_hist_pro_date: "Data de intervenção",
    select_choose: "Escolher...",
    tasks_price_tva: "IVA",
    order_no_asser_document_name: "Nom do documento",
    menu_label_traductor_asser: "Tradução juramentada",
    menu_label_traductor_classic: "Tradução clássica",
    menu_label_interprestation: "Interpretação",
    menu_label_all: "Ver todo",
    login_caption_title: "Hierø - Interface do tradutor",
    login_caption_subtitle:
      "Um programa informático conceibdo pelo tradutores para tradutores.",
    login_main_logo: "Hierø",
    login_main_connect: "Conexão",
    login_main_email: "Correio eletrónico",
    login_main_email_placeholder: "Entra o correio eletrónico",
    login_main_email_help: "Lançar uma sessão com o correio eletrónico",
    login_main_password: "Senha",
    login_main_password_placeholder: "Entra a sua senha",
    login_main_password_help: "",
    login_main_button: "Conexão",
    login_main_to_signup: "Não tem uma conta? Inscreva-se",
    login_main_copyright: "© Hierø - HIERØ 2022",
    login_error_required_field: "Campo obrigatório",
    login_error_invalid_email: "O correio eletrónico é invalido.",
    login_error_email_not_found: "O correio eletrónico não existe.",
    signup_caption_subtitle: "Escolhe Hierø e torne-se visível!",
    signup_main_logo: "Hierø",
    signup_main_title: "Inscrição",
    signup_main_email: "Correio eletrónico",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Senha",
    signup_main_password_placeholder: "",
    signup_main_password_help: "",
    signup_main_confirm: "Confirmar a sua senha",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Sobrenome",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Nome",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Telefone",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "Subscrever",
    signup_main_to_signup: "Você tem uma conta? Iniciar a sessão.",
    signup_main_copyright: "© Hierø - HIERØ 2022",
    signup_error_required_field: "Campo obrigatório",
    signup_error_invalid_email: "O correio eletrónico é invalido ",
    signup_error_min_password: "8 caracteres mínimo",
    signup_error_max_password: "30 caracteres máximo",
    signup_error_passwords_do_not_match: "As senhas não correspondem",
    login_forgot_password: "Esqueceu-se da sua senha?",
    reset_password_main: "Reiniciar a senha",
    reset_password_email_title: "Correio eletrónico",
    reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Enviar",
    reset_password_login: "Conexão",
    reset_password_success:
      "Um correio eletrónico foi mandado no seu endereço. Clique no link para mudar a senha.",
    main_left_menu_section_parameters_agency: "A minha empresa",
    main_left_menu_section_parameters_services: "Os meus serviços",
    main_left_menu_section_parameters_user: "A minha conta",
    main_left_menu_section_parameters_support: "Soporte",
    reset_password_error_required: "Campo obrigatório",
    reset_password_error_invalid_email: "O correio eletrónico é invalido.",
    reset_password_error_user_not_found: "O correio eletrónico não existe.",
    main_header_user_menu_logout: "Desconectar",
    agency_setup_companyname: "Nome comercial",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help:
      "O nome comercial a utilizar para encomendas, faturas, etc.",
    agency_setup_address: "Endereço",
    agency_setup_siret: "NIF/SIRET",
    agency_setup_siret_placeholder: "",
    agency_setup_siret_help: "Entra o seu NIF/SIRET se for uma empresa",
    agency_setup_address_placeholder: "",
    agency_setup_address_help:
      "Escreve o endereço e, depois, escolhe o endereço apropriado.",
    agency_setup_addresscompl: "Complemento de endereço",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help:
      "Edificio, escadaria, código de acceso, etc. Quando os seus clientes recolhem os documentos traduzidos, precisam de ter toda a informação.",
    agency_setup_tel: "Telefone",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help:
      "O número de teléfono a utilizar para conversar com os clientes.",
    agency_setup_email: "Correio eletrónico",
    agency_setup_email_placeholder: "",
    agency_setup_email_help:
      "O correio eletrónico a utilizar para conversar com os clientes. Por favor, entra otro correio se não for o de registo de Hierø.",
    agency_setup_save: "Guardar",
    agency_setup_error_required_field: "Campo obrigatório",
    agency_setup_error_invalid_email: "O correio eletrónico é invalido.",
    agency_setup_error_valid_address:
      "Por favor, escolhe o endereço apropriado.",
    agency_address_not_found: "Não consegue ver o seu endereço?",
    agency_address_title: "Entrar manualmente os dados",
    agency_address_address: "Endereço:",
    agency_address_number: "N.°",
    agency_address_street: "Estrada",
    agency_address_code: "Código postal",
    agency_address_city: "Cidade",
    agency_address_country: "País",
    agency_address_coords: "Coordenadas GPS:",
    agency_address_coords_lat: "Latitude",
    agency_address_coords_long: "Longitude",
    agency_address_cancel: "Cancelar",
    agency_address_validate: "Validar",
    main_left_menu_section_navigation_dashboard: "Lista de indicadores",
    main_menu_left_section_navigation_factures: "As minhas faturas",
    head_facture: "Fatura",
    section_date: "Data",
    section_numero_facture: "Fatura",
    section_type_prestation: "Tipo de serviço",
    section_price: "Total",
    section_print: "Imprimir",
    section_generate_facture: "Gerar a fatura em PDF",
    section_consulter: "Ver",
    prestation: "Serviço",
    commande_qte: "Quantidade",
    commande_produit: "Produto",
    commande_desc: "Descrição",
    total_ht: "Total sem IVA",
    total_tva: "Total com IVA",
    total_ttc: "Total, impostos incluidos",
    retour_btn: "Voltar",
    progress_commande: "Seguimento das encomendas",
    commande_numero: "N.º de encomenda",
    capital_sasu: "SASU com un capital social de 7 000,00 euros",
    rcs_infos: "RCS PARIS B 843 119 728 ",
    siret: "SIRET 84311972800031",
    tva_fr: "Número de IVA: FR00843119728",
    condition_paragraphe_1:
      "Penalidades por atraso no pagamento (taxa anual): 10%",
    condition_paragraphe_2: "Sem desconto para pagamento antecipado",
    condition_paragraphe_3:
      "Indemnização fixa para despesas de recuperação se tiver atraso de pagamento: 40€",
    prestataire: "Fornecedor",
    my_info: "A minha informação",
    geolocation_paragraphe:
      "O software precisa de saber a sua localização para procurar tradutores perto de si",
    update_password: "Mudar a minha senha",
    account_upt: "Você pode mudar a sua senha.",
    send: "Enviado",
    close_modal: "Feichar",
    inscription_date: "Registado desde ",
    order_translate: "de ",
    montant_total_euro: "Total em EUR",
    rules_conditions: "TERMOS DE PAGAMENTO",
    rules_conditions_p: "Pagamnto a recepção da fatura",
    section_agency_title: "A minha empresa",
    section_account_title: "A minha conta",
    section_support_title: "Soporte",
    notifications_new_task: "Recebeu uma nova missão",
    notifications_task_confirmed:
      "A missão foi confimada. Vôce pode iniciar a tradução.",
    notifications_task_validated: "O cliente validou a missão.",
    tasks_sent_on: "Enviado em",
    tasks_src_country: "País de origem",
    tasks_dst_country: "Traduzir para",
    tasks_state: "Estado",
    state_cancelled: "Cancelado pelo cliente",
    state_waiting_for_translator: "Novo",
    state_waiting_for_payment: "A espera do pagamento",
    state_refused_by_translator: "Missão rejeitada",
    state_translating: "Tradução pendente",
    state_waiting_for_client_validation:
      "Terminada. A espera da validação pelo cliente.",
    state_validated: "Validado pelo cliente",
    state_validated_pro: "Validado",
    prestation_order_from: "De",
    prestation_order_document_type: "Tipo de documento",
    prestation_order_messaging_title: "Chat",
    prestation_order_messaging_send: "Enviar",
    prestation_order_messaging_client: "Cliente:",
    prestation_order_messaging_me: "Eu:",
    prestation_document_edit: "Editor de documento",
    prestation_document_edit_expl:
      "O texo no campo acima está sublinhado no documento abaixo. Quanto entra o texto tradzido, o documento será actualizado automaticamente. Utilize as setas para navegar no documento.",
    prestation_document_get_browser:
      "Você não consegue ver o PDF? Por favor, use um navegador como",
    prestation_document_confirm_oui: "Sim",
    prestation_document_confirm_non: "Não",
    account_save: "Guardar",
    account_surname: "Sobrenome",
    account_surname_placeholder: "",
    account_name: "Nome",
    account_name_placeholder: "",
    account_telephone: "Telefone",
    account_telephone_placeholder: "",
    account_adresse: "Endereço",
    account_adresse_placeholder: "Endereço",
    account_complement_adr: "Complemento de endereço",
    account_complement_adr_placeholder: "Complemento de endereço",
    account_email: "Correio eletrónico",
    account_email_placeholder: "Correio eletrónico",
    account_password: "Senha atual",
    account_new_password: "Nova senha",
    account_error_required: "Campo obrigatório",
    agency_expl_1_title: "Nome comercial",
    agency_expl_1_expl:
      "Entra o nome da sua empresa para que os clientes o possam encontrar.",
    agency_expl_2_title: "Localização",
    agency_expl_2_expl:
      "O software precisa da sua informação para o pôr em contacto com os clientes mais pertos.",
    agency_expl_3_title: "Ativar a sua conta",
    agency_expl_3_expl:
      "O seu perfil será visível on-line, quando guarde a informação de contacto.",
    services_dst_lang: "Ao",
    support_title: "Os nossos escritórios",
    support_company: "Hiero",
    support_addr1: "83 boulevard de Paris",
    support_addr2: "75010 Paris",
    support_addr3: "França",
    support_contact: "Contacto",
    support_tel: "Tel.: (+34) 911 23 88 87",
    support_email: "contact@hiero-solution.com",
    not_found_title: "Elemento não encontrado",
    not_found_subtitle: "A página pedida não existe.",
    not_found_action: "Contactar o soporte ",
    not_found_home: "Inicio",
    menu_label_sur_place: "No sitio",
    menu_label_visio: "Por videoconferência",
    consulter_facture: "Ver as minhas faturas",
    facture_numero: "Fatura n.°",
    recap_tva: "RESUMO DA IVA",
    recap_taux: "TASA",
    recap_mt_tva: "TOTAL COM IVA",
    recap_base_ht: "BASE SEM IVA",
    action: "Acção",
    hours: "Horas",
    join_room: "Entrar na sala",
    rooms: "As salas (salas de reunião)",
    warning: "Atenção! ",
    conf: "A conferência",
    warning_1: "A ortografia dos nomes das salas deve ser respeitadas.",
    interpretation_info: "Explicação",
    interpretation_paragraphe_1:
      "Para participar na missão de interpretação, são necessárias pelo menos duas abas. Primeiro estará com o intérprete, e depois com todos os participantes.",
    interpretation_paragraphe_2:
      "Para aceder a sala de conferência, o nome da sala a entrar corresponde a ",
    interpretation_paragraphe_3: ". Todos os participantes estão nesta sala.",
    interpretation_paragraphe_4:
      "Para aceder a sala de interpretação, o nome da sala a entrar corresponde a ",
    interpretation_paragraphe_5:
      ". Os oradores e o intérprete escolhido estão nesta sala.",
    interpretation_paragraphe_6:
      "Para realizar correctamente a missão de interpretação, é ncessário que o orador e o intérprete não falem ao mesmo tempo na mesma sala.",
    interpretation_paragraphe_7:
      "Por isso, eles estão em duas salas diferentes. Assim, os participantes podem ouvir o intérprete na sala de confêrencia, e vôce pode ouvir os oradores na sala de interpretação.",
    interpretation_paragraphe_8:
      "Por razões de desempenho, a capacidade máxima de uma sala é de 50 participantes. Pode criar tantas salas quantas quiser e comunicar os nomes dessas salas ao seu intérprete.",
    room_conf: " Sala de conferência",
    traduction_assermentee: "Tradução juramentada",
    traduction_classic: "Tradução clássica",
    interpretation: "Interpretação",
    AF: "Afganistán",
    ZA: "Sudáfrica",
    AL: "Albania",
    DZ: "Argelia",
    DE: "Alemania",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguila",
    AQ: "Antártida",
    AG: "Antigua y Barbuda",
    AN: "Antillas Neerlandesas",
    SA: "Arabia Saudita",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaiyán",
    BS: "Bahamas",
    BH: "Baréin",
    BD: "Bangladés",
    BB: "Barbados",
    BY: "Belarús",
    BE: "Bélgica",
    BZ: "Belice",
    BJ: "Benín",
    BM: "Bermudas",
    BT: "Bután",
    BO: "Bolivia",
    BA: "Bosnia y Herzegovina",
    BW: "Botsuana",
    BR: "Brasil",
    BN: "Brunéi",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Camboya",
    CM: "Camerún",
    CA: "Canadá",
    CV: "Cabo Verde",
    EA: "Ceuta y Melilla",
    CL: "Chile",
    CN: "China",
    CY: "Chipre",
    CO: "Colombia",
    KM: "Comoras",
    CG: "República del Congo",
    KP: "Corea del Norte",
    KR: "Corea del Sur",
    CR: "Costa Rica",
    CI: "Costa de Marfil",
    HR: "Croacia",
    CU: "Cuba",
    DK: "Dinamarca",
    DG: "Diego García",
    DJ: "Yibuti",
    DM: "Dominica",
    EG: "Egipto",
    SV: "El Salvador",
    AE: "Emiratos Árabes Unidos",
    EC: "Ecuador",
    ER: "Eritrea",
    ES: "España",
    EE: "Estonia",
    VA: "Estado de la Ciudad del Vaticano",
    FM: "Estados Federados de Micronesia",
    US: "Estados Unidos",
    ET: "Etiopia",
    FJ: "Fiyi",
    FI: "Finlandia",
    FR: "Francia",
    GA: "Gabón",
    GM: "Gambia",
    GE: "Georgia",
    GS: "Islas Georgias del Sur y Sándwich del Sur",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Grecia",
    GD: "Granada",
    GL: "Groenlandia",
    GP: "Guadalupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GQ: "Guinea ecuatorial",
    GW: "Guinea-Bisáu",
    GY: "Guyana",
    GF: "Guayana Francesa",
    HT: "Haití",
    HN: "Honduras",
    HU: "Hungría",
    BV: "Isla Bouvet",
    CX: "Isla de Navidad",
    CP: "Isla Clipperton",
    AC: "Isla Ascensión",
    IM: "Isla de Man",
    NF: "Isla Norfolk",
    AX: "Islas Åland",
    KY: "Islas Caimán",
    IC: "Islas Canarias",
    CC: "Islas Cocos / Islas Keeling",
    CK: "Islas Cook",
    FO: "Islas Feroe",
    HM: "Islas Heard y McDonald",
    FK: "Islas Malvinas",
    MP: "Islas Marianas del Norte",
    MH: "República de las Islas Marshall",
    UM: "Islas Ultramarinas Menores de los Estados Unidos",
    SB: "Islas Salomón",
    TC: "Islas Turcas y Caicos",
    VG: "Islas Vírgenes Británicas",
    VI: "Islas Vírgenes de os Estados Unidos",
    IN: "India",
    ID: "Indonesia",
    IQ: "Irak",
    IR: "Irán",
    IE: "Irlanda",
    IS: "Islandia",
    IL: "Israel",
    IT: "Italia",
    JM: "Jamaica",
    JP: "Japón",
    JE: "Jersey",
    JO: "Jordania",
    KZ: "Kazajistán",
    KE: "Kenia",
    KG: "Kirguistán",
    KI: "Kiribati",
    KW: "Kuwait",
    LA: "Laos",
    LS: "Lesoto",
    LV: "Letonia",
    LB: "Líbano",
    LR: "Liberia",
    LY: "Libia",
    LI: "Liechtenstein",
    LT: "Lituania",
    LU: "Luxemburgo",
    MK: "Macedonia",
    MG: "Madagascar",
    MY: "Malasia",
    MW: "Malaui",
    MV: "Maldivas",
    ML: "Malí",
    MT: "Malta",
    MA: "Marruecos",
    MQ: "Martinica",
    MU: "Mauricio",
    MR: "Mauritania",
    YT: "Mayotte",
    MX: "México",
    MD: "Moldavia",
    MC: "Mónaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NI: "Nicaragua",
    NE: "Níger",
    NG: "Nigeria",
    NU: "Niue",
    NO: "Noruega",
    NC: "Nueva Caledonia",
    NZ: "Nueva Zelandia",
    OM: "Omán",
    UG: "Uganda",
    UZ: "Uzbekistán",
    PK: "Pakistán",
    PW: "Palaos",
    PA: "Panamá",
    PG: "Papúa Nueva Guinea",
    PY: "Paraguay",
    NL: "Países Bajos",
    PE: "Perú",
    PH: "Filipinas",
    PN: "Islas Pitcairn",
    PL: "Polonia",
    PF: "Polinesia Francesa",
    PR: "Puerto Rico",
    PT: "Portugal",
    QA: "Catar",
    HK: "R.A.E de Hong Kong de la República Popular China",
    MO: "R.A.E. de Macao",
    QO: "Oceanía Lejana",
    CF: "República Centroafricana",
    CD: "República Democrática del Congo",
    DO: "República Dominicana",
    CZ: "República Checa",
    RE: "Reunión",
    RO: "Rumanía",
    GB: "Reino Unido",
    RU: "Rusia",
    RW: "Ruanda",
    EH: "Sahara Occidental",
    BL: "San Bartolomé",
    KN: "San Cristóbal y Nieves",
    SM: "San Marino",
    MF: "Isla San Martín",
    PM: "San Pedro y Miquelón",
    VC: "San Vicente y las Granadinas",
    SH: "Isla Santa Elena",
    LC: "Santa Lucia",
    WS: "Samoa",
    AS: "Samoa Americana",
    ST: "Santo Tomé y Príncipe",
    SN: "Senegal",
    RS: "Serbia",
    CS: "Serbia y Montenegro",
    SC: "Seychelles",
    SL: "Sierra Leona",
    SG: "Singapur",
    SK: "Eslovaquia",
    SI: "Eslovenia",
    SO: "Somalia",
    SD: "Sudán",
    LK: "Sri Lanka",
    SE: "Suecia",
    CH: "Suiza",
    SR: "Surinam",
    SJ: "Svalbard y Jan Mayen",
    SZ: "Suazilandia",
    SY: "Siria",
    TJ: "Tayikistán",
    TW: "Taiwán",
    TZ: "Tanzania",
    TD: "Chad",
    TF: "Tierras Australes y Antárticas Francesas",
    IO: "Territorio Británico del Océano Índico",
    PS: "Territorios Palestinos",
    TH: "Tailandia",
    TL: "Timor Oriental",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad y Tobago",
    TA: "Tristán da Cunha",
    TN: "Túnez",
    TM: "Turkmenistán",
    TR: "Turquía",
    TV: "Tuvalu",
    UA: "Ucrania",
    EU: "Unión Europea",
    UY: "Uruguay",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    WF: "Wallis y Futuna",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabue",
    /************ fin du bloc pour les phrases utilisées sur les 3 solutions ***********/

    /******************** Bloc pour les phrases utilisées null part ********************/
    order_asser_or_not: "Precisa de uma tradução juramentada",
    dashboard_pro_command_company_priceHT: "Preço sem IVA:",
    new_orders: "Fazer uma nova encomenda",
    state_pro_wait: "Pendente",
    account_expl_pro_cheque: "Por favor mandar um cheque a Hiero",
    account_expl_pro_viremement: "Transferências ",
    account_expl_pro_iban: "IBAN PT 000",
    account_hist_pro_debit: "Débito ",
    account_hist_pro_credit: "Crédito ",
    account_hist_pro_way: "Médio",
    account_hist_pro_ref: "Referência ",
    agency_pro_budget_help:
      "O orçamento que deseja atribuir às suas traduções.",
    sub_training: "Formação de equipa",
    sub_search: "Pesquisa automática deo tradutor/intérprete",
    sub_geo: "Geolocalização do tradutor e/ou intérprete",
    sub_dem: "Desmaterialização das encomendas",
    sub_save: "Cópia de segurança dos documentos com gestão de arquivos",
    sub_recap: "Resumo das encomendas por mês",
    sub_proj: "Progresso dos projetos em tempo real",
    sub_management: "Gestão orçamental em tempo real",
    sub_message: "Chat",
    sub_send: "Entrega das traduções por correio eletrónico",
    sub_impl: "Execução do orçamento atribuído para 12 meses",
    sub_account: "Account manager ao seu lado",
    sub_orientation: "Orientação personalizada com as línguas mais solicitadas",
    sub_split:
      "Divisão do orçamento nos departamentos da empresa, de acordo com os usúarios",
    sub_event: "Entrega das traduções por mensageiro(a)",
    choose: "Escolher",
    sub_function: "Características",
    sub_standard: "Standard",
    sub_essen: "Essencal",
    sub_premium: "Premium",
    sub_month: "mês",
    sub_associations_label: "Associações",
    sub_cabinet_label: "Escritórios de advogados",
    sub_bank_label: "Mobilidade internacional",
    sub_academia_label: "Instituições Académicas",
    sub_who_are_you: "Você é…?",
    sub_explanation_academia:
      "Queremos trabalhar eme colaboração com instituições académicas, tais como universidades.",
    sub_explanation_systeme: "Oferecemos dois sistemas diferentes: ",
    sub_system_one:
      "Utilizar a nossa aplicação móvel se a institução académica não pagar as traduções.",
    sub_system_two:
      "Negociar a implementação da interface profissional se a institução académica paga as traduções.",
    sub_credit_card_message:
      "Deve pagar a sua subscrição para ativar os seus serviços. Na aba",
    validate_credit_card_message: "A sua encomenda foi processada",
    subscriber_choose: "Escolha a sua subscrição. ",
    find_out_more: "Mais informações",
    menu_label_relecture: "Revisão",
    signup_error_email_not_found: "O correio eletrónico não existe.",
    main_left_menu_section_accueil: "Início",
    agency_setup_email_help_pro:
      "O correio eletrónico para corresponder com os tradutores e a equipa Hierø.",
    main_menu_left_section_navigation_entreprise: "A minha empresa",
    main_menu_left_section_navigation_messagerie: "As minhas mensagens",
    main_menu_left_section_navigation_gestion_des_fichiers:
      "Gestão de arquivos",
    montant_total: "Quantia total",
    single_commande_p1: "O tradutor descarrega o documento para tradução. ",
    single_commande_p2:
      "Após a tradução, adiciona-a à encomenda para que possa validar a tradução e confirmar a encomenda.",
    load_intervention: "Intervenção em curso",
    commande_statut: "Estado da encomenda",
    commande_details: "Detalhes da encomenda",
    trad_name: "Nome do tradutor",
    visioner: "Ver",
    infos_interv: "Informação adicional sobre a partcipação",
    intervention_time_date: "Data e hora de intervenção",
    loading_order: "A minhas ecomendas de tradução pendentes",
    view_all_orders: "Ver todas as encomendas",
    last_payment: "Os meus últimos pagamentos realizados",
    view_all_payment: "Ver todos os meus pagamentos",
    last_send_msg: "Últimas mensagens enviadas",
    state_accept_order: "Encomenda aceita",
    account_expl: "Entrar os dados para ser encontrado.",
    account_surname_help: "",
    account_name_help: "",
    account_telephone_help: "",
    account_poste_help: "",
    account_adresse_help: "",
    account_complement_adr_help: "",
    account_email_help: "",
    account_password_help: "",
    account_new_password_help: "",
    not_found_action1: "se o problema persistir. ",
    menu_label_interpretation: "Pedir uma interpretação ",
    table_header_statut: "Estado",
    head_professionnal:
      "O software precisa de saber a sua localização para procurar tradutores perto de sin",
    head_change_password: "Se desejar, pode mudar a sua senha",
    recap_tva_fr: "FR IVA 20%",
    client_particulier_name: "Nome comercial",
    client_particulier_email: "Correo eletrónico",
    client_particulier_address: "Endereço ",
    client_particulier_code_postal: "Cidade",
    particulier_name: "Nombe do particular",
    infos_entrep: "",
    infos_commandes: "",
    address_facturation: " Mudar o meu endereço de facturação.",
    /***************** fin du bloc pour les phrases utilisées null part ****************/
  },
  de: {
    /***************** Bloc pour les phrases utilisées que sur le trad *****************/
    dashboard_status: "Status :",
    agree: "Akzeptieren ",
    our_term: "Unsere Konditionen",
    and_the: "und der",
    stripe_agreement: " Vertrag Stripe. ",
    created_at: "Erstellt am ",
    updated_at: "Aktualisiert am ",
    services_none_yet_subscribe:
      "Abonnieren Sie sich um Ihre Dienstleistungen freizuschalten",
    asser_placeholder: "Sind Sie ein:e vereidigte:r Übersetzer:in?",
    agency_expl_4_expl:
      " Bevor Sie unsere Dienste nutzen können, ist es notwendig, dass Sie einen Nachweis Ihrer Vereidigung erbringen. ",
    agency_expl_4_expl_1: " Senden Sie eine E-Mail : ",
    agency_expl_4_expl_2:
      "Bei Fragen zu diesem Verfahren, zögern Sie nicht und kontaktieren Sie uns. ",
    label_assermentation: " Vereidigung ",
    login_main_title: "Interface Übersetzer / Dolmetscher",
    login_main_subtitle:
      " Behalten Sie die Kontrolle über Ihre Aufträge und Übersetzungen dank dem HIERO Interface Übersetzer / Dolmetscher!",
    signup_caption_title: "Hierø - Interface Übersetzer",
    signup_main_subtitle:
      " Erstellen Sie Ihren Zugang, nutzen Sie unser Interface und werden Sie sichtbar für eine Vielzahl an Nutzern!",
    main_header_subtitle: "Interface Übersetzer / Dolmetscher",
    main_left_menu_title: "Übersetzer / Dolmetscher",
    main_left_menu_section_navigation_missions: "Meine Aufträge",
    main_left_menu_section_parameters_subscription: "Mein Abonnement",
    agency_setup_title: "Über Ihre Agentur",
    order_translate_to: "ins ",
    section_services_title: "Meine Dienstleistungen",
    section_tasks_title: "Meine Aufträge",
    section_task_title: "Auftrag ausführen",
    section_document_title: "Dokument übersetzen",
    tasks_src_lang: "Ausgangssprache",
    prestation_breadcrumb_tasks: "Meine Aufträge",
    prestation_order_number: "Auftragsnummer:",
    prestation_next_action_title: "Nächste Schritte",
    prestation_next_action_accept_order: "Auftrag akzeptieren",
    prestation_next_action_accept_order_expl:
      " Klicken Sie hier, um die Bestellung zu akzeptieren. Der Kunde wird dann mit der Zahlung fortfahren. Sobald die Zahlung eingegangen ist, können Sie mit der Übersetzung beginnen.",
    prestation_next_action_reject_order: "Auftrag ablehnen",
    prestation_next_action_reject_order_expl:
      "Klicken Sie hier dem Auftrag abzulehnen.",
    prestation_next_action_rejected: "Sie haben den Auftrag abgelehnt",
    prestation_next_action_waiting_for_payment:
      "Warten auf die Zahlung des Kunden.",
    prestation_next_action_translate_expl:
      "Bitte übersetzen Sie jedes Dokument dieser Bestellung, indem Sie auf die Schaltfläche 'Übersetzen' klicken. Wenn alle Dokumente fertig sind, klicken Sie auf die Schaltfläche 'senden'. Bei Fragen oder Unklarheiten können Sie direkt mit dem Kunden über das Instant Messaging kommunizieren.",
    prestation_next_action_translate_all_done_expl:
      " Sie haben alle Dokumente des Auftrags erledigt.",
    prestation_next_action_translate_confirm:
      "Senden zur Bestätigung des Kunden",
    prestation_next_action_waiting_for_validation:
      "Der Kunde bestätigt momentan die Übersetzung.",
    prestation_next_action_validated: "Auftrag bestätigt durch den Kunden ",
    prestation_order_details: "Detail des Auftrags",
    prestation_order_to: "an",
    prestation_order_country_origin: "Ursprungsland:",
    prestation_order_document_count: "Zu übersetzendes Dokument:",
    prestation_order_price: "Preis (ohne MwSt.):",
    prestation_order_date: "Auftrag erstellt am:",
    prestation_order_documents_title: "Zu bearbeitendes Dokument",
    prestation_order_document_originals: "Originale",
    prestation_order_document_translated: "Übersetzung(en)",
    prestation_order_document_not_yet_translated: "noch nicht übersetzt",
    prestation_order_document_translate: "Übersetzen!",
    prestation_order_confirm_ok: "Ok",
    prestation_order_confirm_cancel: "Stornieren",
    prestation_order_validate_title: "Bestätigt",
    prestation_order_validate_msg:
      " Sind Sie sicher den Auftrag bestätigen zu wollen? Der Kunde erhält somit eine Benachrichtigung und folglich Zugriff auf die übersetzten Dokumente.",
    prestation_order_accept_title: "Bestätigen",
    prestation_order_accept_msg:
      "Sind Sie sicher den Auftrag akzeptieren zu wollen?",
    prestation_order_refuse_title: "Bestätigen",
    prestation_order_refuse_msg:
      "Sind Sie sicher den Auftrag ablehnen zu wollen?",
    prestation_document_validate: "Übersetzung bestätigen!",
    prestation_document_show_original:
      " Original in einem anderen Fenster ansehen:",
    prestation_document_button_reset: "Initialisierung des Dokuments",
    prestation_document_confirm_title: "Bestätigen",
    prestation_document_confirm_message:
      " Sind Sie sicher die Übersetzung initialisieren zu wollen? Alle Ihre Änderungen gehen so verloren!",
    services_add_button: "Neues Angebot hinzufügen...",
    services_none_yet:
      " Um auf der HIERO Plattform sichtbar zu sein, geben Sie präzise Ihre Dienstleistungen an. ",
    services_add_expl:
      " Geben Sie Ihre Sprachen an und klicken Sie auf ‘Hinzufügen’.",
    services_add_src_lang: "Ausgangssprache",
    services_add: "Hinzufügen!",
    services_error_add:
      " Die ausgewählte Sprachkombination ist momentan nicht möglich. Kontaktieren Sie uns für mehr Informationen!",
    services_dont_see_title: "Ich kann meine Sprachen nicht aufrufen.",
    services_dont_see_response:
      " Hierø erleichtert Ihnen Ihre Übersetzungsarbeit, indem wir Ihnen gebrauchsfertige Vorlagen für Ihre Dokumente zur Verfügung stellen. Wir entwickeln unser Dokumentenangebot ständig weiter. Kontaktieren Sie uns <a href='mailto:contact@hiero-solution.com'>contact@hiero-solution.com</a> um zusätzliche Sprachen anzufordern.",
    services_list_title: "Ihre Dienstleistungen",
    services_expl:
      "Klicken Sie auf jedes Angebot, um die von Ihnen verwalteten Dokumente anzupassen. Sie können entweder einen bestimmten Dokumententyp durch Deaktivieren des Buttons ablehnen oder Ihren persönlichen Preis für die Bearbeitung dieses Dokumententyps angeben.",
    services_list_active: "Aktiviert",
    services_list_country: "Land",
    services_list_document_type: "Dokumentenart",
    services_list_average_price: "Durchschnittspreis (ohne MwSt.)",
    services_list_your_price: "Ihr Preis (ohne MwSt.)",
    services_list_your_price_reset: "Initialisierung",
    services_list_delete: "Löschen",
    temps_ex: "geschätzte Dauer",
    question_inter: " Sind Sie Dolmetscher?",
    question_inter_price: "Preis pro Stunde:",
    question_inter_1: " Sie dolmetschen über unsere Software um ",
    calendar: " Kalender",
    calendar_hidden: "Kalender verbergen",
    interpretation_label_menu: "Dolmetschen",
    interpretation_label: "",
    room_inter: "Dolmetscherraum",
    once_page_price: "Ihr Tarif pro Seite",
    once_page_information:
      " Wir bitten Sie, Ihren Preis für die Übersetzung pro Seite eines Dokuments (250 Wörter) zu berechnen. Dieser Tarif wird dem Kunden angeboten, wenn das Dokument, das er übersetzen möchte, nicht von unserem System registriert ist.",
    /************** fin du bloc pour les phrases utilisées que sur le trad *************/

    /**************** Bloc pour les phrases utilisées sur le pro et trad ***************/
    dashboard_traductor_asser: "Beeidigte Übersetzung",
    dashboard_traductor_classic: "Klassische Übersetzung",
    dashboard_month_janv: "Jan",
    dashboard_month_fevr: "Feb",
    dashboard_month_mars: "März",
    dashboard_month_avr: "Apr",
    dashboard_month_mai: "Mai",
    dashboard_month_juin: "Juni",
    dashboard_month_juill: "Juli",
    dashboard_month_aout: "Aug",
    dashboard_month_sept: "Sept",
    dashboard_month_oct: "Okt",
    dashboard_month_nov: "Nov",
    dashboard_month_dec: "Dez",
    sub_subscription: "Abonnement",
    main_header_title: "Hierø -",
    main_left_menu_section_navigation: "Navigation",
    main_left_menu_section_parameters: "Einstellung",
    agency_setup_explanation:
      " HIERO erleichtert die Sichtbarkeit Ihrer Dienstleistungen für potenzielle Kunden in Ihrer Nähe. <br> Bitte geben Sie Ihren Firmennamen und Ihre E-Mail-Adresse an. <br> Diese Informationen werden den Kunden angeboten, wenn sie einen Übersetzer suchen.",
    section_prestation: "Leistung",
    delai_intervention: "Dauer der Intervention",
    type_doc_commande: "Angefragte Dokumentenart",
    price_total_ht: "Gesamtpreis (ohne MwSt.)",
    section_dashboard_title: "Dashboard",
    tasks_price: "Preis (ohne MwSt.)",
    support_tel_es: "Tel : (+34) 911 23 88 87",
    support_tel_en: "Tel : (+1) 929 274 5104",
    type_intervention: "Art der angefragten Mission",
    once_page_price_default: "Preis (ohne MwSt.)",
    client: "Kunde",
    /************ fin du bloc pour les phrases utilisées sur le pro et trad ************/

    /************ Bloc pour les phrases utilisées sur le trad et particulier ***********/
    tasks_filters: "Filter",
    /******** fin du bloc pour les phrases utilisées sur le trad et particulier ********/

    /*************** Bloc pour les phrases utilisées sur les 3 solutions ***************/
    menu_label_event_name: "Name der Intervention",
    account_error_invalid_email: "Die eingegebene E-Mail-Adresse ist ungültig.",
    account_error_min_password: "Mindestens 8 Zeichen",
    account_error_passwords_do_not_match:
      "Die Passwörter stimmen nicht überein",
    account_confirm: "Bestätigen Sie Ihr Passwort",
    order_language_from: "Von",
    order_language_to: "Nach",
    account_hist_pro_date: "Datum der Mission",
    select_choose: "Wählen Sie...",
    tasks_price_tva: "MwSt.",
    order_no_asser_document_name: "Dokumentenname",
    menu_label_traductor_asser: "Beeidigte Übersetzung",
    menu_label_traductor_classic: "Klassische Übersetzung",
    menu_label_interprestation: "Dolmetscher",
    menu_label_all: "Alle anzeigen",
    login_caption_title: "Hierø - Interface Übersetzer / Dolmetscher",
    login_caption_subtitle:
      "Die Software entwickelt von Übersetzern für Übersetzer.",
    login_main_logo: "Hierø",
    login_main_connect: "Anmelden",
    login_main_email: "E-Mail",
    login_main_email_placeholder: "E-Mail-Adresse einfügen",
    login_main_email_help: "Melden Sie sich mit Ihrer E-Mail-Adresse an.",
    login_main_password: "Password",
    login_main_password_placeholder: "Geben Sie Ihr Passwort ein",
    login_main_password_help: "",
    login_main_button: "Anmelden",
    login_main_to_signup: "Noch nicht registriert? Registrieren Sie sich!",
    login_main_copyright: "© Hierø - HIERØ 2020",
    login_error_required_field: "Pflichtfeld",
    login_error_invalid_email: " Die eingegebene E-Mail-Adresse ist ungültig.",
    login_error_email_not_found: "Die E-Mail-Adresse existiert nicht.",
    signup_caption_subtitle:
      "Wählen Sie Hierø und erweitern Sie Ihren Horizont!",
    signup_main_logo: "Hierø",
    signup_main_title: "Registrierung",
    signup_main_email: "E-Mail-Adresse",
    signup_main_email_placeholder: "",
    signup_main_email_help: "",
    signup_main_password: "Passwort",
    signup_main_password_placeholder: "",
    signup_main_password_help: "",
    signup_main_confirm: "Bestätigen Sie Ihr Passwort",
    signup_main_confirm_placeholder: "",
    signup_main_confirm_help: "",
    signup_main_surname: "Name",
    signup_main_surname_placeholder: "",
    signup_main_surname_help: "",
    signup_main_name: "Vorname",
    signup_main_name_placeholder: "",
    signup_main_name_help: "",
    signup_main_telephone: "Telefon",
    signup_main_telephone_placeholder: "",
    signup_main_telephone_help: "",
    signup_main_button: "Registrieren",
    signup_main_to_signup: "Bereits registriert? Melden Sie sich an",
    signup_main_copyright: "© Hierø - HIERØ 2020",
    signup_error_required_field: "Pflichtfeld",
    signup_error_invalid_email: "Die eingegebene E-Mail-Adresse ist ungültig.",
    signup_error_min_password: "Mindestens 8 Zeichen",
    signup_error_max_password: "Maximal 30 Zeichen",
    signup_error_passwords_do_not_match: "Die Passwörter stimmen nicht überein",
    login_forgot_password: "Passwort vergessen?",
    reset_password_main: "Passwort wiederherstellen",
    reset_password_email_title: "E-Mail-Adresse",
    reset_password_email_placeholder: "",
    reset_password_email_help: "",
    reset_password_send: "Gesendet",
    reset_password_login: "Anmelden",
    reset_password_success:
      " Eine Nachricht wurde an Ihre E-Mail-Adresse gesendet. Klicken Sie auf den Link, um Ihr Passwort zu ändern.",
    main_left_menu_section_parameters_agency: "Mein Unternehmen",
    main_left_menu_section_parameters_services: "Mein Service",
    main_left_menu_section_parameters_user: "Mein Profil",
    main_left_menu_section_parameters_support: "Hilfe",
    reset_password_error_required: "Pflichtfeld",
    reset_password_error_invalid_email:
      "Die eingegebene E-Mail-Adresse ist ungültig ",
    reset_password_error_user_not_found: "Die E-Mail-Adresse existiert nicht.",
    main_header_user_menu_logout: "Abmelden",
    agency_setup_companyname: "Name des Unternehmens",
    agency_setup_companyname_placeholder: "",
    agency_setup_companyname_help:
      " Der Name des Unternehmens, der auf Ihren Auftragsbestätigungen, Rechnungen usw. erscheinen soll.",
    agency_setup_address: "Adresse",
    agency_setup_siret: "USt-ID",
    agency_setup_siret_placeholder: "",
    agency_setup_siret_help: "Geben Sie Ihre SIRET an ",
    agency_setup_address_placeholder: "",
    agency_setup_address_help:
      "Tragen Sie Ihre Adresse ein und wählen Sie die korrekte Adresse aus der erscheinenden Liste.",
    agency_setup_addresscompl: "Adresszusatz",
    agency_setup_addresscompl_placeholder: "",
    agency_setup_addresscompl_help:
      "Gebäude, Treppenhaus, Zugangscode, usw. Falls Ihre Kunden Ihre Übersetzungen vor Ort abholen möchten, werden so viele Informationen wie möglich benötigt.",
    agency_setup_tel: "Telefon",
    agency_setup_tel_placeholder: "",
    agency_setup_tel_help: "Die Telefonnummer auf der Sie erreichbar sind",
    agency_setup_email: "E-Mail-Adresse",
    agency_setup_email_placeholder: "",
    agency_setup_email_help:
      " Die E-Mail-Adresse mit der Sie erreichbar sind für Ihre Kunden. Geben Sie eine andere E-Mail-Adresse an, falls es sich nicht um die E-Mail-Adresse handelt, mit der Sie Ihren Zugang erstellt haben.",
    agency_setup_save: "Speichern",
    agency_setup_error_required_field: "Pflichtfeld",
    agency_setup_error_invalid_email:
      " Die eingegebene E-Mail-Adresse ist ungültig.",
    agency_setup_error_valid_address:
      " Wählen Sie eine vorgeschlagene Adresse aus der Dropdown-Liste.",
    agency_address_not_found: "Ihre Adresse ist nicht gelistet?",
    agency_address_title: "Geben Sie Ihre Adresse ein",
    agency_address_address: "Adresse:",
    agency_address_number: "Nr.",
    agency_address_street: "Straße",
    agency_address_code: "Postleitzahl",
    agency_address_city: "Stadt",
    agency_address_country: "Land",
    agency_address_coords: "GPS-Koordinaten:",
    agency_address_coords_lat: "Breitengrad",
    agency_address_coords_long: "Längengrad",
    agency_address_cancel: "Abbrechen",
    agency_address_validate: "Bestätigen",
    main_left_menu_section_navigation_dashboard: "Dashboard",
    main_menu_left_section_navigation_factures: "Meine Rechnungen",
    head_facture: "Rechnung",
    section_date: "Datum",
    section_numero_facture: "Rechnungsnummer",
    section_type_prestation: "Art der Leistung",
    section_price: "Total (+ MwSt.)",
    section_print: "Ausdrucken",
    section_generate_facture: "PDF-Rechnung erstellen",
    section_consulter: "Konsultieren",
    prestation: "Dienstleistung",
    commande_qte: "Menge",
    commande_produit: "Produkt",
    commande_desc: "Beschreibung",
    total_ht: "Total (+ MwSt.)",
    total_tva: "Total (+ MwSt.)",
    total_ttc: "Total (+ MwSt.)",
    retour_btn: "Zurück",
    progress_commande: "Auftragsfortschritt",
    commande_numero: "Auftragsnummer",
    capital_sasu: " SASU mit Stammkapital von 7 000,00 €",
    rcs_infos: "RCS EVRY 843119728 ",
    siret: " ",
    tva_fr: " USt-IdNr: FR00843119728",
    condition_paragraphe_1: "Verzugszinsen (Jahresrate): 10%",
    condition_paragraphe_2: "Kein Skonto möglich",
    condition_paragraphe_3:
      " Pauschaler Schadensersatz für Beitreibungskosten bei Zahlungsverzug: 40€",
    prestataire: "Leistungserbringer",
    my_info: "Meine Daten",
    geolocation_paragraphe:
      " Die Softaware benötigt Ihre Position, um Ihnen die nächstgelegenen Übersetzer anzuzeigen.",
    update_password: "Passwort ändern",
    account_upt: "Falls Sie es wünschen, können Sie Ihr Passwort ändern",
    send: "Senden",
    close_modal: "Schliessen",
    inscription_date: "Mitglied seit ",
    order_translate: "von ",
    montant_total_euro: "Gesamtpreis in EURO",
    rules_conditions: "Zahlungsbedingungen",
    rules_conditions_p: "Zahlung bei Rechungseingang",
    section_agency_title: "Mein Unternehmen",
    section_account_title: "Mein Profil",
    section_support_title: "Hilfe",
    notifications_new_task: "Sie haben einen neuen Auftrag!",
    notifications_task_confirmed:
      " Der Auftrag wurde bestätigt. Sie können die Übersetzung beginnen.",
    notifications_task_validated:
      " Der Auftrag wurde von Ihrem Kunden bestätigt.",
    tasks_sent_on: "Gesendet am",
    tasks_src_country: "Ursprungsland",
    tasks_dst_country: "Zielsprache",
    tasks_state: "Status",
    state_cancelled: "storniert vom Kunden",
    state_waiting_for_translator: "Neu",
    state_waiting_for_payment: "Warten auf die Zahlung",
    state_refused_by_translator: "Auftrag abgelehnt",
    state_translating: "Übersetzung in Arbeit",
    state_waiting_for_client_validation:
      "Fertig. Warten auf die Bestätigung des Kunden.",
    state_validated: "Bestätigt vom Kunden",
    state_validated_pro: "Bestätigt",
    prestation_order_from: "Von",
    prestation_order_document_type: "Dokumentenart",
    prestation_order_messaging_title: "Nachrichten",
    prestation_order_messaging_send: "Gesendet",
    prestation_order_messaging_client: "Kunden:",
    prestation_order_messaging_me: "Ich:",
    prestation_document_edit: "Dokument-Editor",
    prestation_document_edit_expl:
      " Der Text im obigen Feld ist im folgenden Dokument unterstrichen. Durch die Eingabe des übersetzten Textes in das Feld wird das Dokument automatisch aktualisiert. Verwenden Sie die Pfeile, um sich durch das Dokument zu navigieren.",
    prestation_document_get_browser:
      " Sie sehen keine PDF-Datei? Verwenden Sie den aktuellsten Webbrowser wie",
    prestation_document_confirm_oui: "Ja",
    prestation_document_confirm_non: "Nein",
    account_save: "Speichern",
    account_surname: "Name",
    account_surname_placeholder: "",
    account_name: "Vorname",
    account_name_placeholder: "",
    account_telephone: "Telefon",
    account_telephone_placeholder: "",
    account_adresse: "Adresse",
    account_adresse_placeholder: "Adresse",
    account_complement_adr: "Adresszusatz",
    account_complement_adr_placeholder: " Adresszusatz ",
    account_email: "E-Mail-Adresse",
    account_email_placeholder: " E-Mail-Adresse ",
    account_password: "Aktuelles Passwort",
    account_new_password: "Neues Passwort",
    account_error_required: "Pflichtfeld",
    agency_expl_1_title: "Firmennamen",
    agency_expl_1_expl:
      " Geben Sie exakt den Namen Ihres Unternehmens an, damit Sie besser von Ihren Kunden gefunden werden können.",
    agency_expl_2_title: "Geolokalisierung",
    agency_expl_2_expl:
      " Die Software benötigt Ihre Kontaktdaten, damit Sie an die nächstgelegenen Kunden vermittelt werden können.",
    agency_expl_3_title: "Zugangsaktivierung",
    agency_expl_3_expl:
      " Ihr Profil wird sichtbar durch die Speicherung Ihrer Informationen.",
    services_dst_lang: "Zielsprache",
    support_title: "Unser Büro",
    support_company: "Hiero",
    support_addr1: "7 Rue Montespan",
    support_addr2: "91000 Évry-Courcouronnes",
    support_addr3: "Frankreich",
    support_contact: "Kontakt",
    support_tel: "Tel: (+33) 01 76 38 01 40",
    support_email:
      "Mail: <a href='mailto:contact@hiero-solution.com'>contact@hiero-solution.com</a>",
    not_found_title: "Element kann nicht gefunden werden",
    not_found_subtitle: " Die gewünschte Seite existiert nicht ",
    not_found_action: "Kontaktieren Sie",
    not_found_home: "Startseite",
    menu_label_sur_place: "Vor Ort",
    menu_label_visio: "Via Videokonferenz",
    consulter_facture: "Meine Rechnungen ansehen",
    facture_numero: "Rechnungsnummer",
    recap_tva: " ZUSAMMENFASSUNG DER MWST",
    recap_taux: "RATE",
    recap_mt_tva: "BETRAG DER MWST",
    recap_base_ht: "BASIS OHNE MWST",
    action: "Aktion",
    hours: "Uhrzeit",
    join_room: "Konferenzraum betreten ",
    rooms: "Räume",
    warning: "Achtung! ",
    conf: "Konferenz",
    warning_1: " Beachten Sie die Schreibweise des Namens des Konferenzraums.",
    interpretation_info: "Erläuterung",
    interpretation_paragraphe_1:
      " Um den Dolmetscher-Service wahrzunehmen, benötigen Sie mindestens zwei Fenster. Das erste Fenster dient zum Kontakt mit dem Dolmetscher. Das zweite Fenster dient zum Kontakt mit allen anderen Teilnehmern.",
    interpretation_paragraphe_2:
      " Um den Konferenzraum zu betreten, der Name des Konferenzraums ist ",
    interpretation_paragraphe_3: ". Dort befinden sich alle Teilnehmer.",
    interpretation_paragraphe_4:
      " Um den Dolmetscherraum zu betreten, der Name des Dolmetscherraums ist ",
    interpretation_paragraphe_5:
      ". Dort befinden sich Ihre Teilnehmer und Ihr Dolmetscher.",
    interpretation_paragraphe_6:
      " Zur korrekten Durchführung des Dolmetschereinsatz dieser Fernkonferenz ist es erforderlich, dass Redner und Dolmetscher nicht gleichzeitig im selben Raum sprechen.",
    interpretation_paragraphe_7:
      " Aus diesem Grund befinden Sie sich in zwei verschiedenen Räumen. Auf diese Weise hören die Teilnehmer nur Sie im Konferenzraum und Sie hören nur die Redner im Dolmetscherraum.",
    interpretation_paragraphe_8:
      " Aus Leistungsgründen beträgt die maximale Kapazität eines Raumes 50 Teilnehmer. Sie können beliebig viele Räume entsprechend Ihrer Zuhörerschaft erstellen und die Namen dieser Räume Ihrem Dolmetscher mitteilen.",
    room_conf: " Konferenzraum",
    traduction_assermentee: "Beglaubigte Übersetzung",
    traduction_classic: "Klassische Übersetzung",
    interpretation: "Dolmetschermission",
    AF: "Afghanistan",
    ZA: "Südafrika",
    AL: "Albanien",
    DZ: "Algerien",
    DE: "Deutschland",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarktis",
    AG: "Antigua und Barbuda",
    AN: "Niederländische Antillen",
    SA: "Saudi-Arabien",
    AR: "Argentinien",
    AM: "Armenien",
    AW: "Aruba",
    AU: "Australien",
    AT: "Österreich",
    AZ: "Aserbaidschan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesch",
    BB: "Barbados",
    BY: "Weißrussland",
    BE: "Belgien",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivien",
    BA: "Bosnien und Herzegowina",
    BW: "Botswana",
    BR: "Brasilien",
    BN: "Brunei Darussalam",
    BG: "Bulgarien",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Kambodscha",
    CM: "Kamerun",
    CA: "Kanada",
    CV: "Kap Verde",
    EA: "Ceuta und Melilla",
    CL: "Chile",
    CN: "China",
    CY: "Zypern",
    CO: "Kolumbien",
    KM: "Komoren",
    CG: "Kongo-Brazzaville",
    KP: "Nordkorea",
    KR: "Südkorea",
    CR: "Costa Rica",
    CI: "Elfenbeinküste",
    HR: "Kroatien",
    CU: "Kuba",
    DK: "Dänemark",
    DG: "Diego Garcia",
    DJ: "Dschibuti",
    DM: "Dominique",
    EG: "Ägypten",
    SV: "El Salvador",
    AE: "Vereinigte Arabische Emirate",
    EC: "Ecuador",
    ER: "Eritrea",
    ES: "Spanien",
    EE: "Estland",
    VA: "Staat der Vatikanstadt",
    FM: "Föderierte Staaten von Mikronesien",
    US: "Vereinigte Staaten",
    ET: "Äthiopien",
    FJ: "Fidschi",
    FI: "Finnland",
    FR: "Frankreich",
    GA: "Gabun",
    GM: "Gambia",
    GE: "Georgien",
    GS: "Südgeorgien und die Südlichen Sandwichinseln",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Griechenland",
    GD: "Granate",
    GL: "Grönland",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GQ: "Äquatorialguinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    GF: "Französisch-Guyana",
    HT: "Haiti",
    HN: "Honduras",
    HU: "Ungarn",
    BV: "Île Bouvet",
    CX: "Weihnachtsinsel",
    CP: "Clipperton-Insel",
    AC: "Insel der Himmelfahrt",
    IM: "Isle of Man",
    NF: "Norfolkinsel",
    AX: "Åland-Inseln",
    KY: "Kaimaninseln",
    IC: "Kanarische Inseln",
    CC: "Kokos - Keelinginseln",
    CK: "Kochinseln",
    FO: "Färöer-Inseln",
    HM: "Heard and MacDonald Islands",
    FK: "Falklandinseln",
    MP: "Nördliche Marianen",
    MH: "Marshall-Inseln",
    UM: "Minor Outlying Islands of the United States",
    SB: "Salomonen",
    TC: "Turks- und Caicosinseln",
    VG: "Britische Jungferninseln",
    VI: "Jungferninseln der Vereinigten Staaten",
    ID: "Indonesien",
    IQ: "Irak",
    IR: "Iran",
    IE: "Irland",
    IS: "Island",
    IL: "Israel",
    IT: "Italien",
    JM: "Jamaika",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordanien",
    KZ: "Kasachstan",
    KE: "Kenia",
    KG: "Kirgisistan",
    KI: "Kiribati",
    KW: "Kuwait",
    LA: "Laos",
    LS: "Lesotho",
    LV: "Lettland",
    LB: "Libanon",
    LR: "Liberia",
    LY: "Libyen",
    LI: "Liechtenstein",
    LT: "Litauen",
    LU: "Luxemburg",
    MK: "Mazedonien",
    MG: "Madagaskar",
    MY: "Malaysia",
    MW: "Malawi",
    MV: "Malediven",
    ML: "Mali",
    MT: "Malta",
    MA: "Marokko",
    MQ: "Martinique",
    MU: "Maurice",
    MR: "Mauretanien",
    YT: "Mayotte",
    MX: "Mexiko",
    MD: "Moldawien",
    MC: "Monaco",
    MN: "Mongolei",
    ME: "Serbien und Montenegro",
    MS: "Montserrat",
    MZ: "Mosambik",
    MM: "Myanmar",
    NA: "Namibie",
    NR: "Suriname",
    NP: "Nepal",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NO: "Norwegen",
    NC: "Neukaledonien",
    NZ: "Neuseeland",
    OM: "Oman",
    UG: "Uganda",
    UZ: "Usbekistan",
    PK: "Pakistan",
    PW: "Palau",
    PA: "Panama",
    PG: "Papua-Neuguinea",
    PY: "Paraguay",
    NL: "Niederlande",
    PE: "Peru",
    PH: "Philippinen",
    PN: "Pitcairn",
    PL: "Polen",
    PF: "Französisch-Polynesien",
    PR: "Puerto Rico",
    PT: "Portugal",
    QA: "Katar",
    HK: "Chinesische Sonderverwaltungszone Hongkong",
    MO: "China SAR Macau",
    QO: "Abgelegene Regionen Ozeaniens",
    CF: "Zentralafrikanische Republik",
    CD: "Demokratische Republik Kongo",
    DO: "Dominikanische Republik",
    CZ: "Tschechische Republik",
    RE: "Besprechung",
    RO: "Rumänien",
    GB: "Großbritannien",
    RU: "Russland",
    RW: "Ruanda",
    EH: "Westsahara",
    BL: "Saint-Barthélemy",
    KN: "St.-Kitts-und-Nevis",
    SM: "San Marino",
    MF: "Saint-Martin",
    PM: "Saint-Pierre-et-Miquelon",
    VC: "St. Vincent und die Grenadinen",
    SH: "Heilige Helena",
    LC: "Heilige Lucia",
    WS: "Samoa",
    AS: "Amerikanisch-Samoa",
    ST: "Sao Tome und Principe",
    SN: "Senegal",
    RS: "Serbien",
    SC: "Seychellen",
    SL: "Sierra Leone",
    SG: "Singapur",
    SK: "Slowakei",
    SI: "Slowenien",
    SO: "Somalia",
    SD: "Sudan",
    LK: "Sri Lanka",
    SE: "Schweden",
    CH: "Schweiz",
    SR: "Suriname",
    SJ: "Svalbard und Jan Mayen Island",
    SZ: "Swasiland",
    SY: "Syrien",
    TJ: "Tadschikistan",
    TW: "Taiwan",
    TZ: "Tansania",
    TD: "Tschad",
    TF: "Französische Süd- und Antarktisgebiete",
    IO: "Britisches Territorium im Indischen Ozean",
    PS: "Palästinensisches Territorium",
    TH: "Thailand",
    TL: "Ost-Timor",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad und Tobago",
    TA: "Tristan da Cunha",
    TN: "Tunesien",
    TM: "Turkmenistan",
    TR: "Türkei",
    TV: "Tuvalu",
    UA: "Ukraine",
    EU: "Europäische Union",
    UY: "Uruguay",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    WF: "Wallis und Futuna",
    YE: "Jemen",
    ZM: "Sambia",
    ZW: "Simbabwe",
    /************ fin du bloc pour les phrases utilisées sur les 3 solutions ***********/

    /******************** Bloc pour les phrases utilisées null part ********************/
    order_asser_or_not: "Handelt es sich um eine beglaubigte Übersetzung?",
    new_orders: "Eine Bestellung aufgeben",
    state_pro_wait: "Warten",
    account_expl_pro_cheque: "Schicken Sie uns einen Scheck",
    account_expl_pro_viremement: "Überweisung",
    account_expl_pro_iban: "IBAN DE 000",
    account_hist_pro_debit: "Lastschrift",
    account_hist_pro_credit: "Gutschrift",
    account_hist_pro_way: "Zahlungsmittel ",
    account_hist_pro_ref: "Referenz ",
    agency_pro_budget_help: "Budget für Ihre Übersetzungen.",
    sub_training: "Schulung Ihrer Mitarbeiter ",
    sub_search: "Automatische Suche eines Übersetzers / Dolmetschers ",
    sub_geo: "Geolokalisierung des Übersetzers / Dolmetschers",
    sub_dem: "Digitalisierung Ihrer Aufträge ",
    sub_save: "Speicherung Ihrer Dokumente mit dem Datei Manager",
    sub_recap: "Zusammenfassung der monatlichen Aufträge ",
    sub_proj: "Bearbeitungsstatus Ihrer Aufträge in Echtzeit ",
    sub_management: "Budgetverwaltung in Echtzeit ",
    sub_message: "Instant Messaging",
    sub_send: "Lieferung Ihrer Übersetzung per Post ",
    sub_impl: "12 Monate Budget ",
    sub_account: "persönlicher Account Manager ",
    sub_orientation:
      "Personalisierte Ausrichtung nach den am häufigsten angefragten Sprachen ",
    sub_split:
      "Aufteilung des Budgets in mehrere Abteilungen (nach Übersetzer) ",
    sub_event: "Abholung durch Kurierdienst ",
    choose: "Wahlen Sie",
    sub_function: "Inhalt ",
    sub_standard: "Standard",
    sub_essen: "Essentiell",
    sub_premium: "Premium",
    sub_month: "Monat",
    sub_associations_label: "Assoziationen",
    sub_cabinet_label: "Anwaltskanzleien",
    sub_bank_label: "Internationale Mobilitä",
    sub_academia_label: "Akademische Einrichtungen",
    sub_who_are_you: "Sie sind?",
    sub_explanation_academia:
      " Wir arbeiten bevorzugt in Form von Partnerschaften mit akademischen Einrichtungen wie Universitäten, Internationalen Schulen ...",
    sub_explanation_systeme: "2 mögliche Systeme: ",
    sub_system_one: "Nutzung der mobilen HIERO App.",
    sub_system_two: "Nutzung des Interfaces für Fachkräfte.",
    sub_credit_card_message:
      "Aktivieren Sie unsere Dienstleistungen durch die Zahlung Ihres Abonnements ",
    validate_credit_card_message: "Ihr Bestellung wurde berücksichtigt",
    subscriber_choose: "Wählen Sie Ihr Abonnement",
    find_out_more: "Erfahren Sie mehr",
    menu_label_relecture: "Korrekturlesen ",
    signup_error_email_not_found: "Die E-Mail-Adresse existiert nicht.",
    main_left_menu_section_accueil: "Startseite",
    agency_setup_email_help_pro:
      " Die E-Mail-Adresse mit der Sie erreichbar sind für HIERO und andere Übersetzer / Dolmetscher.",
    main_menu_left_section_navigation_entreprise: "Mein Unternehmen",
    main_menu_left_section_navigation_messagerie: "Instant-Messaging",
    main_menu_left_section_navigation_gestion_des_fichiers: "Ordner",
    montant_total: "Total (+ MwSt.)",
    single_commande_p1:
      " Der Übersetzer lädt das zu übersetzende Dokument herunter ",
    single_commande_p2:
      " Nach der Fertigstellung wird die Übersetzung hinzugefügt, damit sie von Ihnen bestätigt werden kann.",
    load_intervention: "Intervention laufend",
    commande_statut: "Auftragsstatus",
    commande_details: "Auftragsdetails",
    trad_name: "Name des Übersetzers",
    visioner: "Ansehen",
    infos_interv: "Zusätzliche Informationen zur Intervention",
    intervention_time_date: "Datum und Uhrzeit",
    loading_order: "Meine aktuellen Übersetzungsanfragen",
    view_all_orders: "Alle Aufträge anzeigen",
    last_payment: "Meine letzten Zahlungen",
    view_all_payment: "Alle meine Zahlungen anzeigen",
    last_send_msg: "Letzte gesendete Nachrichten",
    state_accept_order: "Auftrag akzeptiert",
    account_expl:
      " Geben Sie Ihre Kontaktdaten an, um Sie bei Bedarf kontaktieren zu können.",
    account_surname_help: "",
    account_name_help: "",
    account_telephone_help: "",
    account_poste_help: "",
    account_adresse_help: "",
    account_complement_adr_help: "",
    account_email_help: "",
    account_password_help: "",
    account_new_password_help: "",
    not_found_action1: "Das Problem besteht weiterhin. ",
    menu_label_interpretation: "Dolmetscher beauftragen",
    table_header_statut: "Status",
    head_professionnal:
      " Die Software benötigt Ihre Position um Sie an die nächstgelegenen Übersetzer / Dolmetschers vermitteln zu können ",
    head_change_password: "Falls gewünscht, können Sie Ihr Passwort ändern",
    recap_tva_fr: "19% MWTS DE",
    client_particulier_name: "Name",
    client_particulier_email: "E-Mail-Adresse",
    client_particulier_address: "Adresse ",
    client_particulier_code_postal: "Stadt",
    particulier_name: "Name",
    infos_entrep: "",
    infos_commandes: "",
    address_facturation: "Rechnungsadresse ändern",
    /***************** fin du bloc pour les phrases utilisées null part ****************/
  },
};
