import { Component, OnInit } from "@angular/core";
import { HieroBDD } from "../../../services/hierobdd.service";
import * as firebase from "firebase/app";
import { ActivatedRoute } from "@angular/router";

HieroBDD;

@Component({
  selector: "app-email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.css"],
})
export class EmailComponent implements OnInit {
  public alert: boolean = false;
  public id: number;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    const id = this.route.snapshot.paramMap.has("id");
    this.route.paramMap.subscribe(async (paramMap) => {
      if (!paramMap.has("id")) {
        // Redirect
        /* this.router.navigate(['/ticket']); */
        return;
      }
      this.id = +paramMap.get("id");
    });
  }

  reverification() {
    firebase.auth().currentUser.sendEmailVerification();

    this.alert = true;
  }
}
