<div class="modal-body">
  <div class="container">
    <div class="mb-3">
      <h3 *ngIf="type === 'modif'">{{ "modal_modif_title" | localise }}</h3>
      <h3 *ngIf="type === 'delete'">{{ "modal_supp_title" | localise }}</h3>
    </div>
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label>{{ "login_main_email" | localise }}</label>
        <input
          type="text"
          formControlName="email"
          class="form-control"
          [placeholder]="'login_main_email_placeholder' | localise"
        />
        <div
          *ngIf="
            registerForm.get('email').hasError('required') &&
            registerForm.get('email').touched
          "
        >
          <small class="text-danger">
            {{ "account_error_required" | localise }}
          </small>
        </div>
        <div
          *ngIf="
            registerForm.get('email').hasError('email') &&
            registerForm.get('email').touched
          "
        >
          <small class="text-danger">
            {{ "account_error_invalid_email" | localise }}
          </small>
        </div>
      </div>

      <div class="form-group">
        <label>{{ "login_main_password" | localise }}</label>
        <input
          type="password"
          formControlName="password"
          class="form-control"
          [placeholder]="'login_main_password_placeholder' | localise"
        />
        <div
          *ngIf="
            registerForm.get('email').hasError('required') &&
            registerForm.get('email').touched
          "
        >
          <small class="text-danger">
            {{ "account_error_required" | localise }}
          </small>
        </div>
      </div>
      <!-- <div class="form-group form-check">
        <label class="form-check-label">
          <input class="form-check-input" type="checkbox" name="remember" />
          Remember me
        </label>
      </div> -->
      <button class="btn my-btn" [disabled]="registerForm.invalid">
        {{ "send" | localise }}
      </button>
      <div>
        <p *ngIf="error" class="text-danger mt-2">
          {{ "modif_profile_failed_login" | localise }}
        </p>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('close')"
  >
    {{ "close_modal" | localise }}
  </button>
</div>
