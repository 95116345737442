import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Traducteur } from '../../../../../common/src/bdd/traducteur/Traducteur';
import { TraducteurService, TraducteurServiceList } from '../../../../../common/src/bdd/traducteur/TraducteurService';
import { Language } from '../../../../../common/src/bdd/utility/Language';
import { HieroBDD } from '../../../services/hierobdd.service';
import { ISubmitFormInputErrors, SubmitForm } from '../../../../../common/src/utility/forms/submitform.class';
import { TemplateList } from '../../../../../common/src/bdd/documents/TemplateList';
import { TemplatesLanguages, ILocalisation } from '../../../../../common/src/bdd/documents/TemplatesLanguages';
import { IAppFormDropdowParameters, IDDFormNameData } from '../../../../../common/src/utility/forms/app-form-dropdown/app-form-dropdown.component';
import { IDocumentType } from '../../../../../common/src/bdd/interfaces/IDocumentType';
import { DocumentType } from '../../../../../common/src/bdd/documents/DocumentType';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { LocalisationService } from '../../../../../common/src/modules/localisation/localisation.service';
import { SubscriptionTraductor } from '../../../services/subsciption.services';
import { ITraducteur } from '../../../../../common/src/bdd/interfaces/ITraducteur';


@Component({
  templateUrl: './traducteurServiceList.component.html'
})
export class TraducteurServiceListComponent implements OnDestroy {

 // @Input() myPrice: number 

  busy = true;
  traducteur: Traducteur;
  templateList: TemplateList;
  serviceList: TraducteurServiceList;
  services: TraducteurService[];
  docTypeMap: DocumentType;

  templatesLanguages: TemplatesLanguages;

  private traducteutSub: Subscription;
  private listSub: Subscription;

  prestationForm: SubmitForm;

  srcLangDropDown: IAppFormDropdowParameters;
  sourceLangISO639: string;
  destLangISO639: string;
  dstLangDropDown: IAppFormDropdowParameters;

  currentActiveId: string;

  addIsCollapsed = true;

  defaultPriceForm:FormGroup;

  currentTraducteur: ITraducteur;
  watchTrad: ITraducteur;


  /**
   * when is true the traductor can't active the service by when if false he can 
   */
  checkedSubscribe = false
  profile: Subscription;

  constructor(
    private hiero: HieroBDD,
    private fb: FormBuilder,
    private localisation: LocalisationService,
    private subcription: SubscriptionTraductor

  ) {

    // Add listeners for the data we want
    // These listeners will either fire immediately,
    // or they will fire when the data becomes available
    this.traducteutSub = hiero.WatchTraducteur({
      next: (traducteur: Traducteur) => {
        if (traducteur) {
          // We have a user, save a reference
          this.traducteur = traducteur;

          // Get a list of services for this translator
          this.traducteur.getTraducterServices()
            .then(
              (serviceList: TraducteurServiceList) => {
                this.serviceList = serviceList;

                this.listSub = this.serviceList.WatchTraducteurServiceList({
                  next: (services: TraducteurService[]) => {
                    this.services = services;
                   console.log('services ==> ',services)
                    this.checkReady();
                  }
                });

                return TemplateList.Init(traducteur.User, true);
              }
            )
            .then(
              (templateList: TemplateList) => {
                this.templateList = templateList;

                return TemplatesLanguages.Init(traducteur.User);
              }
            )
            .then(
              (tl) => {
                this.templatesLanguages = tl;
                this.setSourceDropdown();
                return DocumentType.Init(traducteur.User);
              }
            )
            .then(
              (docType: DocumentType) => {
                this.docTypeMap = docType;
                this.checkReady();
              }
            );
        }
      }
    });
    this.profile = this.hiero.WatchTraducteurProfile({
      next: (profile: ITraducteur) => {
        this.watchTrad = profile
      }
    })


    this.createForm();
    this.createDefaulPriceForm();
    this.subcription.GetSubscription()
    this.subcription.observer.subscribe(data => {
      // console.log(data)
      // console.log(this.checkedSubscribe)

      if (data != null) {
        this.checkedSubscribe = false
      }
    })
    //this.subcription.observer.subscribe(data => console.log(data))
    // this.subcription.GetSubscription().then((element) => {
    //   console.log('my =>', element)
    //   if (typeof element[0].id === 'string') {
    //     this.checkedSubscribe = false
    //   }
    // })

  }

  checkReady() {
    if (this.services && this.docTypeMap) {
      this.busy = false;
    }
  }

  ngOnDestroy() {
    if (this.traducteutSub) {
      this.traducteutSub.unsubscribe();
    }
    if (this.listSub) {
      this.listSub.unsubscribe();
    }
  }


  setSourceDropdown() {
    const options = [];

    if (this.templatesLanguages) {
      const srcLangs: ILocalisation[] = this.templatesLanguages.getNativeSources();
      srcLangs.forEach(
        (loc: ILocalisation) => {
          options.push({
            name: loc.label,
            data: loc.value
          });
        }
      );
    }

    this.srcLangDropDown = {
      choices: options,
      selectedIndex: 0,
      title: '',
      input_title: this.localisation.localise('services_add_src_lang'),
      hasButton: false,
      button_title: '',
      help: ''
    };

    // Automatically set first
    if (options.length > 0) {
      this.sourceLangISO639 = options[0].data;
      this.setDestDropdown();
    }
  }

  setDestDropdown() {
    const options = [];

    if (this.templatesLanguages) {
      const dstLangs: ILocalisation[] = this.templatesLanguages.getDstLangsForSrcLang(this.sourceLangISO639);
      dstLangs.forEach(
        (loc: ILocalisation) => {
          options.push({
            name: loc.label,
            data: loc.value
          });
        }
      );
    }

    this.dstLangDropDown = {
      choices: options,
      selectedIndex: 0,
      title: '',
      input_title: this.localisation.localise('services_dst_lang'),
      hasButton: false,
      button_title: '',
      help: ''
    };

    if (options.length > 0) {
      this.setDestLang(options[0].data);
    }
  }


  selectSourceLang(data: IDDFormNameData) {
    this.sourceLangISO639 = data.data;
    this.setDestDropdown();
  }

  selectDestLang(data: IDDFormNameData) {
    this.setDestLang(data.data);
  }

  private setDestLang(dstIso639: string) {
    if (this.services) {
      const myService = this.services.find(
        (service: TraducteurService) => {
          return service.data.srcLanguageIso639 === this.sourceLangISO639 && service.data.destLanguageIso639 === dstIso639;
        }
      );

      if (myService) {
        this.destLangISO639 = null;
      } else {
        this.destLangISO639 = dstIso639;
      }
    }
  }

  createForm() {
    this.prestationForm = new SubmitForm(
      this.fb,
      [
        {
          name: 'src',
          value: '',
          validators: [],
          type: 'text',
          title: 'Langue d\'origine',
          autocomplete: '',
          placeholder: 'Commencer à taper le nom de la langue',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Champs obligatoire',
            },
          ]
        },
        {
          name: 'dst',
          value: '',
          validators: [],
          type: 'text',
          title: 'Vers',
          autocomplete: '',
          placeholder: 'Commencer à taper le nom de la langue',
          help: '',
          errors: [
            <ISubmitFormInputErrors>{
              code: 'required',
              message: 'Champs obligatoire',
            },
          ]
        },
      ],
      // Submit callback
      (changes) => {
      },

      // Success callback
      () => {
        // What to do with login success ?
        this.busy = false;
      },

      // Fail callback
      err => {
        // What to do with login failuer
        console.error(err);
        this.busy = false;
      },

      // Changes callback
      null
    );

    this.setSourceDropdown();
    this.setDestDropdown();
  }

  createDefaulPriceForm() {
    this.defaultPriceForm = this.fb.group(
      {
        defaultException: ['', [Validators.required, Validators.min(0), Validators.max(9999)]]
      }
    )

  }
  
  async defaultPriceSubmit() {
    console.log('formPrice default',this.defaultPriceForm.value.defaultException)
     this.hiero.WatchTraducteurProfile({
      next: (traducteur: ITraducteur) => {
        
        if(traducteur) {
          this.currentTraducteur = traducteur
          this.currentTraducteur.defaultException = this.defaultPriceForm.value.defaultException
          console.log('new traducteur ',this.currentTraducteur)

         this.hiero.Traducteur.UpdateProfile(this.currentTraducteur)
        }
      }
    }
      
    )
    
    //console.log('traducteur',await this.traducteur.UpdateProfile({defaultException = this.defaultPriceForm.value}))
    
  }

  async addPrestation() {
    this.prestationForm.ClearGeneralError();

    const srcCode = this.sourceLangISO639;
    const dstCode = this.destLangISO639;

    if (!srcCode || !dstCode) {
      this.prestationForm.SetGeneralError('Les langues choisies ne sont pas reconnues');
      return;
    }

    this.busy = true;
    try {
      const supported = await TemplateList.CountTemplates(this.traducteur.User, srcCode, dstCode);
      if (supported <= 0) {
        throw Error(this.localisation.localise('services_error_add'));
      }

      await this.serviceList.addService(srcCode, dstCode);
      this.busy = false;
      this.addIsCollapsed = true;
    } catch (err) {
      this.prestationForm.SetGeneralError(err.message);
      this.busy = false;
    }
  }

  async removePrestation(service: TraducteurService) {
    this.busy = true;

    try {
      await this.serviceList.removeService(service.Id);
    } catch (err) {
      this.prestationForm.SetGeneralError(err.message);
    }
  }

  beforePanelChange($event: NgbPanelChangeEvent) {
    this.currentActiveId = $event.panelId;
  }

  checkSubscription() {
    // if (this.subcription.subscription.getValue() != null) {
    //   this.checkedSubscribe = false
    //}
  }
  getTraductorInfo() {
    
  }
}
