import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DocumentType } from '../../../../../../../common/src/bdd/documents/DocumentType';
import { Template } from '../../../../../../../common/src/bdd/documents/Template';
import { IDocument } from '../../../../../../../common/src/bdd/interfaces/IDocument';
import { IFile } from '../../../../../../../common/src/bdd/interfaces/IFile';
import { IPDFDocument } from '../../../../../../../common/src/bdd/interfaces/IPDF';
import { IPrestation } from '../../../../../../../common/src/bdd/interfaces/IPrestation';
import { File } from '../../../../../../../common/src/bdd/prestation/File';
import { Prestation } from '../../../../../../../common/src/bdd/prestation/Prestation';
import { Traducteur } from '../../../../../../../common/src/bdd/traducteur/Traducteur';
import { PDFRender } from '../../../../../../../common/src/components/pdfpreview/pdfrender';
import { HieroBDD } from '../../../../../services/hierobdd.service';
import { LocalisationService } from '../../../../../../../common/src/modules/localisation/localisation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppModalConfirmComponent } from '../../../../../../../common/src/utility/modal/confirm-modal.component';

@Component({
  templateUrl: './prestationEditor.component.html',
  styleUrls: ['./prestationEditor.component.scss']
})
export class PrestationEditorComponent implements OnDestroy, AfterViewInit {

  private prestationSub: Subscription;
  private traducteurSub: Subscription;

  traducteur: Traducteur;
  prestation: Prestation;

  prestationId: string;
  documentTypeMap: DocumentType;

  documentId: string;
  document: IDocument;

  template: Template;
  pdfData: IPDFDocument;
  pdfRender: PDFRender;

  errorLoadingTemplate = false;

  busy = true;

  constructor(
    private hiero: HieroBDD,
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private localisation: LocalisationService,
    private modalService: NgbModal,
  ) {

  }

  ngOnDestroy() {
    this.traducteurSub.unsubscribe();
    this.prestationSub.unsubscribe();
    if (this.prestation) {
      this.prestation.cleanup();
    }
  }

  ngAfterViewInit() {
    this.documentId = this.route.snapshot.paramMap.get('documentId');
    const prestationId = this.route.snapshot.parent.paramMap.get('prestationId');

    this.traducteurSub = this.hiero.WatchTraducteur({
      next: (traducteur: Traducteur) => {
        if (traducteur) {
          this.traducteur = traducteur;

          Prestation.Load(traducteur.User, true, prestationId, true)
          .then(
            (prestation) => {

              // Clean up old one
              if (this.prestation) {
                this.prestation.cleanup();
              }
              if (this.prestationSub) {
                this.prestationSub.unsubscribe();
              }
              // Replace with new one
              this.prestation = prestation;
              this.setupDocument();

              // Subscribe
              this.prestationSub = this.prestation.Watch({
                next: (updated) => {
                  this.prestation = updated;
                  this.setupDocument();
                }
              });

              this.cdRef.detectChanges();
            }
          );

          DocumentType.Init(traducteur.User)
          .then(
            (types: DocumentType) => {
              this.documentTypeMap = types;
            }
          );
        }
      }
    });
  }

  setupDocument() {
    this.document = this.prestation.Data.documents.find(doc => doc.deviceStorageId === this.documentId);

    // Get template
    if (this.document && !this.template) {
      if (!this.document.translation) {
        Template.Find(this.traducteur.User, this.prestation.Data.srcLanguageIso639, this.prestation.Data.destLanguageIso639, this.prestation.Data.srcCountryCode, this.document.documentTypeId)
        .then(
          (template) => {
            if (!template) {
              this.errorLoadingTemplate = true;
            } else {
              this.template = template;
              this.pdfData = this.template.Data.config;
              this.pdfRender = new PDFRender(this.pdfData);
            }
            this.busy = false;
          }
        )
        .catch(
          () => {
            this.errorLoadingTemplate = true;
          }
        );
      } else {
        this.pdfData = this.document.translation;
        this.pdfRender = new PDFRender(this.pdfData);
        this.busy = false;
      }
    }
  }

  getDocumentName(code: string) {
    if (this.documentTypeMap) {
      return this.documentTypeMap.GetDocumentName(this.localisation.CurrentLanguageISO639, code);
    } else {
      return '';
    }
  }

  async openFile(prestation: IPrestation, document: IDocument, file: IFile) {
    const fileObj: File = new File(this.hiero.Storage, prestation.uid, prestation, document.deviceStorageId, file);
    try {
      const link = await fileObj.GetDownloadLink();
      window.open(link, '_blank');
    } catch (err) {
      console.error(err);
    }
  }

  onUpdate(data: IPDFDocument) {
    this.prestation.UpdateDocumentTranslation(this.document, data);
  }


  async uploadFile() {
    if (this.pdfRender) {
      this.busy = true;

      try {
        const blob = this.pdfRender.renderToBlob(true);
        await this.prestation.UploadTranslatedFile(this.hiero.Storage, this.document, 'hierø-translated', blob);
        this.router.navigate(['/app', 'prestation', this.prestation.Id]);
        this.busy = false;
      } catch (err) {
        console.error(err);
      }

    }

  }

  async resetTemplate() {

    const modalRef = this.modalService.open(AppModalConfirmComponent, {
      centered: true,
    });
    modalRef.componentInstance.header = this.localisation.localise('prestation_document_confirm_title');
    modalRef.componentInstance.message = this.localisation.localise('prestation_document_confirm_message');
    modalRef.componentInstance.ok = this.localisation.localise('prestation_document_confirm_oui');
    modalRef.componentInstance.cancel = this.localisation.localise('prestation_document_confirm_non');
    let result = false;
    try {
      result = await modalRef.result;
    } catch {}

    if (result) {
      this.busy = true;
      try {
        const template: Template = await Template.Find(this.traducteur.User, this.prestation.Data.srcLanguageIso639, this.prestation.Data.destLanguageIso639, this.prestation.Data.srcCountryCode, this.document.documentTypeId)
        this.template = template;
        this.pdfData = this.template.Data.config;
        this.pdfRender = new PDFRender(this.pdfData);
        this.onUpdate(this.pdfData);
      } catch {
        this.errorLoadingTemplate = true;
      }
      this.busy = false;
    }
  }


}
