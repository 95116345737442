import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import * as firebase from "firebase";

@Injectable()
export class ProfilePictureService {
  public pfp$ = new BehaviorSubject<string>(
    firebase.auth().currentUser.photoURL
  );
}
