export const hieroAccounts = [
  {
    ID: "S28D2UpmhhPmidABfFyvCvgDsAX2",
  },
  {
    ID: "XhLiOStdg8Tqds9KHraLS6TyzuN2",
  },
  {
    ID: "DOOqFXTTieYlOeSac1W1DxwPdUj2",
  },
  {
    ID: "55ifkSRmAAYvoFzhz8NtYeRaree2",
  },
  {
    ID: "3HmNAtzWKJcWXG87gI74FSZeust1",
  },
  {
    ID: "BMvnvb8opnMOf6emRvSY9fZyOan1",
  },
  {
    ID: "CrzUDv3sHzW0gx5YHs7Sm4vX1wi1",
  },
  {
    ID: "KtoVnEpnBrXfzsQTyVGmuWVV9bi1",
  },
  {
    ID: "egXLTeWJJvQanWhHaTgnkfsedYa2",
  },
  {
    ID: "qZJ6UxElVNPmtYANUf539calxso2",
  },
  {
    ID: "eDqa6pVSH5cUu8A5UunApMN6Yd03",
  },
  {
    ID: "jAoNsl5W8AO02Ve4UfBmutZuU7Q2",
  },
  {
    ID: "rOGCcGp5IJh2mtq8YShb2HIsuEG2",
  },
  {
    ID: "rgij8E5eqpUKJqnpJbPeYKvhwXZ2",
  },
  {
    ID: "6DjORD8KnrQI36YGFlJWiVwUeQG3",
  },
  {
    ID: "8iU8IAoNdKUPdbiQV0yfyw1F7Qg1",
  },
  {
    ID: "f5UaBV5xlEavReS3NRAO2WuPleS2",
  },
  {
    ID: "FDgWJPpMJaMV8of0zrMXhNALPz92",
  },
  {
    ID: "5W3VhuCkFnOPF226AFfumv3nMEr1",
  },
  {
    ID: "KuibRLrb9wSQD0rLHuJ6fytCuUP2",
  },
]
  .map((user) => (user !== null ? user.ID : null))
  .filter((user) => user !== null && user !== "/");
