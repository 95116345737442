import { IFile } from './IFile';

export enum EnumMessageSender {
  client = 'client',
  translator = 'translator'
}

export interface IMessage {
  // Id of the user
  clientId: string;
  // Id of the translator
  translatorId: string;
  // The id of the message box
  prestationId: string;
  // Is client
  sender: EnumMessageSender;

  // Sender uid (same as either client or translatorId)
  senderUid: string;
  // The text of the message
  text: string;
  // The send date
  sentAt: number;
  // attachement
  file?: IFile;
}


export interface IMessageNotification {
  // The number of messages stored on the server
  stored: number;

  // The number of messages viewed by the user
  seen: number;
}
