<div
  class="container-fluid w-50 card text-center p-3"
  *ngIf="hasSubscriptionFranceRelance"
>
  <span class="h3 font-weight-bold"
    >Vous avez déja un abonnement en cours :</span
  >

  <span class="text-info font-weight-bold mt-3 h3">Traducteur Bronze</span>

  <span class="mt-3 h5">Souscrit via France Relance</span>
</div>

<div class="container-fluid w-50 card text-center p-3" *ngIf="hasSubscription">
  Vous avez déja un abonnement en cours : Traducteur
  {{ hasSubscription.product.name }}
  <br />
  <button class="btn btn-outline-info mt-4" (click)="gestion()">
    Gestion de mon abonnement
  </button>
</div>

<div
  class="container-fluid"
  *ngIf="hasSubscription === false && hasSubscriptionFranceRelance === false"
>
  <div class="d-flex mb-3">
    Vous n'avez aucun abonnement pour le moment. Veuillez saisir l'un des
    abonnements ci-dessous pour profiter des différents services proposés
  </div>
  <div class="d-flex mb-3">
    <h3>Nos abonnements</h3>
  </div>
  <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
    <div class="col">
      <div class="card mb-4 rounded-3 shadow-sm">
        <div class="card-header py-3">
          <h4 class="my-0 fw-normal">Bronze</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">
            34.70€<small class="text-muted fw-light">/mois (HT)</small>
          </h1>
          <p>soit 416,5€ par an</p>
          <!-- <ul class="list-unstyled mt-3 mb-4">
          <li>
            CRM/ERP gestion de projet et facture à installer sur vos supports
          </li>
          <li>Jusqu'à <span class="text-theme">80</span> projets par mois</li>
        </ul> -->
          <button
            type="button"
            class="w-50 btn btn-lg btn-primary"
            (click)="paid('price_1HcqDTHd6jxl5AUCYEhuNoJF')"
          >
            Sélectionner
          </button>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card mb-4 rounded-3 shadow-sm">
        <div class="card-header py-3">
          <h4 class="my-0 fw-normal">Silver</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">
            60€<small class="text-muted fw-light">/mois (HT)</small>
          </h1>
          <p>soit 720€ par an</p>
          <button
            type="button"
            class="w-50 btn btn-lg btn-primary"
            (click)="paid('price_1HcqEYHd6jxl5AUCjTSOOrlq')"
          >
            Sélectionner
          </button>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card mb-4 rounded-3 shadow-sm">
        <div class="card-header py-3">
          <h4 class="my-0 fw-normal">Gold</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">
            100€<small class="text-muted fw-light">/mois (HT)</small>
          </h1>
          <p>soit 1200€ par an</p>
          <button
            type="button"
            class="w-50 btn btn-lg btn-primary"
            (click)="paid('price_1HcqGKHd6jxl5AUClFIbN1mV')"
          >
            Sélectionner
          </button>
        </div>
      </div>
    </div>
  </div>

  <h2 class="display-6 text-center mb-4">
    Comparer les fonctionnalités principales
  </h2>

  <div class="table-responsive">
    <table class="table text-center">
      <thead>
        <tr>
          <th style="width: 34%"></th>
          <th style="width: 22%">Bronze</th>
          <th style="width: 22%">Silver</th>
          <th style="width: 22%">Gold</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row" class="text-left">Système de gestion des missions</th>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-left">Tableau de bord interactif</th>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-left">
            Consultation et réponse aux missions proposées
          </th>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-left">
            Alertes immédiates de nouvelles missions par mail
          </th>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-left">Édition de modèles automatique</th>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-left">
            Discussion automatique avec vos clients
          </th>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-left">Gestion de la comptabilité</th>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-left">Facturation automatique</th>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-left">Account manager à vos côtés</th>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-left">
            Application mobile pour la gestion à distance
          </th>
          <td>
            <i class="fa fa-times-circle fa-lg not-check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-left">
            Ajout de votre en-tête personnalisée
          </th>
          <td>
            <i class="fa fa-times-circle fa-lg not-check"></i>
          </td>
          <td>
            <i class="fa fa-times-circle fa-lg not-check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-left">Récurrence client</th>
          <td>
            <i class="fa fa-times-circle fa-lg not-check"></i>
          </td>
          <td>
            <i class="fa fa-times-circle fa-lg not-check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-left">
            Importation de vos propres clients
          </th>
          <td>
            <i class="fa fa-times-circle fa-lg not-check"></i>
          </td>
          <td>
            <i class="fa fa-times-circle fa-lg not-check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-left">Mémoire de traduction</th>
          <td>
            <i class="fa fa-times-circle fa-lg not-check"></i>
          </td>
          <td>
            <i class="fa fa-times-circle fa-lg not-check"></i>
          </td>
          <td>
            <i class="fa fa-check-circle fa-lg check"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
