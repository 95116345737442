<button type="button" class="btn btn-outline-primary mb-3" (click)="addIsCollapsed = !addIsCollapsed"
  [attr.aria-expanded]="!addIsCollapsed" aria-controls="collapseExample" [disabled]="checkedSubscribe">
  {{'services_add_button' | localise}}
</button>
<ng-container *ngIf="checkedSubscribe">
  <p>{{'services_none_yet_subscribe' | localise}}</p>
</ng-container>
<div class="card mb-3" [ngbCollapse]="addIsCollapsed">
  <div class="card-body">
    <p>{{'services_add_expl' | localise}}</p>
    <form [formGroup]="prestationForm.Group">
      <app-form-dropdown [parameters]="srcLangDropDown" (onSelect)="selectSourceLang($event)"></app-form-dropdown>
      <app-form-dropdown [parameters]="dstLangDropDown" (onSelect)="selectDestLang($event)"></app-form-dropdown>
      <app-form-general-error [submitForm]="prestationForm"></app-form-general-error>
      <button class="btn btn-primary" (click)='addPrestation()'
        [disabled]="!sourceLangISO639 || !destLangISO639">{{'services_add' | localise}}</button>
    </form>
    <p class="card-text"><small><strong>{{'services_dont_see_title' | localise}} </strong> <span
          [innerHTML]="'services_dont_see_response' | localise"></span></small></p>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <h4>{{'once_page_price' | localise}}</h4>

    <p>{{ 'once_page_information' | localise}}</p>
    <div class="row ">

    <div class="col ">
    <form [formGroup]="defaultPriceForm" (ngSubmit)="defaultPriceSubmit()">
      <div class="col-auto "> 
        <div class="input-group mb-2 ">
          <div class="input-group-prepend">
            <div class="input-group-text">{{'once_page_price_default' | localise}}</div>
          </div>
          <div class="col-xs-3">
            <input type="number" class="form-control" formControlName="defaultException">
          </div>
          <div class="col-xs-3">
            <input type="text" value="{{ watchTrad.defaultException}} €" disabled> 
          </div>
          <div class="col-xs-3">
            <input type="submit" class="form-control btn-info" value="{{'reset_password_send' | localise}}">
          </div>
        </div>
      </div>
    </form>
    </div>
  
    </div>
  </div>
</div>

<ng-container *ngIf="services && templateList && docTypeMap">
  <ng-container *ngIf="services.length > 0">
    <h4>{{ 'services_list_title' | localise }}</h4>
    <p>{{ 'services_expl' | localise }}</p>
  </ng-container>

  <ng-container *ngIf="services.length === 0">
    <p>{{'services_none_yet' | localise}}</p>
  </ng-container>

  <ng-container *ngIf="services.length === 1">
    <div class="card mb-3" *ngFor="let service of services; let i = index">
      <div class="card-header">
        {{service.data.srcLanguageIso639 | iso639ToNative }} &rArr;
        {{service.data.destLanguageIso639 | iso639ToNative }}
      </div>
      <div class="card-body">
        <app-traducteur-service [service]="service" [templateList]="templateList" [documentTypeMap]="docTypeMap">
        </app-traducteur-service>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="services.length > 1">
    <ngb-accordion #acc="ngbAccordion" activeIds="{{currentActiveId}}" [closeOthers]="true"
      (panelChange)="beforePanelChange($event)" class="mb-3">
      <ngb-panel *ngFor="let service of services; let i = index" id="{{i}}">
        <ng-template ngbPanelTitle>
          {{service.data.srcLanguageIso639 | iso639ToNative }} &rArr;
          {{service.data.destLanguageIso639 | iso639ToNative }}
        </ng-template>
        <ng-template ngbPanelContent>
          <app-traducteur-service [service]="service" [templateList]="templateList" [documentTypeMap]="docTypeMap">
          </app-traducteur-service>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </ng-container>
</ng-container>


<app-wait [show]='busy'></app-wait>