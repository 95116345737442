<div *ngIf="!!messageBox" class="card">
  <div class="card-header">
    <h5>{{ 'prestation_order_messaging_title' | localise }}</h5>
  </div>
  <div class="card-body p-0">
    <div class="input-group input-group-sm mb-0 p-3">
      <input type="text" (input)="onNewMessage($event.target.value)" value="{{ msgText }}"  (keydown)="onKeydown($event)" class="form-control"
        placeholder="" aria-label="" />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="send()">
          {{ 'prestation_order_messaging_send' | localise }}
        </button>
      </div>
    </div>
    
    <div class="container-fluid" style="max-height: 500px; overflow-y: scroll">
      <div *ngFor="let message of messages" class="row">
        <div class="col-10" [ngClass]="{ 'offset-2': isMe(message) }">
          <div class="alert d-flex flex-column" [ngClass]="{
              'alert-primary': isNotMe(message),
              'alert-success': isMe(message)
            }">
            <div *ngIf="isMe(message)" style="font-size: 0.8em; font-style: italic">
              {{ 'prestation_order_messaging_me' | localise }}
            </div>
            <div *ngIf="!isMe(message)" style="font-size: 0.8em; font-style: italic">
              {{ 'prestation_order_messaging_client' | localise }}
            </div>
            <div *ngIf="!message.file">{{ message.text }}</div>
            <div *ngIf="message.file">
              <img class="img-fluid clickable"  (click)="openChatFile(message)" src="{{message.file.downloadURL}}"/>              
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div>
</div>