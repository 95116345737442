import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import * as firebase from "firebase";
import { map } from "rxjs/operators";
import { Prestation } from "../../../../../../common/src/bdd/prestation/Prestation";
import { User } from "../../../../../../common/src/bdd/user/User";
import { Traducteur } from "../../../../../../common/src/bdd/traducteur/Traducteur";
import { MessageBox } from "../../../../../../common/src/bdd/messaging/MessageBox";
import { IMessage } from "../../../../../../common/src/bdd/interfaces/IMessaging";
import { HieroBDD } from "../../../../services/hierobdd.service";
import { LocalisationService } from "../../../../../../common/src/modules/localisation/localisation.service";
import { DocumentType } from "../../../../../../common/src/bdd/documents/DocumentType";
import {
  EnumPrestationState,
  IPrestation,
} from "../../../../../../common/src/bdd/interfaces/IPrestation";
import { IDocument } from "../../../../../../common/src/bdd/interfaces/IDocument";
import { IFile } from "../../../../../../common/src/bdd/interfaces/IFile";
import { AppModalConfirmComponent } from "../../../../../../common/src/utility/modal/confirm-modal.component";
import { ITraducteur } from "../../../../../../common/src/bdd/interfaces/ITraducteur";
import { InterpretationService } from "../../../../services/interpretation.services";
import { franceRelance } from "../../../../utility/components/franceRelance";
import { hieroAccounts } from "../../../../utility/components/hieroAccounts";

@Component({
  templateUrl: "./interpretation.component.html",
  styleUrls: ["./interpretation.component.scss"],
})
export class InterpretationComponent implements OnInit {
  private traducteurId: string = undefined;
  private traducteurData: ITraducteur = undefined;
  public interpretation: any;
  busy = true;
  private isPart: boolean = false;

  documentTypeMap: DocumentType;

  user: User;
  traducteur: Traducteur;

  messageBox: MessageBox;
  messages: IMessage[] = [];

  public command: any;
  // Get prestation id from params
  interpretationId: string = this.route.snapshot.paramMap.get("id");

  constructor(
    private hiero: HieroBDD,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private localisation: LocalisationService,
    private interpretationService: InterpretationService
  ) {}

  async ngOnInit() {
    // Watch for translator
    await this.getTraductor();
    this.getInterpretation();
  }

  async getTraductor() {
    const refTaductor = await this.hiero.DB.collection("traducteurs")
      .where("uid", "==", this.hiero.Auth.User.Id)
      .get();
    refTaductor.docs.map((doc) => {
      this.traducteurId = doc.id;
      this.traducteurData = doc.data() as ITraducteur;
      return this.traducteurData;
    });
  }

  async getInterpretation() {
    this.interpretationService
      .getInterpretation(this.interpretationId)
      .then((data) => {
        this.isPart = !data.isProfessionnel;
        this.interpretation = data.data;
      });
  }

  /* private async getCommand(prestation: Prestation) {
    if (prestation.Data.isProfessionalPrestation) {
      const docRef = this.hiero.DB.collection("professionnel_commandes").where(
        "prestationId",
        "==",
        this.prestationId
      );
      return docRef.get().then((snapshot) => {
        return snapshot.docs[0].data();
      });
    } else {
      const docRef = this.hiero.DB.collection("particuliers_commandes").where(
        "prestationId",
        "==",
        this.prestationId
      );
      return docRef.get().then((snapshot) => {
        return snapshot.docs[0].data();
      });
    }
  }

  checkBusy() {
    if (this.prestation && this.documentTypeMap) {
      this.busy = false;
    }
  }

  get haveChatWindow(): boolean {
    if (!this.prestation) {
      return false;
    }

    return (
      this.prestation.Data.state === EnumPrestationState.Translating ||
      this.prestation.Data.state ===
        EnumPrestationState.WaitingForValidationFromClient ||
      this.prestation.Data.state === EnumPrestationState.Validated
    );
  }

  getDocumentName(code: string) {
    if (this.documentTypeMap) {
      return this.documentTypeMap.GetDocumentName(
        this.localisation.CurrentLanguageISO639,
        code
      );
    } else {
      return "";
    }
  }

  ngOnDestroy() {
    if (this.traducteurSub) {
      this.traducteurSub.unsubscribe();
    }
    if (this.prestationSub) {
      this.prestationSub.unsubscribe();
    }

    if (this.prestation) {
      this.prestation.cleanup();
    }
  }

  async validate() {
    const modalRef = this.modalService.open(AppModalConfirmComponent, {
      centered: true,
    });
    modalRef.componentInstance.header = this.localisation.localise(
      "prestation_order_validate_title"
    );
    modalRef.componentInstance.message = this.localisation.localise(
      "prestation_order_validate_msg"
    );
    modalRef.componentInstance.ok = this.localisation.localise(
      "prestation_order_confirm_ok"
    );
    modalRef.componentInstance.cancel = this.localisation.localise(
      "prestation_order_confirm_cancel"
    );
    let result = false;
    try {
      result = await modalRef.result;
    } catch {}

    if (result) {
      this.busy = true;
      try {
        await this.prestation.Validate();
      } catch (err) {
        console.error(err.message);
      } finally {
        this.busy = false;
      }
    }
  }

  public translate(document: IDocument) {
    this.router.navigate([
      "/app",
      "prestation",
      this.prestation.Id,
      "document",
      document.deviceStorageId,
    ]);
  }

  public get canValidate(): boolean {
    let valid = true;
    this.prestation.Data.documents.forEach((doc) => {
      if (!doc.translated || doc.translated.length === 0) {
        valid = false;
      }
    });
    return valid;
  }

  async removeFile(document: IDocument, file: IFile) {
    this.busy = true;
    try {
      await this.prestation.DeleteTranslatedFile(
        this.hiero.Storage,
        document,
        file
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.busy = false;
    }
  }*/

  async accept() {
    let db = firebase.firestore();

    const request = db
      .collection("customers")
      .doc(firebase.auth().currentUser.uid)
      .collection("subscriptions")
      .where("status", "in", ["trialing", "active"]);

    return request.get().then(async (snapshot) => {
      // In this implementation we only expect one active or trialing subscription to exist.
      const doc = snapshot.docs[0];
      if (
        doc ||
        franceRelance.includes(this.hiero.Auth.User.Id) ||
        hieroAccounts.includes(this.hiero.Auth.User.Id)
      ) {
        const modalRef = this.modalService.open(AppModalConfirmComponent, {
          centered: true,
        });
        modalRef.componentInstance.header = this.localisation.localise(
          "prestation_order_accept_title"
        );
        modalRef.componentInstance.message = this.localisation.localise(
          "prestation_order_accept_msg"
        );
        modalRef.componentInstance.ok = this.localisation.localise(
          "prestation_order_confirm_ok"
        );
        modalRef.componentInstance.cancel = this.localisation.localise(
          "prestation_order_confirm_cancel"
        );
        let result = false;
        try {
          result = await modalRef.result;
        } catch {}

        if (result) {
          this.busy = true;
          try {
            // UPDATE particuliers_commandes
            if (!this.isPart) {
              const req = firebase
                .firestore()
                .collection("interpretations_commandes")
                .doc(this.interpretationId);
              req.get().then((snapshot) => {
                snapshot.ref.update({
                  state: EnumPrestationState.Translating,
                  acceptedByTranslatorAt: new Date(),
                });
              });
            } else {
              const req = firebase
                .firestore()
                .collection("particuliers_commandes")
                .doc(this.interpretationId);
              req.get().then((snapshot) => {
                snapshot.ref.update({
                  state: EnumPrestationState.WaitingForPayment,
                  acceptedByTranslatorAt: new Date(),
                });
              });
            }
          } catch (err) {
            console.error(err.message);
          } finally {
            this.busy = false;
          }
        }
      } else {
        const modalRef = this.modalService.open(AppModalConfirmComponent, {
          centered: true,
        });
        modalRef.componentInstance.header = "Vous n'avez pas d'abonnement";
        modalRef.componentInstance.message =
          "Veillez souscrire à un abonnement avant d'accepter une mission";
        modalRef.componentInstance.ok = "Souscrire";
        modalRef.componentInstance.cancel = "Retour";
        let result = false;
        try {
          result = await modalRef.result;
          console.log(result);
        } catch {}

        if (result) {
          this.router.navigate(["/app", "card"]);
        }
      }
    });
  }

  async refuse() {
    const modalRef = this.modalService.open(AppModalConfirmComponent, {
      centered: true,
    });
    modalRef.componentInstance.header = this.localisation.localise(
      "prestation_order_refuse_title"
    );
    modalRef.componentInstance.message = this.localisation.localise(
      "prestation_order_refuse_msg"
    );
    modalRef.componentInstance.ok = this.localisation.localise(
      "prestation_order_confirm_ok"
    );
    modalRef.componentInstance.cancel = this.localisation.localise(
      "prestation_order_confirm_cancel"
    );
    let result = false;
    try {
      result = await modalRef.result;
    } catch {}

    if (result) {
      this.busy = true;
      try {
        // UPDATE particuliers_commandes
        if (!this.isPart) {
          const req = firebase
            .firestore()
            .collection("interpretations_commandes")
            .doc(this.interpretationId);
          req.get().then((snapshot) => {
            snapshot.ref.update({
              state: EnumPrestationState.RefusedByTranslator,
              refusedByTranslatorAt: new Date(),
            });
          });
        } else {
          const req = firebase
            .firestore()
            .collection("particuliers_commandes")
            .doc(this.interpretationId);
          req.get().then((snapshot) => {
            snapshot.ref.update({
              state: EnumPrestationState.RefusedByTranslator,
              refusedByTranslatorAt: new Date(),
            });
          });
        }
      } catch (err) {
        console.error(err.message);
      } finally {
        this.busy = false;
      }
    }
  }
}
