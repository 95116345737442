import { Traducteur } from './Traducteur';
import { EnumPrestationState, IPrestation } from '../interfaces/IPrestation';
import { Prestation } from '../prestation/Prestation';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { PartialObserver, Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';

const FIREBASE_PRESTATION_COLLECTION = 'prestations';

export class TraducteurOrderParam {
  column: string;
  desc: boolean;
}

export interface IPrestationNotification {
  prestation: IPrestation;
  from: EnumPrestationState;
  to: EnumPrestationState;
}

/**
 * Use this class to list, filter, order and monitor the orders for a specific translator.
 * Note: this class will recover ALL records for this translator, filter and order them client side.
 * This will most likely need to be optimised once we know more about the general usage of this class.
 * Remember to call cleanup before destroying this object to avoid memory leaks.
 */
export class TraducteurPrestations {

  private _traducteur: Traducteur;
  
  private _prestationMap: Map<string, Prestation>;
  private _prestationArr: Prestation[]; // All the unfiltered unordered unpaged prestations
  private _filteredArr: Prestation[];   // The filtered, ordered, paged prestations
  private _totalFilteredUnpagedCount = 0;

  private _stopListening;
  private _listSubject: BehaviorSubject<Prestation[]>;

  private _states: EnumPrestationState[];
  private _order: TraducteurOrderParam[];
  private _page = 0;
  private _pageSize = 10;

  private _prestationChangeStateSubject: Subject<IPrestationNotification>;

  private constructor (traducteur: Traducteur, query: firebase.firestore.Query, initSnap: firebase.firestore.QuerySnapshot, watch: boolean, states?: EnumPrestationState[], order?: TraducteurOrderParam[]) {

    this._states = states;
    this._order = order;
    this._traducteur = traducteur;
    this.reconstruct(initSnap);
    this._listSubject = new BehaviorSubject<Prestation[]>(this.All);
    this._prestationChangeStateSubject = new Subject<IPrestationNotification>();

    if (watch) {
      this._stopListening = query.onSnapshot({
        next: (snapshot: firebase.firestore.QuerySnapshot) => {


          // Go through all changes
          snapshot.docChanges().forEach(
            (change: firebase.firestore.DocumentChange) => {

              // Get a corresponding old one to be able to see if it exists or not
              const old = this._prestationMap.get(change.doc.id);

              const prestation: IPrestation = change.doc.data() as IPrestation;
              
              // SIGNAL NEWLY ADDED
              if (change.type === 'added' && !old) {

                if (prestation.state === EnumPrestationState.WaitingForTranslator) {
                  this._prestationChangeStateSubject.next({
                    prestation: prestation,
                    from: EnumPrestationState.Defining,
                    to: prestation.state
                  });
                }

              } else if (change.type === 'modified') {

                // SIGNAL MODIFIED
                if (old) {
                  if (prestation.state !== old.Data.state) {
                    this._prestationChangeStateSubject.next({
                      prestation: prestation,
                      from: old.Data.state,
                      to: prestation.state
                    });
                  }

                }

              }

            }
          );

          this.reconstruct(snapshot);
        },
        error: (err) => {

        }
      });
    }
  }

  public get Count(): number {
    return this._filteredArr.length;
  }

  /**
   * Gets the number of filtered items before paging
   */
  public get TotalCount(): number {
    return this._totalFilteredUnpagedCount;
  }

  /**
   * Get a copy of your query
   */
  public get All(): Prestation[] {
    return [...this._filteredArr];
  }

  /**
   * Update your query here, it will not redownload the data, but will fire the subscription with re-ordered data (if there is one in place).
   * Otherwise get the data using All.
   * @param states 
   * @param order 
   */
  public updateQuery(states: EnumPrestationState[], order: TraducteurOrderParam[], page: number, pageSize: number) {
    this._states = states;
    this._order = order;
    this._page = page;
    this._pageSize = pageSize;

    // Filter and order
    this._filteredArr = this.getWithQuery(this._states, this._order, page, pageSize);

    if (this._listSubject) {
      this._listSubject.next(this.All);
    }
  }

  private getWithQuery(states: EnumPrestationState[], order: TraducteurOrderParam[], page: number = 0, pageSize: number = 10) {

    // Filter by selected states
    let result: Prestation[] = [];
    if (states && states.length > 0) {
      const stateSet: Set<EnumPrestationState> = new Set<EnumPrestationState>();
      states.forEach(
        (state: EnumPrestationState) => {
          stateSet.add(state);
        }
      );

      result = this._prestationArr.filter(
        (prestation: Prestation) => {
          return stateSet.has(prestation.Data.state);
        }
      );
    } else {
      result = this._prestationArr;
    }

    // Order
    if (order && order.length > 0) {
      for (let i = order.length - 1; i >= 0; --i) {
        const orderParam = order[i];

        const sorted: Prestation[] = result.sort(
          (a: Prestation, b: Prestation) => {
            let res = 0;
             if (typeof(a.Data[orderParam.column]) === 'string') {
              res = a.Data[orderParam.column].localeCompare(b.Data[orderParam.column]);
             } else {
              res = a.Data[orderParam.column] - b.Data[orderParam.column];
             }
            if (orderParam.desc) { res = res * -1; }
            return res;
          }
        );
        result = sorted;
      }
    }

    // Save the total filtered count
    this._totalFilteredUnpagedCount = result.length;

    // Paging
    const startPage = page * pageSize;
    result = result.slice(startPage, startPage + pageSize);

    return result;
  }

  /**
   * Get a specific order, given its Id
   * @param id 
   */
  public getPrestation(id: string): Prestation {
    return this._prestationMap.get(id);
  }

  private reconstruct(snap: firebase.firestore.QuerySnapshot) {
    this._prestationMap = new Map<string, Prestation>();
    this._prestationArr = [];
    snap.forEach(
      (doc: firebase.firestore.QueryDocumentSnapshot) => {
        const prestRaw = doc.data() as IPrestation;
        const prestation = new Prestation(this._traducteur.User, true, doc.ref, doc.id, prestRaw);
        this._prestationMap.set(doc.id, prestation);
        this._prestationArr.push(prestation);
      }
    );

    // Filter and order
    this._filteredArr = this.getWithQuery(this._states, this._order);

    if (this._listSubject) {
      this._listSubject.next(this.All);
    }
  }

  /**
   * Subscribe for changes to this list
   * @param observer 
   */
  public WatchList(observer: PartialObserver<Prestation[]>): Subscription {
    return this._listSubject.subscribe(observer);
  }

  public WatchEvents(observer: PartialObserver<IPrestationNotification>): Subscription {
    return this._prestationChangeStateSubject.subscribe(observer);
  }

  /**
   * Make sure you call this function to avoid memory leaks.
   */
  public cleanup() {
    if (this._stopListening) {
      this._stopListening();
      this._stopListening = null;
    }
  }

  /**
   * Create the list object. This will get the initial list and setup the watcher if required.
   * @param traducteur The translator for whom we are getting the list
   * @param watch If true, a watch will be placed on this list, and automatic updates will be transmitted via the Watch* subscriptions
   * @param states A list of states that you want to filter the list by
   * @param order The order in which you wish to display the results
   */
  public static async Init(traducteur: Traducteur, watch: boolean, states?: EnumPrestationState[], order?: TraducteurOrderParam[]) {

    const query = traducteur.User.DB.collection(FIREBASE_PRESTATION_COLLECTION)
      .where('traducteurId', '==', traducteur.Id);
      // TODO: A way to filter these results to reduce query time !
      // .where('archived', '==', (archived ? true : false));

    const snapshot: firebase.firestore.QuerySnapshot = await query.get();
    return new TraducteurPrestations(traducteur, query, snapshot, watch, states, order);
  }
}
