

import { Injectable } from '@angular/core';
import { IGeoCoords, IAddress } from '../../../common/src/bdd/interfaces/ITraducteur';
import { Config } from '../../../common/src/services/config.service';

declare var google: any;

export interface LatLong {
  latitude: number;
  longitude: number;
}

export interface GeoCodeResult {
  address: IAddress;
  coords: LatLong;
}

@Injectable()
export class GoogleGeo {

  private _loaded = false;

  constructor(private config: Config) {
    const API_KEY = config.Environment.google_geocode_apikey;
    const url = 'https://maps.googleapis.com/maps/api/js?key=' + API_KEY + '&callback=onGoogleMapsLoaded';

    // const script = '<script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=initMap"async defer></script>';

    const el = document.createElement('script');
    el.setAttribute('src', url);
    el.setAttribute('async', '');
    el.setAttribute('defer', '');

    (<any>window).onGoogleMapsLoaded = () => {
      this._loaded = true;
    };

    document.body.appendChild(el);
  }

  geocode(address: string): Promise<GeoCodeResult[]> {
    if (!this._loaded) {
      console.warn('Google maps not yet loaded');
      return;
    }

    const geocoder = new google.maps.Geocoder();
    return new Promise(
      (resolve, reject) => {
        geocoder.geocode( { 'address': address},
        (results, status) => {
          if (status === 'OK') {
            // console.log(results);

            const adds: GeoCodeResult[] = [];

            results.forEach(result => {

              const extracted: IAddress = {
                formatted: result.formatted_address,
                number: this.getComponentFromList('street_number', result.address_components),
                street: this.getComponentFromList('route', result.address_components),
                city: this.getComponentFromList('locality', result.address_components),
                country: this.getComponentFromList('country', result.address_components),
                code: this.getComponentFromList('postal_code', result.address_components),
                extra: ''
              };


              adds.push({
                address: extracted,
                coords: {
                  latitude: result.geometry.location.lat(),
                  longitude: result.geometry.location.lng()
                }
              });
            });
            // console.log(adds);
            resolve(adds);

          } else {
            resolve([]);
            // reject('Geocode was not successful for the following reason: ' + status);
          }
        });
      }
    );


  }

  private getComponentFromList(type: string, components) {

    let result = '';
    if (components) {      
      components.forEach(
        (component) => {

          const comp = this.getComponent(type, component);
          if (!!comp) {
            result = comp;
          }

        }
      );
    }
    return result;
  }

  private getComponent(type: string, component) {

    let result = null;
    if (component.types) {

      const found = component.types.find((t: string) => t.toLowerCase().trim() === type);
      if (found) {
        result = component.long_name;
        if (!result) {
          result = component.short_name;
        }
      }
    }
    return result;

  }


}
