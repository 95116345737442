<!-- <ng-template #popFilterContent>
  <a
    *ngFor="let filter of filterUIAsser"
    class="badge clickable mr-1"
    [ngClass]="{
      'badge-info': filter.active,
      'badge-secondary': !filter.active
    }"
    (click)="toggleState(filter)"
    >{{ filter.state | prestationStateName }}</a
  >
</ng-template> -->
<ng-template #popFilterTitle>
  {{ "tasks_filters" | localise }}
</ng-template>
<div class="row pl-3">
  <div>
    <h3 class="mr-3">{{ "consult_missions" | localise }}</h3>
  </div>
  <!-- Le menu pour Afficher les commandes de -->
  <div>
    <select
      class="custom-select rounded-pill my-color"
      (change)="selectOption($event.target.value)"
    >
      <option selected value="0">{{ "menu_label_all" | localise }}</option>
      <option value="1">{{ "menu_label_traductor_asser" | localise }}</option>
      <option value="2">{{ "menu_label_traductor_classic" | localise }}</option>
    </select>
  </div>
</div>

<!-- traducteur assermenté -->
<div class="mt-3">
  <div *ngIf="showAsser">
    <h4>{{ "menu_label_traductor_asser" | localise }}</h4>
    <table class="table table-striped table-hover table-bordered">
      <thead>
        <tr>
          <th scope="col" class="align-middle">
            <a
              *ngIf="descAsser"
              class="badge badge-info menu_badge text-white clickable"
              (click)="toggleOrderAsser()"
              ><i class="fa fa-angle-down"></i
            ></a>
            <a
              *ngIf="!descAsser"
              class="badge badge-info menu_badge text-white clickable"
              (click)="toggleOrderAsser()"
              ><i class="fa fa-angle-up"></i
            ></a>
            {{ "tasks_sent_on" | localise }}
          </th>
          <th scope="col" class="align-middle">
            {{ "type_doc_commande" | localise }}
          </th>
          <th scope="col" class="align-middle">
            {{ "tasks_src_lang" | localise }}
          </th>
          <th scope="col" class="align-middle">
            {{ "tasks_src_country" | localise }}
          </th>
          <th scope="col" class="align-middle">
            {{ "tasks_dst_country" | localise }}
          </th>
          <!------------- TO DO : page number --------------->
          <!--  <th scope="col" class="align-middle">
            {{ "page_number" | localise }}
          </th> -->
          <th scope="col" class="align-middle">
            {{ "tasks_price" | localise }}
          </th>
          <th scope="col" class="align-middle">
            <!-- <a
              class="badge badge-info menu_badge text-white clickable"
              [ngbPopover]="popFilterContent"
              [popoverTitle]="popFilterTitle"
              [placement]="['bottom']"
              ><i class="fa fa-filter"></i
            ></a> -->
            {{ "tasks_state" | localise }}
          </th>
          <!-- <th scope="col" class="align-middle"></th> -->
        </tr>
      </thead>
      <tbody>
        <!-- pour activer l'abonnement <tr *ngFor="let prestation of prestationList; let i = index" (click)=" checkedSubscribe ? open(prestation) : alertMessage() " class="clickable">-->
        <tr
          *ngFor="
            let asser of asserList
              | slice: (pageAsser - 1) * pageSize:pageAsser * pageSize
          "
          (click)="open(asser)"
          class="clickable"
        >
          <td class="align-middle">
            {{ asser.Data.createdAt | date: "d/MM/yyyy, H:mm" }}
          </td>
          <td class="align-middle">
            {{ asser.Data.documents[0].documentTypeId }}
          </td>
          <td class="align-middle">
            {{ asser.Data.srcLanguageIso639 | iso639ToNative }}
          </td>
          <td class="align-middle">
            {{ asser.Data.srcCountryCode | country }}
          </td>
          <td class="align-middle">
            {{ asser.Data.destLanguageIso639 | iso639ToNative }}
          </td>
          <!------------- TO DO : page number --------------->
          <!-- <td class="align-middle">
            
          </td> -->
          <td class="align-middle">{{ asser.Data.price.traducteurHT }} €</td>
          <td class="align-middle">
            {{ asser.Data.state | prestationStateName }}
          </td>
          <!-- <td class="align-middle">
            <i class="fa fa-envelope text-info" aria-hidden="true"></i> x
            {{ getUnreadMessages(prestation) }}
          </td> -->
        </tr>
      </tbody>
    </table>
    <ngb-pagination
      *ngIf="!!asserList"
      [collectionSize]="asserList.length"
      [page]="pageAsser"
      [pageSize]="pageSize"
      (pageChange)="onPageChange($event, 0)"
      aria-label="Default pagination"
    ></ngb-pagination>
  </div>
  <!-- <ngb-pagination *ngIf="!!prestations" [collectionSize]="prestations.TotalCount" [page]="page" [pageSize]="pageSize" (pageChange)="onPageChange($event, 0)" aria-label="Default pagination"></ngb-pagination>
  <app-wait [show]='busy'></app-wait>  -->

  <!-- end traducteur assermenté -->
  <!-- traducteur classique -->

  <div *ngIf="showClassic" class="mt-3">
    <h4>{{ "menu_label_traductor_classic" | localise }}</h4>
    <table class="table table-striped table-hover table-bordered">
      <thead>
        <tr>
          <th scope="col" class="align-middle">
            <a
              *ngIf="descClassic"
              class="badge badge-info menu_badge text-white clickable"
              (click)="toggleOrderClassic()"
              ><i class="fa fa-angle-down"></i
            ></a>
            <a
              *ngIf="!descClassic"
              class="badge badge-info menu_badge text-white clickable"
              (click)="toggleOrderClassic()"
              ><i class="fa fa-angle-up"></i
            ></a>
            {{ "tasks_sent_on" | localise }}
          </th>
          <th scope="col" class="align-middle">
            {{ "order_no_asser_document_name" | localise }}
          </th>
          <th scope="col" class="align-middle">
            {{ "tasks_src_lang" | localise }}
          </th>
          <th scope="col" class="align-middle">
            {{ "tasks_dst_country" | localise }}
          </th>
          <!------------- TO DO : page number --------------->
          <!--  <th scope="col" class="align-middle">
            {{ "page_number" | localise }}
          </th> -->
          <th scope="col" class="align-middle">
            {{ "tasks_price" | localise }}
          </th>
          <th scope="col" class="align-middle">
            <!-- <a
              class="badge badge-info menu_badge text-white clickable"
              [ngbPopover]="popFilterContent"
              [popoverTitle]="popFilterTitle"
              [placement]="['bottom']"
              ><i class="fa fa-filter"></i
            ></a> -->
            {{ "tasks_state" | localise }}
          </th>
          <!-- <th scope="col"></th> -->
        </tr>
      </thead>
      <tbody>
        <!--<tr *ngFor="let prestation of prestationList; let i = index" (click)="checkedSubscribe ? open(prestation) : alertMessage()" class="clickable"> -->
        <tr
          *ngFor="
            let prestation of classicList
              | slice: (pageClassic - 1) * pageSize:pageClassic * pageSize
          "
          (click)="open(prestation)"
          class="clickable"
        >
          <td class="align-middle">
            {{ prestation.Data.createdAt | date: "d/MM/yyyy, H:mm" }}
          </td>
          <td class="align-middle">
            {{ prestation.Data.documents[0].documentTypeId }}
          </td>
          <td class="align-middle">
            {{ prestation.Data.srcLanguageIso639 | iso639ToNative }}
          </td>
          <!-- <td *ngIf="prestation.Data.srcCountryCode==''" class="align-middle">{{ prestation.Data.srcCountryCode | country }}</td> -->
          <td class="align-middle">
            {{ prestation.Data.destLanguageIso639 | iso639ToNative }}
          </td>
          <!------------- TO DO : page number --------------->
          <!-- <td *ngIf="prestation.Data.srcCountryCode == ''" class="align-middle">
          </td> -->
          <td class="align-middle">
            {{ prestation.Data.price.traducteurHT }} €
          </td>
          <td class="align-middle">
            {{ prestation.Data.state | prestationStateName }}
          </td>
          <!-- <td *ngIf="prestation.Data.srcCountryCode == ''" class="align-middle">
            <i class="fa fa-envelope text-info" aria-hidden="true"></i> x
            {{ getUnreadMessages(prestation) }}
          </td> -->
        </tr>
      </tbody>
    </table>
    <ngb-pagination
      *ngIf="!!classicList"
      [collectionSize]="classicList.length"
      [page]="pageClassic"
      [pageSize]="pageSize"
      (pageChange)="onPageChange($event, 1)"
      aria-label="Default pagination"
    ></ngb-pagination>
  </div>

  <app-wait [show]="busy"></app-wait>
  <!-- <ngb-pagination *ngIf="!!prestations" [collectionSize]="prestations.TotalCount" [page]="page" [pageSize]="pageSize" (pageChange)="onPageChange($event, 1)" aria-label="Default pagination"></ngb-pagination>
  <app-wait [show]='busy'></app-wait>  -->
  <!--
  <ngb-pagination *ngIf="!!prestations" [collectionSize]="prestations.TotalCount" [page]="pageClass" [pageSize]="pageSize" (pageChange)="onPageChange($event)" aria-label="Default pagination"></ngb-pagination>
  <app-wait [show]='busy'></app-wait> 
--></div>
<!-- end traducteur classique  -->
