import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ITraducteur } from "../../../../../../common/src/bdd/interfaces/ITraducteur";
import { HieroBDD } from "../../../../services/hierobdd.service";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import tippy from "tippy.js";
import { Router, ActivatedRoute } from "@angular/router";
import { InterpretationService } from "../../../../services/interpretation.services";

export interface Ievent {
  id: string;
  title: string;
  start?: string;
  end?: string;
  allDay?: boolean;
  color?: string;
}
interface Iinterpreter {
  traducteur: ITraducteur;
  isInterpreter: boolean;
  availability: string[];
}

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements OnInit {
  private traducteurId: string = undefined;
  private traducteurData: ITraducteur = undefined;

  public calendarPlugins = [dayGridPlugin, timeGridPlugin]; // important!
  public calendarEvents: Ievent[] = [];

  constructor(
    private hiero: HieroBDD,
    private modal: NgbModal,
    private router: Router,
    private interpretationService: InterpretationService
  ) {}

  async ngOnInit() {
    await this.getTraductor();
    this.getMissions();
  }

  /* addAvailable(mydate: string) {
    const param = this.modal.open(EventModalComponent);
    param.componentInstance.parentDate = mydate;
  } */

  async getTraductor() {
    console.log("Id", this.hiero.Auth.User.Id);
    const refTaductor = await this.hiero.DB.collection("traducteurs")
      .where("uid", "==", this.hiero.Auth.User.Id)
      .get();
    const trad = refTaductor.docs.map((doc) => {
      this.traducteurId = doc.id;
      this.traducteurData = doc.data() as ITraducteur;
      return this.traducteurData;
    });
  }

  public async getMissions() {
    this.interpretationService.getInterpretations().then((_) => {
      _.map((data) => {
        let color;
        if (data.isProfessionnel) color = "#5f2454";
        else color = "#2d4054";

        this.calendarEvents = this.calendarEvents.concat({
          id: data.id,
          title: data.data.event,
          start: data.data.missions.date.toDate(),
          color: color,
          allDay: false,
          end: this.end(
            data.data.missions.date.toDate(),
            data.data.missions.extHours
          ),
        });
      });
    });
    /* await this.hiero.DB.collection("interpretations_commandes")
      .where("traducteur", "==", this.traducteurData.businessName)
      .get()
      .then((snap) =>
        snap.docs.map((_) => {
          this.calendarEvents = this.calendarEvents.concat({
            id: _.id,
            title: _.data().missions.context,
            start:
              _.data().missions.date + "T" + _.data().missions.time + ":00",
            color: "#5f2454",
            allDay: false,
            end:
              _.data().missions.date +
              "T" +
              this.end(_.data().missions.time, _.data().missions.extHours) +
              ":00",
          });
        })
      );
    await this.hiero.DB.collection("particuliers_commandes")
      .where("traducteur", "==", this.traducteurData.businessName)
      .get()
      .then((snap) =>
        snap.docs.map((_) => {
          console.log(_.data());

          this.calendarEvents = this.calendarEvents.concat({
            id: _.id,
            title: _.data().missions.context,
            start:
              _.data().missions.date + "T" + _.data().missions.time + ":00",
            end:
              _.data().missions.date +
              "T" +
              this.end(_.data().missions.time, _.data().missions.extHours) +
              ":00",
            color: "#2d4054",
            allDay: false,
          });
          return _.data();
        })
      ); */
  }

  end(startTime, endTime) {
    let d = endTime;
    let a = d.split(":"); // split it at the colons
    let time = +a[0] * 60 + +a[1];

    var newDateObj = new Date(startTime.getTime() + time * 60000);

    let dformat =
      [
        newDateObj.getFullYear(),
        newDateObj.getMonth() + 1,
        newDateObj.getDate(),
      ].join("-") +
      "T" +
      [
        newDateObj.getHours(),
        newDateObj.getMinutes(),
        newDateObj.getSeconds() + "0",
      ].join(":");
    //=> dformat => 'yyyy/mm/ddThh:mm:ss'

    return dformat;
  }

  test(event) {
    this.router.navigate(["app", "interpretations", event.event.id]);
  }

  /* ngAfterViewInit() {
    tippy("#myButton", { content: "I'm a Tippy tooltip!" });
  } */
}
