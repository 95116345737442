

<table class="table table-sm">
  <thead>
    <tr>
      <th scope="col">{{'services_list_active' | localise}}</th>
      <th scope="col" sortable="localisedCountry" (sort)="onSort($event)">{{'services_list_country' | localise}}</th>
      <th scope="col">{{'services_list_document_type' | localise}}</th>
      <th scope="col">{{'services_list_average_price' | localise}}</th>
      <th scope="col">{{'services_list_your_price' | localise}}</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let template of templateDetail">
      <td class="align-middle"><app-switch [isOn]="template.on" (onToggled)="onToggled($event, template)"></app-switch></td>
      <td class="align-middle">{{ getCountryName(template.template.Data.srcCountryCode) }}</td>
      <td class="align-middle">{{ getDocumentName(template.template.Data.documentTypeId) }}</td>
      <td class="align-middle">{{ template.priceHT }} €</td>
      <td class="align-middle">{{ (template.overridePriceHT > -1 ? (template.overridePriceHT + ' €') : '') }} </td>
      <td class="align-middle">
        <div *ngIf='template.on' class="d-flex flex-row">
          
          <app-debounce-input [type]="number" [value]="(template.overridePriceHT > -1 ? template.overridePriceHT : null)" [period]="1000" (onChanged)="onUpdate($event, template)"></app-debounce-input>
          <button class="btn btn-sm btn-warning" (click)="clearPriceException(template)" [disabled]="(template.overridePriceHT < 0)">{{'services_list_your_price_reset' | localise}}</button>
        </div>
      </td>
    </tr>
    
  </tbody>
</table>

<button class="btn btn-danger" (click)="delete()" >{{'services_list_delete' | localise}}</button>
<app-wait [show]='busy'></app-wait>
